<template>
  <div class="h-100">
    <loader :loading="loading || loadingShops" class="h-100" transparent>
      <div id="map-canvas" ref="map"></div>
    </loader>
    <div class="tab-navigation">
      <filter-tab v-if="activeTab === 'filter' && !loading" :type="types" @filter="handleFilter" @cancel="handleCancel"></filter-tab>
      <search-tab v-if="activeTab === 'search' && !loading" :coords="initialRegion" :current-address="currentAddress" @filter="handleFilter" @cancel="handleCancel"></search-tab>
      <shop-detail v-if="showActiveShop" :shop-details="activeShopDetails" @cancel="hideShopDetails"></shop-detail>
      <finder-tabs @tab-changed="setTab"></finder-tabs>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import FinderTabs from "../components/FinderTabs";
import FilterTab from "../components/FilterTab";
import SearchTab from "../components/SearchTab";
import ShopDetail from "../components/ShopDetail";
import mapStyles from "../constants/config";
import store_marker from "../assets/images/store_marker.png";
import user_marker from "../assets/images/user_marker.png"
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {StoreStatus} from "../"

export default {
  name: "BrowseRetailers",
  components: {
    FinderTabs,
    FilterTab,
    SearchTab,
    ShopDetail,
  },
  data() {
    return {
      loading: false,
      loadingShops: false,
      activeTab: null,
      initialRegion : {
        lat: 37.090240,
        lng: -95.712891,
      },
      map: null,
      userMarker : null,
      stores: [],
      storeMarkers: [],
      markerCluster : null,
      types: null,
      radius : 10000,
      google: null,
      currentAddress: '',
      activeShopDetails: null,
      showActiveShop : false,
      spherical : null
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      this.loading = true;

      const loader = new Loader(
          {
            apiKey: this.$config('api.google_api_key'),
            libraries: ['geometry']
          }
      ).load().then((google) => {
        this.google = google;
        this.getUserPosition(position => {
          this.initialRegion.lat = position.coords.latitude;
          this.initialRegion.lng = position.coords.longitude;

          this.map = new google.maps.Map(this.$refs.map, {
            center : new google.maps.LatLng(this.initialRegion),
            zoom  : 10,
            disableDefaultUI: true,
            styles: mapStyles
          })

          this.userMarker = new google.maps.Marker({
            position: new google.maps.LatLng(this.initialRegion),
            map: this.map,
            draggable: true,
            crossOnDrag: false,
            icon: {
              url: user_marker,
              scaledSize: {
                width: 15,
                height: 15
              },
              anchor: new google.maps.Point(10,10),
            },
            zIndex: 100
          })

          google.maps.event.addListener(this.userMarker, 'dragend', (marker) => {
            this.initialRegion.lat = marker.latLng.lat();
            this.initialRegion.lng = marker.latLng.lng();
          });
        });

      }).finally(() => {
        this.getStores();
        this.loading = false;
      })
    },
    getUserPosition(callback) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(callback, (error) => {
          switch(error.code) {
            case error.PERMISSION_DENIED:
              this.$fnNotify({
                message :this.$t('User denied the request for location.'),
                type: 'error'
              })
              break;
            case error.POSITION_UNAVAILABLE:
              this.$fnNotify({
                message :this.$t('Location information is unavailable.'),
                type: 'error'
              })
              break;
            case error.TIMEOUT:
              this.$fnNotify({
                message :this.$t('The request to get user location timed out.'),
                type: 'error'
              })
              break;
            case error.UNKNOWN_ERROR:
              this.$fnNotify({
                message :this.$t('An unknown error occurred.'),
                type: 'error'
              })
              break;
          }
          callback({
            coords : {
              latitude : this.initialRegion.lat,
              longitude: this.initialRegion.lng
            }
          });
        });
      }
    },
    setTab(tab) {
      this.activeTab = tab;
    },
    handleCancel() {
      this.activeTab = null
    },
    handleFilter(filters) {
      this.types = filters?.type;
      if(filters?.radius) {
        this.radius = filters.radius;
      }
      if(filters?.address) {
        this.getAddressCoordinates(filters.address);
      }
      else {
        if(filters?.lat && filters?.lng) {
          let center = {lat : filters.lat, lng : filters.lng}
          this.initialRegion.lat = filters.lat
          this.initialRegion.lng = filters.lng
          this.setCenter(center)
        }
      }
      this.getStores()
    },
    setCenter(position) {
      this.initialRegion = position
      this.map.setOptions({
        center: position
      })
      this.userMarker.setOptions({
        position: position
      })
    },
    getAddressCoordinates(address) {
      let geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({address: address, region: 'US'},(results, status) => {
        if(status === 'OK') {
          this.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          })

          this.getStores();
        }
      })
    },
    getCoordinatesFromAddress(lat, lng) {
      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({
        location : {lat, lng},
        region: 'US'
      }, (results) => {
        if(results.length > 0) {
          for (let i=0; i < results.length; i++) {
            if(!results[i].types.includes('plus_code')) {
              this.currentAddress = results[i].formatted_address;
              break;
            }
          }
        }
      })
    },
    getStores() {
      this.activeTab = null;
      this.loadingShops = true;

      let params = {
        latitude: this.initialRegion.lat,
        longitude: this.initialRegion.lng,
        radius : this.radius * 1.60934, //convert miles to kilometers,
      }

      if(this.types) {
        params.types = this.types;
      }

      this.$fnApi
          .call(`/api/v1/mobile/stores`, "GET", params)
          .then(response => {
            this.stores = response.data.stores?.filter(s => s.Status == StoreStatus.Active)
            this.setStoreMarkers();
          })
          .finally(() => {
            this.loadingShops = false;
          })
    },
    setStoreMarkers() {
      let stores = this.stores;
      this.storeMarkers.forEach(item => {
        item.setMap(null);
      })
      this.storeMarkers = [];
      this.markerCluster?.clearMarkers()

      stores.forEach((item) => {
        const marker = new this.google.maps.Marker({
          position: {lat: parseFloat(item.lat), lng: parseFloat(item.lng)},
          icon: {
            url: store_marker,
            scaledSize: {
              width: 35,
              height: 35
            },
          }
        })

        marker.addListener('click', () => {
          this.showShopDetails(item);
        });

        this.storeMarkers.push(marker)
      })

      let renderer = {
        render: ({ count, position }) =>
            new google.maps.Marker({
              position,
              icon: {
                url: store_marker,
                scaledSize: {
                  width: 35,
                  height: 35
                },
              },
              zoomOnClick: false
            }),
      }

      this.markerCluster = new MarkerClusterer({
        map : this.map,
        markers: this.storeMarkers,
        renderer,
        onClusterClick: (event, cluster) => {
          this.map.setCenter(cluster.position);
          this.map.fitBounds(cluster.bounds);
        }
      });
    },
    showShopDetails(shop) {
      this.activeShopDetails = shop ;
      this.showActiveShop = true;
      this.activeTab = null;
    },
    hideShopDetails() {
      this.activeShopDetails = null;
      this.showActiveShop = false;
    }
  },
  watch: {
    activeTab(tab) {
      if(this.map) {
        if(tab) {
          this.showActiveShop = false;
          this.map.setOptions({
            draggable: false,
            scrollwheel: false,
            disableDoubleClickZoom: true
          })
          this.userMarker.setOptions({
            draggable: false
          })
        } else {
          this.map.setOptions({
            draggable: true,
            scrollwheel: true,
            disableDoubleClickZoom: false
          })
          this.userMarker.setOptions({
            draggable: true
          })
        }
      }
    },
    initialRegion: {
      handler: function() {
        this.getCoordinatesFromAddress(this.initialRegion.lat, this.initialRegion.lng);
        this.getStores();
      },
      deep: true
    }
  }
}
</script>
<template>
    <div :class="editorClass" ref="medium-anchor" translate="no">
        <div ref="editor" v-html="content"></div>
    </div>
</template>

<script>
import {get, merge} from "lodash";
import TextInput from "@fndry-vue/fn-types/inputs/TextInput";
import MediumEditor, { MediumEditorTextcolor } from "./mediumEditor";

/**
 *
 * Note:
 * Bootstrap Modals cause an issue with ignore-enforce-focus-selector because the modal can interfers with some HTML editors.
 * @see https://bootstrap-vue.org/docs/components/modal#keyboard-navigation for more details
 *
 */
export default {
    name: "MediumHtmlInput",
    extends: TextInput,
    data() {
        return {
            content: this.value,
            editorClass: [
                "medium-editor",
                "form-control"
            ],
            editor: null
        };
    },
    mounted(){
        const colorPickerOpts = {
            showHSL: false,
            showRGB: false,
            showHEX: false,
            contentDefault: "<u>A</u>",
        };

        this.editor = new MediumEditor(this.$refs.editor, {
            buttonLabels: false,
            disableEditing: this.disabled,
            disableReturn: get(this.data, "editor.disableReturn", false),
            toolbar: {
                buttons: get(this.data, "editor.toolbar", ["bold", "italic", "underline"]),
            },
            placeholder: {
                text: this.placeholder || "Type your text"
            },
            paste: {
                /**
                 * NOTE: cleanPastedHTML true causes div's when pasting a set of paragraphs. Seems to be a bug with the editor
                 */
                //cleanPastedHTML: true,
            },
            extensions: {
                colorPicker: MediumEditorTextcolor.createInstance(
                    merge(
                        colorPickerOpts,
                        get(this.data, "editor.extensions.colorPicker")
                    )
                )
            }
        });

        this.editor.subscribe('editableInput', (event, editable) => {
            this.$emit("input", editable.innerHTML);
        });
    },
};
</script>

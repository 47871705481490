var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.topics, function (topic, index) {
      return _c("topic-item", {
        key: index,
        attrs: { title: topic.title },
        on: {
          open: function ($event) {
            return _vm.handleOpen(topic)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "px-4 py-3 border-bottom border-light d-flex justify-content-between align-items-center pointer",
      on: { click: _vm.handleClick },
    },
    [
      _c(
        "div",
        { staticClass: "mr-3 bg-primary rounded-circle p-2" },
        [
          _c("icon", {
            staticClass: "text-white",
            attrs: { icon: "question-circle", type: "regular", size: "xl" },
          }),
        ],
        1
      ),
      _c("span", { staticClass: "font-16" }, [_vm._v(_vm._s(_vm.title))]),
      _c("icon", {
        staticClass: "ml-auto",
        attrs: { icon: "long-arrow-right", size: "xl" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <form @submit.prevent="() => handleSubmit()">
        <slot name="header"></slot>

        <!--
        @slot Default Slot
        @binding {function} submit Submit the form
        @binding {function} cancel Cancel the form
        @binding {boolean} invalid If the form is invalid
        @binding {boolean} loading Form is loading
        @binding {boolean} loaded When the schema is loaded
        @binding {boolean} submitting When the form is submitting
        @binding {Object} schema The original schema from the server (non-reactive)
        @binding {Object} inputs The list of inputs from the schema
        @binding {Object} model The model of the store
        @binding {SchemaStore} store The Schema Store attached to the form
        @binding {Object} errors The list of inputs from the schema
        @binding {Object} flags The validation flags of the form
        @binding {Boolean} state The validation stat of the form
        @binding {Object} response The last response from the server
        @binding {String} responseVariant The bootstrap variant of the response (success or danger)
        @binding {String} responseMessage The message for the response
        -->
        <slot v-bind="{loaded, loading, submitting, submit: handleSubmit, cancel, schema: schema_, inputs, model, schemaStore, errors, flags, state, response, responseVariant, responseMessage}">
            <b-alert v-if="!noAlert" v-model="showAlert" :variant="responseVariant">
                {{ responseMessage }}
            </b-alert>
            <component :is="loaderTag" :loading="loading && !outerLoader" :transparent="loaderTransparent" class="form-wrapper">
                <form-schema v-if="loaded"
                             ref="schema"
                             :schema-store="schemaStore"
                             @validated="onValidated"
                             @reload="reloadSchema"
                ></form-schema>
            </component>
        </slot>
        <!--
        @slot Buttons Slot
        @binding {function} submit Submit the form
        @binding {function} cancel Cancel the form
        @binding {boolean} invalid If the form is invalid
        @binding {boolean} loading Form is loading
        -->
        <slot name="buttons"
              v-if="loaded && !noButtons"
              v-bind="{submit: handleSubmit, schema: schema_, cancel, loading, submitting, flags}"
        >
            <form-buttons :active="activeButton"
                          :buttons="schema_.buttons"
                          :submitting="submitting"
                          :cancel-button="cancelText"
                          :submit-button="submitText"
                          :state="flags.valid"
                          @click="handleButtonClick"
            ></form-buttons>
        </slot>
    </form>

</template>

<script>

import {BAlert, BButton} from "bootstrap-vue";
import FormButtons from "./FormButtons";

import FormSchema from "./FormSchema";
import withSchemaStore from "../mixins/withSchemaStore";
import {$_fnForm} from "./common";

export default {
    name: "Form",
    provide: function () {
        return {
            [$_fnForm]: this
        };
    },
    inject: {
        "panel": {
            default: null
        }
    },
    mixins: [
        withSchemaStore
    ],
    components: {
        FormSchema,
        FormButtons,
        BAlert,
        BButton
    },
    props: {
        /**
         * The URI to fetch the schema or submit the form (optional)
         */
        uri: {
            type: String,
            required: false
        },
        /**
         * The URI Parameters to send to the server for the given URI (optional)
         */
        uriParams: {
            type: Object,
            required: false,
            default(){
                return {};
            }
        },
        /**
         * The HTTP method to use when calling the given URI (optional)
         */
        uriMethod: {
            type: String,
            required: false,
            default: "GET"
        },

        /**
         * The data to send for the given URI (optional)
         *
         * @deprecated use props.value instead
         */
        uriData: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },

        /**
         * Whether to autoload and call the uri on load, which will return a schema
         */
        noAutoload: {
            type: Boolean
        },

        loaderTag: {
            type: String,
            default: "fn-ui-loader"
        },

        loaderTransparent: Boolean,

        /**
         * Disable the displaying of the default cancel button
         */
        noCancel: {
            type: Boolean,
            default: false
        },

        /**
         * The Cancel Button text
         */
        cancelText: {
            type: String,
            default() {
                return "Cancel";
            }
        },

        /**
         * The Submit Button text
         */
        submitText: {
            type: String,
            default() {
                return "Save";
            }
        },

        /**
         * Toggle the display of the buttons
         */
        noButtons: {
            type: Boolean,
            default: false
        },

        /**
         * Toggle the display of the Alert
         */
        noAlert: {
            type: Boolean,
            default: false
        },

        /**
         * Disable the use of notifications
         */
        noNotify: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            loaded: false,
            loading: false,

            submitting: false,
            response: null,
            showAlert: false,
            activeButton: undefined,
            flags: {
                dirty: null,
                validated: null,
                valid: null
            }

        };
    },
    computed: {
        model: function(){
            return this.schemaStore.state.model;
        },

        nodes: function(){
            return this.schemaStore.state.node;
        },

        inputs: function(){
            return this.schemaStore.state.inputs;
        },

        schema_: function(){
            if (this.schema) {
                return this.schema;
            } else if (this.schemaStore) {
                return this.schemaStore.schema;
            } else {
                return undefined;
            }
        },

        responseVariant: function () {
            if (this.response) {
                return (this.response.status) ? "success" : "danger";
            }
            return undefined;
        },
        responseMessage: function () {
            if (this.response) {
                if (this.response.error) {
                    return this.response.error;
                } else if (this.response.message) {
                    return this.response.message;
                } else if (!this.response.status) {
                    return "An unknown error occurred";
                }
            }
            return undefined;
        },
        errors: function () {
            if (this.response && this.response.error) {
                return this.response.data;
            }
            return undefined;
        },
        state: function () {
            let {dirty, validated, valid} = this.flags;
            return dirty || validated ? valid : null;
        },
        outerLoader: function(){
            return !!(this.panel);
        }

    },
    mounted() {
        if (this.uri && !this.noAutoload) {
            this.getSchema(this.uri, this.uriParams, this.value, "view", this.uriMethod);
        } else if (this.schema) {
            this.setSchema(this.schema);
        }
        this.schemaStore.on("loaded", () => {
            this.loaded = true;
        });
    },
    destroyed() {
        if (this.panel) {
            this.panel.setLoading(false);
        }
    },
    watch: {
        loading: function(newVal){
            if (this.panel) {
                this.panel.setLoading(newVal);
            }
        },
        submitting: function(newVal){
            if (this.panel) {
                this.panel.setLoading(newVal);
            }
        }
    },
    methods: {
        /**
         * Get the Schema based on the given uri details
         *
         * @param {String} uri
         * @param {{}} params
         * @param {{}} data
         * @param {String} action The action for the schema
         * @param {String} method
         */
        getSchema(uri, params, data = {}, action = "view", method = "GET") {
            this.setLoading(true);
            this.showAlert = false;

            this.$emit("change-title", this.$t("Loading") + "...");
            this.$fnApi.handle(uri, {...params, _action: action}, data, method)
                .then((response) => {
                    if (response.status) {
                        this.setSchema(response.data);
                    } else {
                        this.setResponse(response);
                    }
                })
                .finally(() => {
                    this.setLoading(false);
                })
            ;
        },

        reloadSchema(inputs) {
            this.setLoading(true);
            this.$fnApi.view(this.uri, {...this.uriParams, _action: "reload", _inputs: inputs}, this.model, "POST")
                .then((response) => {
                    if (response.status) {
                        this.schemaStore.reload(response.data.inputs, response.data.values);
                    } else {
                        this.setResponse(response);
                    }
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },

        /**
         * Set the schema
         *
         * @param {{}} schema
         */
        setSchema(schema){
            this.schemaStore.load(schema, this.value);
            this.$emit("schema-set", schema);
        },

        /**
         * Set the loading state and emit it
         *
         * @param {Boolean} loading
         */
        setLoading(loading) {
            this.loading = loading;
            this.$emit("loading", loading);
        },

        setSubmitting(submitting) {
            this.submitting = submitting;
            this.$emit("submitting", submitting);
        },

        updateModel(value){
            this.schemaStore.setModel(value);
        },

        /**
         * Handle a field input and change event
         *
         * This will update the current model of the component with the latest changes and emit an update event
         *
         * @param {string} key The field that was changed
         * @param {*} value The new value of the field
         */
        updateField(key, value) {
            this.schemaStore.setValue(key, value);
        },

        onValidated(flags){
            this.flags = flags;
            this.$emit("validated", flags);
        },

        async submit(uri, params, method = "POST") {
            this.showAlert = false;

            let isValid = this.flags.valid;
            if (this.$refs.schema) {
                isValid = await this.$refs.schema.validate();
            }
            if (!isValid) {
                this.setSubmitting(false);
                return;
            }

            this.setSubmitting(true);

            if (this.$refs.schema) this.$refs.schema.setErrors({});

            return this.$fnApi.handle(uri, {...params, _action: "submit"}, this.model, method)
                .then((response) => {
                    this.setResponse(response);
                    this.setSubmitting(false);
                    return response;
                }).finally(() => {
                    this.setSubmitting(false);
                })
                ;
        },
        /**
         * Handles the submission of the form
         *
         * @returns {Promise<void>}
         */
        async handleSubmit(uri = undefined) {
            let _uri;
            if (uri && !(uri instanceof MouseEvent)) {
                _uri = uri;
            } else if (this.schema_?.uri) {
                _uri = this.schema_.uri;
            } else {
                _uri = this.uri;
            }

            let params = {};
            if (this.schema_?.params) {
                params = this.schema_?.params;
            } else if (this.uriParams){
                params = this.uriParams;
            }

            return this.submit(_uri, params);
        },

        cancel() {
            this.onCancel();
        },

        setResponse(response) {
            this.response = {...response};
            if (this.response.status) {
                this.$emit("success", this.response);

                if (!this.noNotify && this.$fnNotify) {
                    this.$fnNotify({message: this.responseMessage, type: this.responseVariant});
                }

            } else {
                this.showAlert = true;

                if (!this.noNotify && this.$fnNotify) {
                    this.$fnNotify({message: this.responseMessage, type: this.responseVariant});
                }

                if (this.response.code === 422 && this.$refs.schema) {
                    this.$refs.schema.setErrors(this.response.data);
                }
                this.$emit("fail", this.response);
            }
        },

        onSuccess(response) {
            /**
             * Success
             *
             * @property {*} response The response from the server
             */
            this.$emit("success", response);
        },

        onFail(response) {
            /**
             * Success
             *
             * @property {*} response The response from the server
             */
            this.$emit("fail", response);
        },

        onCancel() {
            /**
             * Cancel
             */
            this.$emit("cancel");
        },

        /**
         * Handle the button click
         *
         * @param {{name: String, type: String|undefined, uri: String|undefined, params: Object|undefined, method: String|undefined}} button
         */
        handleButtonClick(button) {
            let type = button.type || "submit";
            let uri = button.uri || this.schema_?.uri || this.uri;
            let params = button.params || this.schema_?.params || this.uriParams;
            let method = button.method || this.uriMethod;
            this.activeButton = button.name;
            switch (type) {
                case "validate":
                case "submit":
                    this.submit(uri, params);
                    break;
                case "cancel":
                    this.onCancel();
                    break;
                default:
                    this.getSchema(uri, params, this.model, button.name, method);
                    break;
            }
        },

        reset(){
            if (this.$refs.schema?.$refs?.observer) this.$refs.schema.$refs.observer.reset();
        }

    }
};
</script>

<template>
    <card-layout class="table-card" with-header :with-dropdown="hasCardDropdown" :header-class="headerClass">
        <template #header>{{ headerTitle }}</template>

        <template #dropdown>
            <slot name="card-dropdown">
            </slot>
        </template>

        <div class="action-row border-bottom">
            <div class="d-flex align-items-center">
                <div class="checkbox-container" v-if="rowSelectable">
                    <b-form-checkbox class="ml-3" v-model="selectAllRows" :indeterminate.sync="selectAllIndeterminate"></b-form-checkbox>
                </div>
<!--                <icon class="ml-1 text-primary" icon="caret-down" size="sm" v-if="hasActions"></icon>-->
                <transition name="slide-right">
                    <div class="action-container" v-if="hasActions" v-show="showActions">
                        <slot name="actions" v-bind="{selectedRows, disabled: isActionRowDisabled}"></slot>
                    </div>
                </transition>
                <b-button variant="link" class="refresh-container" :disabled="loading" @click.prevent="() => $emit('refresh')">
                    <icon class="pointer" icon="redo"></icon>
                </b-button>
                <b-dropdown variant="link" toggle-class="text-decoration-none" right class="ml-2" no-caret v-if="hasActionDropdown">
                    <template #button-content>
                        <icon icon="ellipsis-v" size="sm" type="solid"></icon>
                    </template>
                    <slot name="action-dropdown">
                    </slot>
                </b-dropdown>
            </div>

            <div class="d-flex align-items-center action-right">
                <div v-if="!noSearch" class="search-from" :class="{'show-form' : showSearch}">
                    <input class="search-input form-control form-control-sm" type="text" v-model="searchValue" placeholder="Search" @change="() => $emit('search', searchValue)">
                    <div class="search-btn btn btn-sm" @click="toggleSearch" id="search-popup" v-b-popover.hover.top.v-primary="searchPopupText">
                        <icon icon="search" size="sm"></icon>
                    </div>
                </div>

                <b-dropdown v-if="hasSelectableFields && !loading" variant="link" class="ml-2 btn btn-sm border" no-caret>
                    <template #button-content>
                        <icon class="m-0" icon="line-columns" size="sm"></icon>
                    </template>

                    <b-dropdown-item v-for="(field,index) in selectableFields" :key="index" @click.native.capture.stop>
                        <b-checkbox v-model="field.selected">{{ field.label }}</b-checkbox>
                    </b-dropdown-item>
                </b-dropdown>

                <div v-if="hasFilters" class="filter-btn ml-2 btn btn-sm border" :class="{'active' : showFilter}" @click="toggleFilter" id="filter-popup" v-b-popover.hover.top.v-primary="filterPopupText">
                    <icon icon="filter" size="sm"></icon>
                </div>

                <nav v-show="totalPages > 0" class="table-card-pagination ml-3">
                    <pagination :active-page="activePage" :total-pages="totalPages" v-on="$listeners"/>
                </nav>
            </div>

        </div>

        <transition v-if="hasFilters" name="slide-down" type="transition">
            <div class="filter-row" v-show="showFilter">
                <slot name="filters">
                    <fn-filters :filters="filters" v-on="$listeners" :api-base-url="apiBaseUrl"></fn-filters>
                </slot>
            </div>
        </transition>

        <loader :loading="loading">
            <div class="card-table">
                <slot name="table" v-bind="{setSelectable, selectAllRows, setSelectedRows}"></slot>
            </div>
        </loader>

        <div class="table-footer" v-if="pageMeta.from && pageMeta.to && pageMeta.total">
            {{ `Showing ${pageMeta.from} to ${pageMeta.to} of ${pageMeta.total} entries` }}
        </div>

    </card-layout>
</template>

<script>
import CardLayout from "../card-layout/CardLayout";
import Pagination from "./Pagination";
import {FnFilters} from "@fndry-vue/fn-filters";
import {findIndex} from "lodash";

export default {
    name: "TableCard",
    components: {
        Pagination,
        CardLayout,
        FnFilters
    },
    props: {
        headerTitle: String,
        noSearch: Boolean,
        searchPopupText: {
            type: String,
            default() {
                return this.$t("Search");
            }
        },
        filterPopupText: {
            type: String,
            default() {
                return this.$t("Filter");
            }
        },
        filters: {
            type: Object,
            required: false
        },
        activePage: {
            type: Number,
            default: 1
        },
        totalPages: {
            type: Number,
            default: 0
        },
        pageMeta: {
            type:Object,
            default() {return {};}
        },
        selectKey: String,
        loading: Boolean,
        rowSelectable : {
            type: Boolean,
            default: false
        },
        headerClass: String,
        apiBaseUrl: String,
    },
    data() {
        return {
            showActions : true,
            showSearch: false,
            showFilter: false,
            searchValue: "",
            selectableFields: [],
            selectAllRows: false,
            selectAllIndeterminate: false,
            selectedRows: [],
        };
    },
    computed: {
        hasCardDropdown() {
            return !!this.$slots["card-dropdown"];
        },

        hasActionDropdown() {
            return !!this.$scopedSlots["action-dropdown"];
        },

        hasActions() {
            return !!this.$scopedSlots["actions"];
        },

        hasFilters(){
            return this.filters || this.$slots.filters;
        },
        hasSelectableFields() {
            return this.selectableFields.length > 0;
        },
        localStorageKey() {
            return this.selectKey + "_" + btoa(this.$route.path);
        },
        isActionRowDisabled() {
            return this.selectedRows <= 0;
        }
    },
    methods: {
        toggleSearch() {
            this.showSearch = !this.showSearch;
            this.$root.$emit("bv::hide::popover", "search-popup");
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
            this.$root.$emit("bv::hide::popover","filter-popup");
        },
        setSelectable(selectable) {
            if(this.selectKey) {
                let localSelected = localStorage.getItem(this.localStorageKey);

                if(localSelected) {
                    JSON.parse(atob(localSelected)).forEach((item) => {
                        let index = findIndex(selectable, (selectItem) => selectItem.key === item.key );

                        if(index !== -1) {
                            selectable[index].selected = item.selected;
                        }
                    });
                }
            }
            this.selectableFields = selectable;
        },
        setSelectedRows(args) {
            let {rows, areAllRowsSelected} = args;
            this.selectedRows = rows;

            this.selectAllIndeterminate = rows.length > 0 && !areAllRowsSelected;

            if(areAllRowsSelected) {
                this.$nextTick(function() {
                    this.selectAllIndeterminate = false;
                    this.selectAllRows = true;
                })
            }

            if(rows.length === 0) {
                this.$nextTick(function() {
                    this.selectAllRows = false;
                })
            }

        }
    },
    watch:{
        selectableFields: {
            handler(newVal) {
                if(this.selectKey) {
                    localStorage.setItem(this.localStorageKey, btoa(JSON.stringify(newVal)));
                }
            },
            deep: true
        }
    }
};
</script>

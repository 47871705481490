<template>
    <div :key="authKey" class="d-flex flex-column h-100 justify-content-around">
        <div>
            <h4 class="text-center text-gray-600">{{ $t('Log In') }}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{$t("Welcome to the Okinus Portal.") }}</p>
            <div v-if="!ui && !guard">
                <router-link class="btn btn-primary btn-block"
                             :to="loginPortal">{{ $t("Portal Area") }}
                </router-link>
                <router-link class="btn btn-primary btn-block"
                             :to="loginClient">{{ $t("Client Area") }}
                </router-link>
            </div>
            <div v-else-if="!guard">
                <router-link class="btn btn-primary btn-block"
                             :to="loginStore">{{ $t("Log in as Store") }}
                </router-link>
                <router-link class="btn btn-primary btn-block"
                             :to="loginRetailer">{{ $t("Log in as Retailer") }}
                </router-link>
                <router-link class="btn btn-primary btn-block"
                             :to="loginSystem">{{ $t("Log in as Okinus") }}
                </router-link>
                <router-link class="btn btn-primary btn-block"
                             :to="loginClient">{{ $t("Log in as Customer") }}
                </router-link>
            </div>
            <div v-else class="mt-3">
                <fn-form no-autoload no-buttons :uri="formUri" :uri-params="formUriParams" uri-method="POST"
                         v-model="form" @success="onSuccess" @fail="onFail">
                    <template v-slot="{loading, loaded, submitting, submit, flags}">
                        <fn-form-schema>
                            <fn-form-group :schema="inputs.email"></fn-form-group>
                            <fn-form-group :schema="inputs.password">
                                <template v-slot:end>
                                    <router-link :to="forgotRoute"><small>{{ $t("Forgot your password?") }}</small>
                                    </router-link>
                                </template>
                            </fn-form-group>
                        </fn-form-schema>
                        <div class="form-group">
                            <fn-ui-button variant="primary" class="btn-block btn-rounded"
                                          :disabled="!flags.valid || loading || submitting" :submitting="submitting"
                                          type="submit">{{ $t("Log In") }}
                            </fn-ui-button>
                        </div>
                    </template>
                </fn-form>
            </div>
        </div>
    </div>
</template>

<script>
import {AUTH_CHECK, AUTH_VERIFY, AUTH_FORGOT, AUTH_LOGIN } from "./index";
import {AUTH_GUARD_CLIENT, AUTH_GUARD_RETAILER, AUTH_GUARD_STORE, AUTH_GUARD_SYSTEM} from "../constants";
import {setRedirectTo} from "../index";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import {withScreen} from "@fndry-vue/fn-screen";


export default {
    name: "AuthLogin",
    components: {},
    mixins: [
        withAutoGuard,
        withScreen
    ],
    props: {
        redirect: {
            type: [String,Object],
            default() {
                return this.$route.query.redirect;
            }
        }
    },
    screen() {
        return {
            title: this.$t('Login'),
            data: {
                noToggle: true,
                withButton: true,
                clearWave: true
            }
        }
    },
    data() {
        return {
            errors: [],
            inputs: {
                email: {
                    key: "email",
                    id: "email",
                    name: "email",
                    label: this.$t("Email Address"),
                    placeholder: this.$t("Email Address"),
                    type: "email",
                    rules: "required|email",
                    required: true,
                    autocomplete: "email"
                },
                password: {
                    key: "email",
                    id: "password",
                    name: "password",
                    label: this.$t("Password"),
                    placeholder: this.$t("Password"),
                    type: "password",
                    rules: "required",
                    required: true,
                    autocomplete: "current-password"
                }
            },
            form: {
                email: "",
                password: "",
                confirm: null
            }
        };
    },
    computed: {
        loginRoot() {
            return {
                name: AUTH_LOGIN
            };
        },
        loginPortal() {
            return {
                name: AUTH_LOGIN,
                query: {
                    guard: null,
                    ui: "portal"
                }
            };
        },
        loginClient() {
            return {
                name: AUTH_LOGIN,
                query: {
                    guard: AUTH_GUARD_CLIENT,
                    ui: "customer"
                }
            };
        },
        loginSystem() {
            return {
                name: AUTH_LOGIN,
                query: {
                    guard: AUTH_GUARD_SYSTEM,
                    ui: "portal"
                }
            };
        },
        loginStore() {
            return {
                name: AUTH_LOGIN,
                query: {
                    guard: AUTH_GUARD_STORE,
                    ui: "portal"
                }
            };
        },
        loginRetailer() {
            return {
                name: AUTH_LOGIN,
                query: {
                    guard: AUTH_GUARD_RETAILER,
                    ui: "portal"
                }
            };
        },
        formUri() {
            return "/api/v2/auth/login";
        },
        formUriParams() {
            return this.getGuardUriParams({
                ui: this.ui
            });
        },
        forgotRoute() {
            return this.getGuardRoute(AUTH_FORGOT);
        },
        user: function () {
            return this.$fnAuth.getUser();
        },
        authKey: function() {
            return this.ui + "_" + this.guard;
        }
    },
    mounted() {
        //check if the guest param is true, then force the current user to log out
        if (this.$route.params.guest) {
            this.$fnApi.call("/api/v2/auth/logout");
        }
        this.$fnApi.call("/sanctum/csrf-cookie", "GET");

        if (this.redirect){
            setRedirectTo(this.redirect);
        }
    },
    methods: {
        onSuccess(response) {
            if (response.status) {
                this.$fnNotify(this.$t("Welcome Back!"));
                this.$fnAuth.setUser(response.data);
                this.$router.push({
                    name: AUTH_CHECK
                });
            }
        },
        onFail(response) {
            if ((response.code === 403 && response.data.code && response.data.code === 1001)
                || (response.code === 449 && response.data.code && response.data.code === 1005)) {
                const route = this.getGuardRoute(AUTH_VERIFY, {
                    token: response.data.token
                });

                this.$router.push(route);
            }
        }
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-item", style: _vm.cardStyle }, [
    _c("img", { staticClass: "card-logo", attrs: { src: _vm.cardLogo } }),
    _c("div", { staticClass: "font-22 text-white mt-2" }, [
      _vm._v(_vm._s(_vm.getCardNumber())),
    ]),
    _c("div", { staticClass: "row text-white mt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "font-10" }, [
          _vm._v(_vm._s(_vm.$t("Month/Year"))),
        ]),
        _c("div", { staticClass: "font-12" }, [
          _vm._v(_vm._s(_vm.getExpirationDateDetails(_vm.card))),
        ]),
      ]),
      _c("div", { staticClass: "col-5" }, [
        _c("div", { staticClass: "font-10" }, [
          _vm._v(_vm._s(_vm.$t("cvc2/cvv2"))),
        ]),
        _c("div", { staticClass: "font-12" }, [_vm._v(_vm._s("XXX"))]),
      ]),
    ]),
    _c("div", { staticClass: "row text-white mt-3" }, [
      _c("div", { staticClass: "col-5" }, [
        _c("div", { staticClass: "font-10" }, [
          _vm._v(_vm._s(_vm.getCardName())),
        ]),
      ]),
      _c("div", { staticClass: "col" }, [
        false && _vm.isDefaultPayment
          ? _c(
              "div",
              {
                staticClass:
                  "font-10 text-uppercase text-secondary font-weight-bold text-right",
              },
              [_vm._v(_vm._s(_vm.$t("Default Payment")))]
            )
          : _vm._e(),
        !_vm.isDefaultPayment && _vm.isPaylianceCard && !_vm.noActions
          ? _c("div", { staticClass: "font-10 text-uppercase text-center" }, [
              false
                ? _c(
                    "div",
                    {
                      staticClass:
                        "pointer p-1 border rounded-sm border-primary text-primary",
                      on: { click: _vm.handleMakeDefault },
                    },
                    [_vm._v(_vm._s(_vm.$t("Make Default Payment")))]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "mt-1 pointer p-1 border rounded-sm border-danger text-danger",
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(_vm._s(_vm.$t("Remove Payment Method")))]
              ),
            ])
          : _vm._e(),
        !_vm.isPaylianceCard && !_vm.noActions
          ? _c("div", { staticClass: "font-10 text-uppercase text-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "pointer p-1 border rounded-sm border-warning text-primary",
                  on: { click: _vm.handleReVerify },
                },
                [_vm._v(_vm._s(_vm.$t("Card Requires Verification")))]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
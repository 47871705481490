<template>
    <div class="fn-ui-report-menu-history">
        <div class="fn-ui-report-menu-history__header">
            <span class="fn-ui-report-menu-history__title">{{ title }}</span>
            <b-dropdown variant="link" toggle-class="text-decoration-none text-dark p-0" right
                        class="dropdown-container flex-grow-0" no-caret v-if="hasHeaderDropdown">
                <template #button-content>
                    <icon icon="ellipsis-v" type="solid" size="sm"></icon>
                </template>
                <slot name="header-dropdown">
                </slot>
            </b-dropdown>
        </div>
        <div class="fn-ui-report-menu-history__list">
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuHistory",
        props: {
            title: String,
        },
        computed: {
            hasHeaderDropdown() {
                return !!this.$slots['header-dropdown'];
            }
        }
    }
</script>

<template>
    <layout-navigation :id="navId">
        <template #header>
            <layout-navigation-header v-if="title || titleCompact" :title="title" :title-compact="titleCompact" :compact="compact"></layout-navigation-header>
        </template>
        <template>
            <slot name="top"></slot>
            <template v-if="menuCollapsed">
                <layout-navigation-group
                     :icon="menuCollapsedIcon"
                     :text="menuCollapsedText"
                     :compact="compact"
                     @show-children="$emit('expand-menu')"
                ></layout-navigation-group>
            </template>
            <template v-else>
                <template v-for="(item, index) in items">
                    <layout-search-navigation :key="index"
                                              v-if="item.search"
                                              :icon="item.icon"
                                              :text="item.text"
                                              :compact="compact"
                                              :to="item.to"
                    />
                    <layout-navigation-group :key="index"
                                             v-else-if="item.children"
                                             :icon="item.icon"
                                             :avatar="item.avatar"
                                             :text="item.text"
                                             :compact="compact"
                                             :caret-icon="caretIcon"
                                             :show-children="showChildren === true || showChildren === index"
                                             @show-children="handleShowChildren"
                    >
                        <template v-for="(item, cindex) in item.children">
                            <layout-navigation-group v-if="item.children"
                                                     :key="cindex"
                                                     :icon="item.icon"
                                                     :avatar="item.avatar"
                                                     :text="item.text"
                                                     :compact="compact"
                                                     :caret-icon="caretIcon"
                                                     :show-children="showChildren === true || showChildren === index"
                                                     @show-children="handleShowChildren"
                            >
                                <layout-navigation-link v-for="(item, dindex) in item.children"
                                                        class="pl-5"
                                                        :compact="compact"
                                                        :key="dindex"
                                                        :href="item.href"
                                                        :to="item.to"
                                                        :icon="item.icon"
                                                        :avatar="item.avatar"
                                                        :alt="item.alt || item.text"
                                                        :active="item.active"
                                                        :badge="(item.badge) ? item.badge : null"
                                                        @click="() => handleClick(item.click)"
                                >{{item.text}}</layout-navigation-link>
                            </layout-navigation-group>
                            <layout-navigation-link v-else
                                                    :compact="compact"
                                                    :key="cindex"
                                                    :href="item.href"
                                                    :to="item.to"
                                                    :icon="item.icon"
                                                    :avatar="item.avatar"
                                                    :alt="item.alt || item.text"
                                                    :active="item.active"
                                                    :badge="(item.badge) ? item.badge : null"
                                                    @click="() => handleClick(item.click)"
                            >{{item.text}}</layout-navigation-link>
                        </template>
                    </layout-navigation-group>
                    <layout-navigation-link :key="index" v-else
                                            :icon="item.icon"
                                            :avatar="item.avatar"
                                            :compact="compact"
                                            :href="item.href"
                                            :to="item.to"
                                            :alt="item.alt || item.text"
                                            :active="item.active"
                                            :badge="(item.badge) ? item.badge : null"
                                            @click="() => handleClick(item.click)"
                    >{{item.text}}</layout-navigation-link>
                </template>
            </template>
        </template>
    </layout-navigation>
</template>

<script>

    import LayoutNavigation from "./LayoutNavigation";
    import LayoutNavigationHeader from "./LayoutNavigationHeader";
    import LayoutNavigationLink from "./LayoutNavigationLink";
    import LayoutNavigationGroup from "./LayoutNavigationGroup";
    import LayoutSearchNavigation from "./LayoutSearchNavigation";
    import gsap from "gsap";

    export default {
        name: "LayoutNavigationList",
        components: {
            LayoutSearchNavigation,
            LayoutNavigationGroup,
            LayoutNavigationLink,
            LayoutNavigationHeader,
            LayoutNavigation
        },
        props: {
            items: {
                type: Array,
                default: () => []
            },
            title: String,
            titleCompact: String,
            compact: Boolean,
            menuCollapsedIcon: {
                type: String,
                default: "door-open"
            },
            menuCollapsed: {
                type: Boolean,
                default: false
            },
            menuCollapsedText: {
                type: String,
                default: "Open Application Menu"
            },
            showChildren: {
                type: [Boolean,Number],
                default: false
            },
            caretIcon: {
                type: String,
                default: 'chevron-down'
            }
        },
        data() {
            return {
                menuCollapsedState: this.menuCollapsed,
                containerHeight: 0,
            };
        },
        computed: {
            navId() {
                return "fn-ui-navigation-"+this._uid;
            },
            containerSelector() {
                return "#"+this.navId + " .fn-ui-layout-navigation__body";
            },
        },
        methods: {
            handleClick(clickHandler) {
                if (clickHandler) {
                    clickHandler();
                }
            },
            childrenIsActive(children) {
                if(Array.isArray(children)) {
                    return children.some(child => {
                        return child.active;
                    });
                }
            },
            handleShowChildren(){
                this.$emit("show-children");
            },
            handleContainerNavigationShowAnimation() {
                let selector = this.containerSelector;
                if(document.querySelector(selector)) {
                    gsap.fromTo(selector,{autoAlpha: 0},{autoAlpha: 1, ease:"circ.inOut", duration: 0.25});
                    gsap.fromTo(selector,{height: 0},{height: "auto", ease:"circ.inOut", duration: 0.5, onComplete : () => {
                            this.containerHeight = document.querySelector(selector).clientHeight;
                        }});
                }
            },
            handleContainerNavigationHideAnimation() {
                let selector = this.containerSelector;
                if(document.querySelector(selector)) {
                    let heightPx = this.containerHeight + "px";
                    gsap.fromTo(selector,{height: heightPx},{height: "auto", ease:"circ.inOut", duration: 0.5});
                }
            }
        },
        watch: {
            menuCollapsed(val) {
                if(val) {
                    this.$nextTick(()=>{
                        this.handleContainerNavigationHideAnimation();
                    });
                } else {
                    this.$nextTick(()=>{
                        this.handleContainerNavigationShowAnimation();
                    });
                }
            }
        }
    };
</script>

<template>
    <div style="display: contents">
        <tr role="row" :key="item.id" :class="parentClass">
            <slot name="columns" v-bind="{item, depth, hasChildren, toggleChildRows: () => toggleChildRows(item.id), depthPadding}"></slot>
        </tr>
        <template v-if="hasChildren">
            <tree-grid-table-row

                v-for="(child, index) in children"
                :item="child"
                :key="item.id+'_'+index"
                :row-class="childClass(item)"
                :depth="depth + 1"
            >
                <template v-slot:columns="{item, depth, hasChildren, toggleChildRows, depthPadding}">
                    <slot name="columns" v-bind="{item, depth, hasChildren, toggleChildRows, depthPadding}"></slot>
                </template>
            </tree-grid-table-row>
        </template>
    </div>
</template>

<script>
export default {
    name: "TreeGridTableRow",
    props: {
        item: {
            type: Object,
            required: true,
        },
        rowClass: String,
        depth: {
            type: Number,
            required: true,
        },
    },
    computed: {
        hasChildren() {
            return (this.children && this.children.length);
        },
        children() {
            return this.item.children;
        },
        parentClass() {
            let className = "grid-parent parent-" + this.item.id;

            if (this.rowClass) {
                className += ` ${this.rowClass}`;
            }

            return className;
        },
        depthPadding() {
            return (this.depth * 20) + "px";
        }
    },
    methods: {
        childClass(item) {
            return "grid-child d-none child-" + item.id;
        },
        toggleChildRows(id) {
            document.querySelector('.parent-' + id).classList.toggle('expand');
            document.querySelectorAll('.child-' + id).forEach(child => child.classList.toggle('d-none'));
        },
    }
}
</script>

<template>
    <b-form-checkbox-group
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :autofocus="autofocus"
        :required="required"
        :state="state"
        :size="size"
        v-model="model"
        :stacked="isStacked"
        :switches="isSwitch"
        v-on="$listeners"
    >
            <b-form-checkbox
                class="checkbox-group-item"
                v-for="(option,index) in options_"
                :value="option.value"
                :key="index"
                :inline="isStacked">
                    {{option.text}}
                <div class="checkbox-group-help-key" v-if="option.help">
                    <small>({{ option.help }})</small>
                </div>
            </b-form-checkbox>
    </b-form-checkbox-group>
</template>

<script>

    import {BFormCheckbox, BFormCheckboxGroup} from "bootstrap-vue";

    import input from "../mixins/input";
    import options from "../mixins/options";
    import SelectInput from "./SelectInput";

    export default {
        name: "CheckboxesInput",
        extends: SelectInput,
        mixins: [
            input,
            options
        ],
        components: {
            BFormCheckbox,
            BFormCheckboxGroup
        },
        props: {
            stacked: Boolean,
            switch: Boolean
        },
        computed: {
            isSwitch: function(){
                return this.switch;
            },
            isStacked: function() {
                return this.stacked || this.options.some((item) => item.helpKey);
            },
            model: {
                get: function(){
                    return this.value || [];
                },
                set: function(value){
                    this.$emit("input", value);
                }
            }
        }
    };
</script>

<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t('Account Creation')}}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{$t("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pulvinar a arcu sed ullamcorper. Proin a sapien eu nisi varius aliquet non non enim.")}}</p>
            <div v-if="showRegisterForm" class="mt-3">
                <fn-form :uri="formUri" uri-method="POST" v-model="form" @success="onSuccess">
                    <template #buttons="{submit, invalid, loading, submitting}">
                        <div class="form-group mt-4">
                            <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="invalid || submitting" :submitting="submitting" type="submit">{{$t("Register")}}</fn-ui-button>
                        </div>
                    </template>
                </fn-form>
            </div>
            <div v-else>
                <p>
                    <button class="btn btn-primary btn-block" @click.prevent="() => showRegisterForm = true">{{$t('Sign Up with Email')}}</button>
                </p>
            </div>
            <div class="mt-4">
                <p class="text-center">{{$t("Already have account?")}} <router-link :to="loginRoute">{{$t("Log In")}}</router-link></p>
            </div>
        </div>
    </div>
</template>

<script>
    import {AUTH_CHECK, AUTH_LOGIN, AUTH_VERIFY} from "./index";
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

    export default {
        name: "AuthRegister",
        mixins: [
            withAutoGuard
        ],
        data() {
            return {
                loading: false,
                showRegisterForm: false,
                form: {
                    display_name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    confirm: null
                }
            };
        },
        mounted() {
            //ensure the user is logged out
            if (this.$fnAuth.isLoggedIn()) {
                this.loading = true;

                const uri = "/api/v2/auth/logout";

                this.$fnApi
                    .call(uri)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        computed: {
            formUri() {
                return "/api/v2/auth/register";
            },
            loginRoute() {
                return {
                    name: AUTH_LOGIN
                };
            },
        },
        methods: {
            onSuccess(response){
                if (response.data.is_verified) {
                    this.$fnNotify(this.$t("Account Registered. Please log in to continue."));
                    this.$router.push({
                        name: AUTH_CHECK
                    });
                } else {
                    this.$router.push({
                        name: AUTH_VERIFY,
                        params: {
                            token: response.data.token
                        }
                    });
                }
            }
        }
    };
</script>

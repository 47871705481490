var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col font-18 text-primary font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("Contract Terms"))),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Lease Start Date"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-date", {
            attrs: {
              format: "MM/DD/YYYY",
              value: _vm.lease.date_of_loan,
              "ignore-time-zone": true,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Lease Payment Date"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.lease.extra
            ? _c("fn-format-date", {
                attrs: {
                  format: "MM/DD/YYYY",
                  value: _vm.lease.extra.nextDueDate,
                  "ignore-time-zone": true,
                },
              })
            : _c("span", [_vm._v(_vm._s(_vm.$t("N/A")))]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Cash Price"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-currency", {
            attrs: {
              value: _vm.lease.extended ? _vm.lease.extended.cash_price : 0,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Down Payment"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-currency", {
            attrs: {
              value: _vm.lease.extended
                ? _vm.lease.extended.down_payment_retained_1st
                : 0,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Lease Payments"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-currency", {
            attrs: {
              value: _vm.lease.extra ? _vm.lease.extra.PymtPerMonth : 0,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col-8 text-left" }, [
        _vm._v(_vm._s(_vm.$t("Number of Payments"))),
      ]),
      _c("div", { staticClass: "col-4 text-right" }, [
        _vm._v(_vm._s(_vm.lease.term)),
      ]),
    ]),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass:
              "btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
            class: { disabled: _vm.loadingContract },
            on: { click: _vm.downloadContract },
          },
          [
            _c("span", { staticClass: "font-14" }, [
              _vm._v(_vm._s(_vm.$t("Download PDF Contract"))),
            ]),
            _c("icon", {
              staticClass: "ml-auto",
              attrs: { icon: "long-arrow-right", size: "xl" },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
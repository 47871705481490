var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseLayout", {
    scopedSlots: _vm._u([
      {
        key: "header-content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "fn-ui-layout-column-header",
                on: {
                  click: function ($event) {
                    return _vm.toggleLayoutColumnShow(_vm.primaryMenu)
                  },
                },
              },
              [
                _c("fn-ui-avatar", {
                  attrs: { src: _vm.avatar, type: "image" },
                }),
                _c("div", { staticClass: "p-3 d-flex flex-column" }, [
                  _c("span", { staticClass: "font-12" }, [
                    _vm._v("Good Morning, "),
                  ]),
                  _c("div", { staticClass: "font-24 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.user.full_name)),
                  ]),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
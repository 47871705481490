<template>
    <div :class="[schema.class, 'fn-type-collection']" :id="schema.id">
        <div class="fn-type-collection__single">
            <fn-form-schema v-if="schema" no-inject :schema="schema" :value="model" @input="onInput" :name-key="nameKey" />
        </div>
    </div>
</template>

<script>

import input from "../mixins/input";

export default {
        name: "CollectionInput",
        mixins: [
            input
        ],
        props: {
            schema: {
                type: Object,
                required: true
            }
        },
        data(){
            return {
                children: []
            }
        },
        computed:{
            model: {
                get: function(){
                    return this.value || {};
                },
                set: function(value){
                    this.$emit('input', {...value});
                }
            }
        },
        methods: {
            onInput(value){
                this.model = value;
            },
        }
    };
</script>

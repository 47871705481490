<template>
    <layout-column mode="responsive" class="fn-ui-screen">
        <template #header>
            <slot name="header">
                <layout-column-header :title="title" :compact="false"></layout-column-header>
            </slot>
        </template>
        <template>
            <slot name="after-header"></slot>
            <loader :loading="loading" variant="dark" height="100%">
                <layout-column-content>
                    <slot></slot>
                </layout-column-content>
            </loader>
        </template>
        <slot v-if="!noHeader" name="footer"></slot>
    </layout-column>
</template>

<script>
import LayoutColumn from "../layout/LayoutColumn";
import LayoutColumnHeader from "../layout/LayoutColumnHeader";
import LayoutColumnContent from "../layout/LayoutColumnContent";

export default {
    name: "Screen",
    components: {
        LayoutColumnContent,
        LayoutColumnHeader,
        LayoutColumn
    },
    props: {
        title: {
            type: String
        },
        loading: Boolean,
        breadcrumbs: {
            type: Array,
            required: false
        },
        noBreadcrumbs: Boolean,
        noContext: Boolean,
        noFooter: Boolean,
        noHeader: Boolean
    }
};
</script>

<style scoped>

</style>

<template>
    <div class="fn-ui-report-range-selector-legend">
        <div
            class="fn-ui-report-range-selector-legend__label"
            v-for="(date, index) in dates"
            :key="index"
            :title="getTitle(index)"
        >
            <div class="fn-ui-report-range-selector-legend__super"><span>{{ getLabelYear(index) }}</span></div>
            <div :class="['fn-ui-report-range-selector-legend__title',type.toLowerCase()]">{{ getLabel(index) }}</div>
        </div>
    </div>
</template>

<script>
    import {forEach} from "lodash";
    import moment from "moment";

    export default {
        name: "ReportRangeSelectorLegend",
        props: {
            type: {
                type: String,
                default: "Month"
            },
            dates: {
                type: Array,
                required: true
            }
        },
        computed: {
            moments() {
                let moments = [];
                forEach(this.dates, (date) => {
                    let moment_ = moment(date.value, "YYYY-MM-DD");
                    moments.push(moment_);
                });
                return moments;
            }
        },
        methods: {
            getLabel(index) {
                if(this.type === "Month") {
                    return this.moments[index].format("MM");
                }
                else if(this.type === "Week") {
                    return this.dates[index].label;
                }
            },
            getLabelYear(index) {
                // if(this.type === "week") {
                //     return;
                // }

                if(index === 0 || index === (this.dates.length - 1)
                    || (this.type === "Month" && this.moments[index].month() == 0)
                    || (this.type === "Week" && this.dates[index].label == "1")
                ) {
                    return this.moments[index].format("YYYY");
                }
            },
            getTitle(index) {
                return this.moments[index].format("MMMM Do YYYY");
            }
        }
    };
</script>

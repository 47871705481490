var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "current-lease-item" }, [
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Lease Name"))),
      ]),
      _c("div", { staticClass: "col text-right" }, [
        _vm._v(_vm._s(_vm.lease.store_name + " " + _vm.$t("Payment"))),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col-2 text-left" }, [
        _vm._v(_vm._s(_vm.$t("Store"))),
      ]),
      _c("div", { staticClass: "col-10 text-right" }, [
        _vm._v(_vm._s(_vm.lease.store_name ?? "")),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Last Payment"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.lease.extra.lastPaymentdate
            ? _c("fn-format-date", {
                attrs: {
                  format: "MM/DD/YYYY",
                  value: _vm.lease.extra.lastPaymentdate,
                  "ignore-time-zone": true,
                },
              })
            : _c("span", [_vm._v("---")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Next Due Date"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.lease.extra.nextDueDate
            ? _c("fn-format-date", {
                attrs: {
                  format: "MM/DD/YYYY",
                  value: _vm.lease.extra.nextDueDate,
                  "ignore-time-zone": true,
                },
              })
            : _c("span", [_vm._v("---")]),
        ],
        1
      ),
    ]),
    !_vm.allowAnyPaymentAmount
      ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
          _c("div", { staticClass: "col text-left" }, [
            _vm._v(_vm._s(_vm.$t("Minimum Due"))),
          ]),
          _c(
            "div",
            { staticClass: "col text-right" },
            [
              _vm.lease.extra.MinDue
                ? _c("fn-format-currency", {
                    attrs: { value: _vm.lease.extra.MinDue },
                  })
                : _c("span", [_vm._v("---")]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Total Due"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.lease.extra.totalDue
            ? _c("fn-format-currency", {
                attrs: { value: _vm.lease.extra.totalDue },
              })
            : _c("span", [_vm._v("-")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Balance"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.lease.extra.totalDue
            ? _c("fn-format-currency", {
                attrs: { value: _vm.lease.extra.balance },
              })
            : _c("span", [_vm._v("---")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t(_vm.displayedPayOffDateLabel))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _vm.displayedPayOffDate
            ? _c("fn-format-date", {
                attrs: {
                  format: "MM/DD/YYYY",
                  value: _vm.displayedPayOffDate,
                  "ignore-time-zone": true,
                },
              })
            : _c("span", [_vm._v("---")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-3" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Provider"))),
      ]),
      _c("div", { staticClass: "col text-right" }, [
        _vm._v(_vm._s(_vm.$t(_vm.getProvider(_vm.lease?.store_type)))),
      ]),
    ]),
    _c("div", { staticClass: "row mb-2" }, [
      _c(
        "div",
        {
          staticClass: "col",
          on: {
            click: function ($event) {
              return _vm.goToDetails()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
            },
            [
              _c("span", { staticClass: "font-14" }, [
                _vm._v(_vm._s(_vm.$t("More Details"))),
              ]),
              _c("icon", {
                staticClass: "ml-auto",
                attrs: { icon: "long-arrow-right", size: "xl" },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col",
          on: {
            click: function ($event) {
              return _vm.handlePayEarly()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
            },
            [
              _c("span", { staticClass: "font-14" }, [
                _vm._v(_vm._s(_vm.$t("Make Payment"))),
              ]),
              _c("icon", {
                staticClass: "ml-auto",
                attrs: { icon: "long-arrow-right", size: "xl" },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
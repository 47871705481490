var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-container welcome",
      staticStyle: { "background-size": "contain" },
    },
    [
      _c("fn-ui-layout", [
        _c(
          "div",
          { staticClass: "fn-layout__screen w-100 position-relative" },
          [_c("router-view")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
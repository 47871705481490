<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t("One moment please...")}}</h4>
        </div>
        <div>
            <loader :loading="true"></loader>
        </div>
    </div>
</template>

<script>
import {AUTH_LOGIN, AUTH_SET_PASSWORD} from "./index";
import {getRedirectTo} from "../../auth";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

export default {
    name: "AuthCheck",
    mixins: [
        withAutoGuard
    ],
    props: {
        redirect: {
            type: [String,Object],
            default() {
                return getRedirectTo();
            }
        }
    },
    data() {
        return {
            loading: true
        };
    },
    mounted(){
        this.checkAuthenticated();
    },
    methods: {
        checkAuthenticated(){
            const uri = "/api/v2/auth/user";

            this.$fnApi
                .call(uri, "GET")
                .then((response) => {
                    if (response.status) {
                        this.$fnAuth.setUser(response.data);
                        this.checkRedirect();
                    } else {
                        this.$fnAuth.removeUser();
                        this.$router.push({
                            name: AUTH_LOGIN
                        });
                    }
                })
                .catch(() => {
                    this.$fnNotify({message: this.$t("Unable to log in")});
                })
            ;
        },
        checkRedirect(){

            const user = this.$fnAuth.getUser();

            //does he need to reset password
            if (user.must_reset_password) {
                this.$router.push({
                    name: AUTH_SET_PASSWORD
                });
                return;
            }

            if (this.redirect) {
                window.location.href = this.redirect;
            } else {
                window.location.href = user.redirect;
            }
        }
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col-2 text-left" }, [
        _vm._v(_vm._s(_vm.$t("Store"))),
      ]),
      _c("div", { staticClass: "col-10 text-right" }, [
        _vm._v(_vm._s(_vm.lease.store_name)),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Last Payment Date"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-date", {
            attrs: {
              format: "MM/DD/YYYY",
              value: _vm.lease.last_payment_date,
              "ignore-time-zone": true,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col-8 text-left" }, [
        _vm._v(_vm._s(_vm.$t("Last Payment Amount"))),
      ]),
      _c(
        "div",
        { staticClass: "col-4 text-right" },
        [
          _c("fn-format-currency", {
            attrs: { value: _vm.lease.monthly_payment },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-3" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Total Paid"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-currency", {
            attrs: { value: _vm.lease?.extra?.totalPaid ?? 0 },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col", on: { click: _vm.goToDetails } }, [
        _c(
          "div",
          {
            staticClass:
              "btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
          },
          [
            _c("span", { staticClass: "font-14" }, [
              _vm._v(_vm._s(_vm.$t("More Details"))),
            ]),
            _c("icon", {
              staticClass: "ml-auto",
              attrs: { icon: "long-arrow-right", size: "xl" },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <span :title="titleFormatted">{{ formatted }}</span>
</template>

<script>

    import numeral from "numeral";

    export default {
        name: "FnFormatNumber",
        props: {
            value: {
                required: false
            },
            format: {
                type: String,
                default() {
                    return "0.000";
                }
            },
            titleFormat: {
                type: String,
                default() {
                    return "0,0.[00]";
                }
            },
            defaultValue: {
                default() {
                    return null;
                }
            }
        },
        computed: {
            formatted: function() {
                if (this.value !== undefined) {
                    return this.formatValue(this.value);
                } else if (this.defaultValue !== null) {
                    return this.formatValue(this.defaultValue);
                } else {
                    return undefined;
                }
            },
            titleFormatted: function() {
                if (this.value !== undefined) {
                    return numeral(this.value).format(this.titleFormat);
                } else if (this.defaultValue !== null) {
                    return numeral(this.defaultValue).format(this.titleFormat);
                } else {
                    return undefined;
                }
            }
        },
        methods: {
            formatValue(value) {
                return numeral(value).format(this.format);
            }
        }
    };

</script>

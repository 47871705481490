var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h4", { staticClass: "text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("Set Password"))),
      ]),
    ]),
    _c(
      "div",
      [
        _c("p", { staticClass: "lead text-center text-gray-600" }, [
          _vm._v(_vm._s(_vm.$t("You need to set / change your password."))),
        ]),
        _c("fn-form", {
          staticClass: "mt-3",
          attrs: { uri: _vm.formUri, "uri-method": "POST", value: _vm.form },
          on: { success: _vm.onSuccess, fail: _vm.onFail },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function ({ submit, invalid, flags, loading, submitting }) {
                return [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "fn-ui-button",
                        {
                          staticClass: "btn-block btn-rounded",
                          attrs: {
                            variant: "primary",
                            disabled: !flags.valid || loading || submitting,
                            submitting: submitting,
                            type: "submit",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Set Password")))]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
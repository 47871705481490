<template>
    <div :class="classes">
        <panel-sidebar
            v-if="withSteps"
            :steps="steps"
            :active-step="activeStep"
            :collapse="collapseSidebar"
            :position="position"
            @toggle-sidebar="handleCollapseSidebar"
            v-on="$listeners"
        ></panel-sidebar>
        <div class="fn-ui-panel__info" v-if="withInfo" :class="infoPanelClass">
            <div class="fn-ui-panel__header">
                <div class="d-flex justify-content-between">
                    <slot name="info-header">
                        <h4 class="mb-0">{{ infoTitle }}</h4>
                    </slot>
                    <span class="pointer" @click.stop="handleToggleInfoPanel"><icon icon="times" type="light" size="2x" ></icon></span>
                </div>
            </div>
            <div class="fn-ui-panel__content">
                <slot name="info-content"></slot>
            </div>
        </div>
        <div class="fn-ui-panel__main" :class="mainPanelClass">
            <div class="fn-ui-panel__header" :class="[headerClass, {'no-padding': noHeaderPadding}]">
                <slot name="header" :toggleInfo="handleToggleInfoPanel" :toggleSidebar="handleCollapseSidebar" :closePanel="emitClosePanel">
                    <div class="d-flex justify-content-between">
                        <h4 class="mb-0">{{ title }}</h4>
                        <div class="panel-buttons-container">
                            <span v-if="withInfo" class="panel-button" @click.prevent="handleToggleInfoPanel"><icon icon="info-circle" type="light" size="2x"></icon></span>
                            <span v-if="withSteps" class="panel-button" @click="handleCollapseSidebar"><icon icon="tasks" size="lg"></icon></span>
                            <span class="panel-button" @click="emitClosePanel"><icon class="panel-button" icon="times" type="light" size="2x"></icon></span>
                        </div>
                    </div>
                </slot>
            </div>
            <div class="fn-ui-panel__content" :class="{'p-0': noContentPadding}">
                <slot name="default" v-bind="{setLoading, closePanel: emitClosePanel}"></slot>
                <loader v-if="loading || loading_" absolute :loading="true"></loader>
            </div>
            <div v-if="!noFooter" :class="{'fn-ui-panel__footer': true, 'p-0': noFooter}">
                <slot name="footer" v-bind="{setLoading, closePanel: emitClosePanel}"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import PanelSidebar from "./PanelSidebar";

export default {
    name: "Panel",
    provide: function () {
        return {
            "panel": this
        };
    },
    components: {
        PanelSidebar,
    },
    props: {
        steps: {
            type: Array,
            default: () => []
        },
        activeStep: {
            type: Number,
            default: 0
        },
        withInfo: {
            type: Boolean,
            default: false
        },
        expandedInfo: {
            type: Boolean,
            default: false
        },
        mainPanelClass: {
            type: String,
            default: "bg-gray-200"
        },
        infoPanelClass: {
            type: String,
            default: "bg-gray-300"
        },
        headerClass: {
            type: String
        },
        title : String,
        infoTitle : String,
        loading: Boolean,
        position: {
            type: String,
            default(){
                return "right";
            }
        },
        noHeaderPadding: Boolean,
        noContentPadding: Boolean,
        noFooterPadding: Boolean,
        noFooter: Boolean
    },
    data() {
        return {
            collapseSidebar: false,
            showInfoPanel: false,
            loading_: false
        };
    },
    computed: {
        classes(){
            return {
                "fn-ui-panel": true,
                "hide-info" : this.withInfo && !this.showInfoPanel,
                "with-info" : this.withInfo,
                "with-steps" : this.withSteps,
                "position-left" : this.position === "left",
                "position-right" : this.position === "right",
                "loading" : true//this.loading || this.loading_
            };
        },
        withSteps() {
            return this.steps.length > 0;
        }
    },
    methods: {
        emitClosePanel() {
            this.collapseSidebar = true;
            this.showInfoPanel = false;
            this.$emit("close");
        },
        handleCollapseSidebar(){
            this.collapseSidebar = !this.collapseSidebar;
        },
        handleToggleInfoPanel() {
            this.showInfoPanel = !this.showInfoPanel;
        },
        setLoading(loading){
            this.loading_ = loading;
        }

    },
    mounted() {
        if(this.expandedInfo) {
            this.showInfoPanel = this.expandedInfo;
        }
    }
};
</script>

<template>
    <component :is="tag" v-bind="$props" :variant="variant" :size="size" @click.prevent="onClick" :title="title" :disabled="submitting" class="fn-ui-promise-button d-inline-flex align-items-center">
        <slot>
            <icon v-if="icon" :icon="icon" aria-hidden="true"></icon>
            <span v-if="text"><span v-if="icon">&nbsp;</span>{{text}}</span>
            <span v-if="submitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" style="margin-left: 10px"></span>
        </slot>
    </component>
</template>

<script>
    export default {
        name: "ButtonPromise",
        props: {
            title: String,
            tag: {
                type: String,
                default() {
                    return "b-button";
                }
            },
            size: {
                type: String,
                default() {
                    return "";
                }
            },
            disabled: Boolean,
            variant: {
                type: String,
                default() {
                    return "";
                }
            },
            icon: {
                type: [String, Boolean],
                default() {
                    return false;
                }
            },
            text: {
                type: [String, Boolean],
                default() {
                    return false;
                }
            },
            promise: {
                type: [Promise, Function],
                required: true
            }
        },
        data(){
            return {
                submitting: false
            };
        },
        methods: {
            onClick(){
                this.submitting = true;
                this.promise().finally(() => {
                    this.submitting = false;
                });
            }
        }
    };
</script>

<template>
    <b-form-input type="text"
                  :id="id"
                  :name="name"
                  :value="value"
                  :class="className"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :readonly="readonly"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :min="minLength"
                  :max="maxLength"
                  :required="required"
                  :state="state"
                  :size="size"
                  :list="list"
                  v-mask="maskValue"
                  v-on="listeners"
                  @accept="handleMaskInput"
    ></b-form-input>
</template>

<script>

    import {BFormInput} from "bootstrap-vue";

    import input from "../mixins/input";
    import mask from "../mixins/mask";
    import minMaxLength from "../mixins/minMaxLength";

    export default {
        name: "TextInput",
        mixins: [
            input,
            minMaxLength,
            mask
        ],
        components: {
            BFormInput
        },
        props: {
            list: String
        }
    };
</script>

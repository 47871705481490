<template>
    <div class="fn-ui-report-section">
        <div class="fn-ui-report-section__header" v-if="title">
            <span class="fn-ui-report-section__title">{{title}}</span>
            <span class="fn-ui-report-section__context">
                <slot name="context"></slot>
            </span>
        </div>
        <div :class="{'fn-ui-report-section__body': true, 'fn-ui-report-section__body--table': table}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportSection",
        props: {
            title: String,
            table: Boolean
        }
    };
</script>

<template>
    <b-form-input type="email"
                  :id="id"
                  :name="name"
                  :value="value"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :readonly="readonly"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :min="minLength"
                  :max="maxLength"
                  :required="required"
                  :state="state"
                  :size="size"
                  v-on="$listeners"
    ></b-form-input>
</template>

<script>

    import {BFormInput} from 'bootstrap-vue';

    import TextInput from "./TextInput";

    export default {
        name: "EmailInput",
        extends: TextInput,
        components: {
            BFormInput
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "loader",
    { attrs: { loading: _vm.loadingHistory } },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col font-18 text-primary font-weight-bold" },
          [_vm._v(_vm._s(_vm.$t("Payment Overview")))]
        ),
      ]),
      _vm.payments.length > 0
        ? _c("payment-history-chart", { attrs: { data: _vm.chartData } })
        : _vm._e(),
      _vm.payments.length > 0
        ? _c(
            "div",
            { staticClass: "mt-3" },
            _vm._l(_vm.payments, function (payment, index) {
              return _c("payment-history-item", {
                key: index,
                attrs: { payment: payment, lease: _vm.lease },
              })
            }),
            1
          )
        : _vm._e(),
      !_vm.loadingHistory && _vm.payments.length <= 0
        ? _c("div", [
            _c("p", [
              _vm._v("No payments have been captured against your lease"),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-card-widget">
        <div v-if="!noHeader" class="card card-top">
            <div class="card-body" style="position: relative;">
                <slot name="header">
                    <div class="widget-header">
                        <div class="widget-heading">
                            <slot name="title">
                                <h4 v-if="title" class="card-title">{{title}}</h4>
                                <p v-if="subtitle" class="card-subtitle text-muted">{{subtitle}}</p>
                            </slot>
                        </div>
                        <div v-if="!noContext" class="widget-context no-wrap">
                            <slot name="context"></slot>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
        <div v-if="!noFilter" class="card card-filters no-wrap">
            <div class="card-body pt-0 d-flex">
                <slot name="filters"></slot>
            </div>
        </div>
        <div :class="{'card': true, 'card-middle': true, 'card-table': table}">
            <slot></slot>
        </div>
        <div v-if="!noFooter" class="card card-top">
            <div class="card-body" style="position: relative;">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CardWidget",
        props: {
            table: Boolean,
            title: String,
            subtitle: String,
            noHeader: Boolean,
            noFooter: Boolean,
            noContext: Boolean,
            noFilter: Boolean,
            filterIcon: String
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "d-flex pb-1 mb-2 pointer",
        on: { click: _vm.handleCancel },
      },
      [
        _c(
          "fn-ui-icon-layers",
          { staticClass: "align-self-center", staticStyle: { width: "50px" } },
          [
            _c("icon", {
              staticStyle: { color: "#DADEE3", opacity: "0.15" },
              attrs: { icon: "circle", type: "solid", size: "3x" },
            }),
            _c("icon", {
              staticClass: "text-primary",
              attrs: { icon: "calendar", type: "solid" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "flex-grow-1 ml-1" }, [
          _c("div", { staticClass: "font-14" }, [
            _vm._v(_vm._s(_vm.lease.store_name + " " + _vm.$t("Payment"))),
          ]),
          _c("div", { staticClass: "font-12" }, [
            _vm._v(_vm._s(_vm.lease.store_name)),
          ]),
          _c(
            "div",
            { staticClass: "font-10" },
            [
              _vm._v("SCHEDULED ON: "),
              _c("fn-format-date", {
                attrs: {
                  format: "MM/DD/YYYY",
                  value: _vm.payment.created_at,
                  "ignore-time-zone": true,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "div",
            { staticClass: "text-primary" },
            [
              _c("fn-format-currency", {
                attrs: { value: _vm.payment.amount },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "font-10 text-muted" },
            [
              _c("strong", [_vm._v("PAY AT:")]),
              _vm._v(" "),
              _c("fn-format-date", {
                attrs: {
                  format: "MM/DD/YYYY",
                  value: _vm.payment.pay_at,
                  "ignore-time-zone": true,
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text-right" },
      [
        _c(
          "fn-ui-button",
          {
            attrs: {
              variant: "outline-primary",
              disabled: _vm.saving,
              submitting: _vm.saving,
            },
            on: { click: _vm.handleCancel },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }
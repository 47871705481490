<template>
    <b-form-checkbox
                  :id="id"
                  :name="name"
                  :checked="value"
                  :value="checkedValue"
                  :unchecked-value="uncheckedValue"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :autofocus="autofocus"
                  :required="required"
                  :state="state"
                  :size="size"
                  :switch="isSwitch"
                  v-on="$listeners"
    ><span v-html="statement"></span></b-form-checkbox>
</template>

<script>

    import {BFormCheckbox} from "bootstrap-vue";

    import input from "../mixins/input";

    export default {
        name: "CheckboxInput",
        mixins: [
            input
        ],
        components: {
            BFormCheckbox
        },
        props: {
            checkedValue: {},
            uncheckedValue: {},
            switch: Boolean,
            statement: {
                type: String,
                required: false
            }
        },
        computed: {
            isSwitch: function(){
                return this.switch;
            }
        }
    };
</script>

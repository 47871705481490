var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    _vm._g(
      {
        attrs: {
          id: _vm.modalId,
          title: "Payment",
          visible: "",
          "ok-only": "",
          "ok-title": _vm.$t("Confirm Payment"),
          centered: "",
        },
        on: { ok: _vm.handleConfirmPayment },
        model: {
          value: _vm.modalShow,
          callback: function ($$v) {
            _vm.modalShow = $$v
          },
          expression: "modalShow",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "loader",
        { staticClass: "w-100", attrs: { loading: _vm.loadingPayment } },
        [
          [
            _c(
              "fn-form-schema",
              {
                ref: "form",
                on: { validated: _vm.handleValidated },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              },
              [
                [
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col font-18 text-primary font-weight-bold",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Payment for ") + _vm.store_name) + " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
                      _c("div", { staticClass: "col text-left" }, [
                        _vm._v(_vm._s(_vm.$t("Start Date"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col text-right" },
                        [
                          _vm.start_date
                            ? _c("fn-format-date", {
                                attrs: {
                                  format: "MM/DD/YYYY",
                                  value: _vm.start_date,
                                  "ignore-time-zone": true,
                                },
                              })
                            : _c("span", [_vm._v("---")]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "label",
                          { staticClass: "mb-0", attrs: { for: "pay_amount" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("How much do you want to pay?"))
                            ),
                          ]
                        ),
                        _c("fn-type-money-input", {
                          attrs: {
                            id: "pay_amount",
                            format: "$0,0.00",
                            minLength: 0,
                            maxLength: 999999,
                          },
                          model: {
                            value: _vm.form.pay_amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pay_amount", $$v)
                            },
                            expression: "form.pay_amount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
              ],
              2
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
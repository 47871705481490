<template>
    <label :class="['star-rating', {'checked': isChecked}]" :for="id">
        <input
            type="checkbox"
            :id="id"
            :name="name"
            :value="inputValue"
            class="star-input"
            :checked="isChecked"
            @change="(evt) => $emit('input', evt.target.value)"
            :disabled="disabled"
        />
        <icon icon="star" :type="starIconType" class="star-icon" />
    </label>
</template>

<script>

    export default {
        name: "StarRating",
        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: false
            },
            inputValue: {
                type: [Number, String],
                required: true
            },
            value: [Number, String],
            disabled: Boolean
        },
        computed: {
            isChecked() {
                return this.value >= this.inputValue;
            },
            starIconType() {
                return (this.value >= this.inputValue) ? "solid" : "light";
            }
        }
    }
</script>

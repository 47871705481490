<template>
    <div :class="[className, 'section']" :id="id">
        <div class="section-wrapper">
            <div class="section-title">
                <h4>{{title}}</h4>
                <p v-if="description">{{description}}</p>
            </div>
            <div class="section-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

import element from "../mixins/element";
import container from "../mixins/container";

export default {
    name: 'SectionContainer',
    mixins: [
        element,
        container
    ],
    props: {
        title: {
            type: String
        },
        description: {
            type: String,
            required: false
        }
    }
}

</script>

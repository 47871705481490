var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-error", [
    _c("div", { staticClass: "error-content" }, [
      _c("h3", { staticClass: "text-white mt-3" }, [
        _vm._v(_vm._s(_vm.$t("Page Not Found"))),
      ]),
      _c("h4", { staticClass: "text-secondary mt-2" }, [
        _vm._v(_vm._s(_vm.$t("Error code 404"))),
      ]),
      _c("p", { staticClass: "text-muted mt-3" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us."
            )
          ) + " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-report-menu-version">
        <div class="fn-ui-report-menu-version__bg"></div>
        <div class="fn-ui-report-menu-version__inner">
            <span class="fn-ui-report-menu-version__title">{{ title }}</span>
            <div v-if="subtitle" class="fn-ui-report-menu-version__subtitle">{{ subtitle }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuVersion",
        props: {
            title: String,
            subtitle:String
        }
    };
</script>

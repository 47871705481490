<template>
    <fn-ui-button :tag="tag" ref="button" v-bind="{variant: $props.variant, size: $props.size}" :tooltip="$props.tooltip" :submitting="submitting" :icon="buttonIcon" :text="buttonText" @click.prevent="onClick"></fn-ui-button>
</template>

<script>

    import Form from "./Form";

    /**
     * A Foundry Button for displaying a form from the URI in a modal.
     *
     * It is typically used when performing a create or edit action against the URI such as creating or editing a user.
     *
     * The initial request to the server is a GET with the _form=true param which returns the form to be rendered.
     *
     * The submit button is then pressed and the form data is sent back to the server.
     */
    export default {
        name: "FormButton",
        props: {

            /**
             * A title attribute value
             */
            title: String,

            /**
             * The URI to fetch the form from
             */
            uri: {
                type: String,
                required: true
            },

            /**
             * The URI Parameters to send to the server
             */
            uriParams: {
                type: Object,
                default(){
                    return {};
                }
            },

            /**
             * The type of tag to render. Defaults ot b-button.
             */
            tag: {
                type: [String, Object],
                default: "b-button"
            },

            /**
             * The size of the button (only works with Vue Bootstrap Buttons)
             *
             * @see https://bootstrap-vue.org/docs/components/button
             */
            size: {
                type: String
            },
            /**
             * The variant of the button (only works with Vue Bootstrap Buttons)
             *
             * @see https://bootstrap-vue.org/docs/components/button
             */
            variant: {
                type: String
            },

            /**
             * The icon to use in the button
             */
            buttonIcon: {
                type: String
            },

            /**
             * The text to display in the button
             */
            buttonText: {
                type: String
            },

            tooltip: {
                type: String
            },

        },
        data() {
            return {
                submitting: false
            };
        },
        methods: {
            onClick() {
                this.loading = true;
                this.$fnPanels.open({
                    title: this.title || this.buttonText,
                    component: Form,
                    props: {
                        uri: this.uri,
                        uriParams: this.uriParams
                    },
                    on: {
                        success: (response) => {
                            this.onSuccess(response);
                            return true;
                        },
                        fail: (response) => {
                            this.onFail(response);
                        },
                        hidden: () => {
                            this.loading = false;
                        },
                        cancel: () => {
                            this.onCancel();
                        }
                    }
                });
            },
            onSuccess(response){
                /**
                 * Success event
                 *
                 * @property {object} response The response from the API
                 */
                this.$emit("success", response);
            },
            onFail(response){
                /**
                 * Fail event
                 * @property {object | string} data The response from the API
                 */
                this.$emit("fail", response);
            },
            onCancel(){
                this.submitting = false;
                /**
                 * Cancel event
                 */
                this.$emit("cancel");
            }
        }
    };

</script>

<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{ $t("One moment please...") }}</h4>
        </div>
        <div>
            <loader :loading="true"></loader>
        </div>
    </div>
</template>

<script>
import {AUTH_LOGIN} from "./index";

export default {
    name: "AuthLogout",
    computed: {
        user: function () {
            return this.$fnAuth.getUser();
        }
    },
    mounted() {
        this.logout();
    },
    methods: {
        logout() {
            this.$fnApi
                .call("/api/v2/auth/logout")
                .then(() => {
                    this.$fnAuth.removeUser();
                    this.$fnNotify(this.$t("You have been logged out!"));
                }).finally(() => {
                this.$router.push({
                    name: AUTH_LOGIN
                });
            });
        }
    }
};
</script>

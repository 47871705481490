<template>
    <div>
        <div :class="['fn-ui-layout-navigation-link',
        { 'fn-ui-layout-navigation-link--compact' : compact },
        ]" v-b-toggle="'#collapse-'+menuId">
            <span v-if="avatar" class="fn-ui-layout-navigation-link__avatar">
                <avatar :src="avatar" type="image" size="sm" />
            </span>
            <span v-else class="fn-ui-layout-navigation-link__icon">
                <icon v-if="icon && compact" :icon="iconCompact ? iconCompact : icon"></icon>
                <icon v-else-if="icon && !compact" :icon="icon"></icon>
            </span>
            <span class="fn-ui-layout-navigation-link__text">{{ text }}</span>
            <span class="fn-ui-layout-navigation-link__caret">
                <span :class="['caret', {'hidden' : compact}]">
                    <icon :icon="caretIcon"></icon>
                </span>
            </span>
        </div>
        <b-collapse :id="'#collapse-'+menuId" :visible="showChildren_" ref="collapseContainer" @show="() => toggleShowChildren(true)" @hidden="() => toggleShowChildren(false)">
            <div class="fn-ui-layout-navigation-group">
                <slot></slot>
            </div>
        </b-collapse>
    </div>
</template>

<script>

    import {$_fnNavGroup} from "./common";
    import Avatar from "../avatar/Avatar";

    export default {
        name: "LayoutNavigationGroup",
        components: {
            Avatar
        },
        provide: function () {
            return {
                [$_fnNavGroup]: this
            };
        },
        props: {
            compact: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: null
            },
            caretIcon: {
                type: String,
                default: 'chevron-down'
            },
            avatar: {
                type: String,
                default: null
            },
            iconCompact: {
                type: String,
                default: null
            },
            text: {
                type: String,
                required: true
            },
            showChildren: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showChildren_: this.showChildren
            };
        },
        computed: {
            menuId() {
                return "id-"+this._uid;
            },
        },
        methods: {
            toggleShowChildren(show){
                this.showChildren_ = show;
                if (show) this.$emit("show-children");
            }
        },
        watch: {
            compact(value) {
                if(value) {
                    this.toggleShowChildren(false);
                    this.$refs.collapseContainer.slotScope.close();
                }
            }
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("img", {
      staticClass: "col-8 offset-2 col-lg-4 offset-lg-4",
      attrs: { src: _vm.logo, alt: "" },
    }),
    _c(
      "div",
      {
        staticClass:
          "col-12 d-flex flex-column justify-content-center bg-primary text-white p-5",
      },
      [
        _c("h4", { staticClass: "pt-2" }, [
          _vm._v(_vm._s(_vm.$t("We'd Love To Hear From You!"))),
        ]),
        _c("p", { staticClass: "font-11 pt-2" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "Our daily goal is to make your Okinus experience the best that it can be with friendly representatives, and fast, flexible lease purchase programs. We enjoy hearing from you, whether you're a new or existing customer or part of our certified retailer network. So if you have any comments, questions or need assistance now, simply select a contact method below."
              )
            )
          ),
        ]),
        _c("hr", { staticClass: "w-100 border-secondary py-2" }),
        _c("ul", { staticClass: "font-11 fa-ul" }, [
          _c("li", { staticClass: "mt-2 pl-1" }, [
            _c(
              "span",
              { staticClass: "fa-li" },
              [
                _c("icon", {
                  staticClass: "text-secondary",
                  attrs: { icon: "map-marker-alt", type: "solid" },
                }),
              ],
              1
            ),
            _vm._v(
              _vm._s(
                _vm.$t("147 W Railroad St S, Pelham, GA 31779, United States")
              )
            ),
          ]),
          _c(
            "li",
            { staticClass: "mt-2 pl-1" },
            [
              _c(
                "span",
                { staticClass: "fa-li" },
                [
                  _c("icon", {
                    staticClass: "text-secondary",
                    attrs: { icon: "phone", type: "solid" },
                  }),
                ],
                1
              ),
              _vm._v(_vm._s(_vm.$t("General")) + ": "),
              _c("fn-format-phone", {
                staticClass: "info-links",
                attrs: { format: "none", value: "800-472-1334" },
              }),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "mt-2 pl-1" },
            [
              _c(
                "span",
                { staticClass: "fa-li" },
                [
                  _c("icon", {
                    staticClass: "text-secondary",
                    attrs: { icon: "phone", type: "solid" },
                  }),
                ],
                1
              ),
              _vm._v(_vm._s(_vm.$t("After Hours")) + ": "),
              _c("fn-format-phone", {
                staticClass: "info-links",
                attrs: { format: "none", value: "229-405-6656" },
              }),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "mt-2 pl-1" },
            [
              _c(
                "span",
                { staticClass: "fa-li" },
                [
                  _c("icon", {
                    staticClass: "text-secondary",
                    attrs: { icon: "envelope", type: "solid" },
                  }),
                ],
                1
              ),
              _c("fn-format-email", {
                staticClass: "info-links",
                attrs: { value: "information@okinus.com" },
              }),
            ],
            1
          ),
          _c("li", { staticClass: "mt-2 pl-1" }, [
            _c(
              "span",
              { staticClass: "fa-li" },
              [
                _c("icon", {
                  staticClass: "text-secondary",
                  attrs: { icon: "clock", type: "regular" },
                }),
              ],
              1
            ),
            _vm._v(
              _vm._s(
                _vm.$t(
                  "Monday - Friday 9 AM - 8 PM Saturday - 10 AM - 8 PM Sunday 1 PM - 7 PM"
                )
              )
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-report-menu-summary-section-data">
        <span class="fn-ui-report-menu-summary-section-data__label">{{label}}</span>
        <span class="fn-ui-report-menu-summary-section-data__value">{{value}}</span>
        <span v-if="percentDiff" class="fn-ui-report-menu-summary-section-data__diff">{{percentDiff}}</span>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuSummarySectionData",
        props: {
            label: String,
            value: [String,Number],
            percentDiff: [String,Number]
        }
    };
</script>

<template>
    <div class="fn-ui-panel__sidebar" :class="{'collapsed': collapse}" >
        <div class="sidebar-collapse text-center py-4" @click="emitToggleSidebar">
            <icon class="step-icon" :icon="icon" size="2x"></icon>
        </div>
        <div class="sidebar-item"
            :class="{
                'step-done' : step.done,
                'step-active': index === activeStep,
                'step-complete': step.complete
            }"
            v-for="(step,index) in steps"
            :key="index"
            @click="() => emitChangeTab(index)">
            <div class="step-icon">
                <icon v-if="step.complete" icon="flag" type="light"></icon>
                <icon v-else-if="step.done" icon="check-square"></icon>
                <icon v-else icon="square"></icon>
            </div>
            <div class="step-text">{{ step.title }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PanelSidebar",
        props: [
            "steps",
            "collapse",
            "activeStep",
            "position"
        ],
        methods: {
            emitToggleSidebar() {
                this.$emit("toggle-sidebar");
            },
            emitChangeTab(index) {
                this.$emit("change-step", index);
            }
        },
        computed: {
            icon(){
                if (this.position === "left") {
                    return "chevron-left";
                } else {
                    return "chevron-right";
                }
            }
        }
    };
</script>

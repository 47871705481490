<template>
    <div class="fn-ui-report-menu-hierarchy">
        <div class="fn-ui-report-menu-hierarchy__header">
            <span class="fn-ui-report-menu-hierarchy__title">{{ title }}</span>
            <div class="fn-ui-report-menu-hierarchy__buttons" v-if="!hideControls">
                <span class="text-primary" @click="handleSelect">Select</span>
                <span class="text-danger" @click="handleClear">Clear</span>
            </div>
        </div>
        <div class="fn-ui-report-menu-history__list">
            <template v-if="children && children.base">
                <report-menu-hierarchy-item
                    v-for="(id) in children.base"
                    :key="id"
                    :id="id"
                    :children="children"
                    :items="items"
                    :depth="0"
                    :label="items[id].label"
                    @change="handleChange"
                >
                </report-menu-hierarchy-item>
            </template>
            <template v-else>
                <p class="m-0">{{$t("All selected")}}</p>
            </template>
        </div>
    </div>
</template>

<script>
    import ReportMenuHierarchyItem from "./ReportMenuHierarchyItem";

    export default {
        name: "ReportMenuHierarchy",
        components: {
            ReportMenuHierarchyItem
        },
        props: {
            title: String,
            items: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            children: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            hideControls: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleSelect() {
                this.$emit("select");
            },
            handleClear() {
                this.$emit("clear");
            },
            handleChange(id, checked){
                this.$emit("change", id, checked);
            }
        }
    };
</script>

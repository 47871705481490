<template>
    <b-form-input type="url"
                  :id="id"
                  :name="name"
                  :value="value"
                  :class="className"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :readonly="readonly"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :min="minLength"
                  :max="maxLength"
                  :required="required"
                  :state="state"
                  :size="size"
                  v-mask="maskValue"
                  v-on="listeners"
                  @accept="handleMaskInput"
    ></b-form-input>
</template>

<script>

    import {BFormInput} from 'bootstrap-vue';

    import TextInput from "./TextInput";

    export default {
        name: "UrlInput",
        extends: TextInput,
        props: {
            value: {
                required: false
            },
        },
        components: {
            BFormInput
        }
    };
</script>

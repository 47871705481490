<template>
    <div :class="classes" :style="{'min-height': (loading) ? height : null}">
        <div v-if="loading">
            <div :class="{'fn-ui-loading': true, 'no-background': transparent}">
                <div class="text-center">
                    <div :class="spinnerClasses" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: {
            variant: {
                type: String,
                default : "dark"
            },
            size: {
                type: String,
                default: "md",
                validator: function (value) {
                    return ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1;
                }
            },
            loading: {
                type: Boolean,
                required: false
            },
            height: {
                type: String,
                default() {
                    return "80px";
                }
            },
            transparent: Boolean,
            absolute: Boolean
        },
        computed: {
            classes: function(){
                return {
                    "fn-ui-loading-wrapper": true,
                    "fn-ui-loading-wrapper--relative": !this.absolute,
                };
            },
            spinnerClasses: function(){
                let classes = [
                    'spinner-border',
                    'text-' + this.variant,
                    'spinner-border-' + this.size,
                ];
                return classes;
            }
        },
    };
</script>

<template>
    <ul class="pagination no-outline mb-0">
        <li class="page-item"><button :disabled="!canMovePrev" class="page-link" @click.prevent="()=> $emit('previous-page')">
            <icon icon="chevron-left" size="xs"/>
        </button></li>
        <li class="page-item">
            <span class="page-link">Page</span>
        </li>
        <li class="page-item active">
            <b-input :value="activePage" @change="(value)=> $emit('page', value)" :disabled="!canMoveNext && !canMovePrev"></b-input>
        </li>
        <li class="page-item">
            <span class="page-link">of {{ totalPages }}</span>
        </li>
        <li class="page-item"><button :disabled="!canMoveNext" class="page-link" @click.prevent="()=> $emit('next-page')">
            <icon icon="chevron-right" size="xs"/>
        </button></li>
    </ul>
</template>
<script>
export default {
    name: "Pagination",
    props: {
        activePage: {
            type: Number,
            default: 0
        },
        totalPages: {
            type: Number,
            default: 0
        }
    },
    computed: {
        canMoveNext: function(){
            return (this.activePage < this.totalPages) && this.totalPages > 1;
        },
        canMovePrev: function(){
            return (this.activePage > 1) && this.totalPages > 1;
        }
    },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h4", { staticClass: "text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("One Time Login"))),
      ]),
    ]),
    _c(
      "div",
      [
        _vm.loading
          ? _c("loader", { attrs: { loading: true } })
          : _c("div", [
              _c("p", { staticClass: "text-center text-gray-600" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "Unable to process your one time login. It might have expired. Please try login below or contact support."
                    )
                  )
                ),
              ]),
              _c(
                "p",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        block: "",
                        to: _vm.loginRoute,
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Login")))]
                  ),
                ],
                1
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
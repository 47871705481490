<template>
    <b-modal v-bind="$props"
             visible
             hide-header-close
             no-close-on-esc
             no-close-on-backdrop
             v-on="$listeners"
             @ok="() => handleConfirm(true)"
             @cancel="() => handleConfirm(false)"
         >{{message}}</b-modal>
</template>

<script>
export default {
    name: "ConfirmModal",
    props: {
        title: {
            type: String,
            default: "Confirmation"
        },
        size: {
            type: String,
            default: "md"
        },
        okVariant: {
            type: String,
            default: "primary"
        },
        okTitle: {
            type: String,
            default: "Yes"
        },
        cancelVariant: {
            type: String
        },
        cancelTitle: {
            type: String,
            default: "No"
        },
        centered: Boolean,
        message: {
            type: String,
            required: true
        }
    },
    methods: {
        handleConfirm(confirmed){
            this.$emit("resolve", confirmed);
        },
        handleClose(){
            this.$emit("reject");
        }
    }
};
</script>

<style scoped>

</style>

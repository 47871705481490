var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col font-18 text-primary font-weight-bold" },
          [_vm._v(_vm._s(_vm.$t("Items")))]
        ),
      ]),
      _vm.items.length > 0
        ? _c("b-table-lite", {
            staticClass: "font-12",
            attrs: {
              small: "",
              borderless: "",
              fields: _vm.fields,
              items: _vm.items,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(total)",
                  fn: function ({ item }) {
                    return [_c("span", [_vm._v(_vm._s(_vm.getTotal(item)))])]
                  },
                },
                {
                  key: "cell(qty)",
                  fn: function ({ item }) {
                    return [_c("span", [_vm._v(_vm._s(item.quantity))])]
                  },
                },
                {
                  key: "custom-foot",
                  fn: function () {
                    return [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "text-right text-light font-10",
                            attrs: { colspan: "3" },
                          },
                          [_vm._v("Total")]
                        ),
                        _c("td", { attrs: { colspan: "1" } }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4253541620
            ),
          })
        : _c("p", { staticClass: "small text-center" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "No line items were captured at the creation of your lease."
                )
              )
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
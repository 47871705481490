<template>
    <ol class="breadcrumb fn-ui-breadcrumb">
        <li class="breadcrumb-item">
            <div class="ellipsis-container">
                <a :class="['breadcrumb-ellipsis', {'hover':active}]" href="#">
                    <icon icon="ellipsis-h"></icon>
                </a>
            </div>
        </li>
        <slot></slot>
    </ol>
</template>

<script>
    export default {
        name: "Breadcrumb",
        props: {
            active: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("loader", {
    attrs: { loading: _vm.loading },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ loading }) {
          return [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v("Payment Details"),
                ]),
                _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
                  _vm._v(_vm._s(_vm.store_name)),
                ]),
                _c(
                  "p",
                  { staticClass: "card-text" },
                  [
                    _vm.pay_amount
                      ? _c("fn-format-currency", {
                          attrs: { value: _vm.pay_amount },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", {
              staticClass: "payliance-iframe",
              attrs: { id: _vm.iframeId },
            }),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "fn-ui-button",
                  {
                    staticClass: "btn-block",
                    attrs: {
                      variant: "outline-primary",
                      disabled: loading || _vm.submitting,
                      submitting: _vm.submitting,
                    },
                    on: { click: _vm.createCardAndPay },
                  },
                  [_vm._v(_vm._s(_vm.$t("Make Payment Now")) + " ")]
                ),
                _c(
                  "fn-ui-button",
                  {
                    staticClass: "btn-block",
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v(_vm._s(_vm.$t("Cancel")))]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
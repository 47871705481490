<template>
    <layout-column
        :mode="mode"
        :class="['fn-ui-layout__nav fn-ui-layout__secondary-nav', {'show': show}]"
        @mouseover.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #header>
            <layout-column-header
                :compact="computedCompact"
                :title="contextTitle"
                icon="chevron-circle-right"
                icon-compact="chevron-circle-left"
                closeable
                @toggle-compact="toggleCompact"
                @toggle-close="toggleShow"
            >
                {{ contextTitle }}
            </layout-column-header>
        </template>
        <template #default v-if="contextActions.length || contextNavigation.length">
            <div class="fn-ui-layout-column-body">
                <layout-navigation-list
                    v-if="contextActions.length"
                    title="Actions"
                    title-compact="Act"
                    :compact="computedCompact"
                    :items="contextActions"
                    @show-children="() => toggleCompact(false)"
                    @click="$emit('click')"
                />
                <layout-navigation-list
                    v-if="contextNavigation.length"
                    title="Navigation"
                    title-compact="Nav"
                    :compact="computedCompact"
                    :is-menu-collapsed="false"
                    :items="contextNavigation"
                    @show-children="() => toggleCompact(false)"
                    @click="$emit('click')"
                />
            </div>
        </template>
    </layout-column>
</template>

<script>
import {LayoutColumn, LayoutColumnHeader, LayoutNavigationList} from "../layout";
import {fnMenuAbilityMixin, fnMenuHoverMixin} from "@fndry-vue/fn-menus";

export default {
    name: "ContextMenuColumn",
    mixins: [
        fnMenuAbilityMixin,
        fnMenuHoverMixin
    ],
    components: {
        LayoutColumn,
        LayoutColumnHeader,
        LayoutNavigationList,
    },
    props: {
        compact: Boolean,
        show: Boolean,
        contextTitle: String,
        contextActions: {
            type: Array,
            default(){
                return [];
            }
        },
        contextNavigation: {
            type: Array,
            default(){
                return [];
            }
        },
    },
    computed: {
        mode() {
            return this.computedCompact ? "compact" : "expanded";
        },
    },
    methods: {
        toggleCompact(compact) {
            if(compact !== undefined) {
                this.compactWhileHovered = compact;
            } else {
                this.compactWhileHovered = !this.compactWhileHovered;
            }
            this.$emit("toggle-compact", compact);
        },
        toggleShow() {
            this.$emit("toggle-show");
        },
    },
};
</script>

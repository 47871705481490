<template>
    <panel @close="handleClose" :title="formTitle" :with-info="withInfo" :info-title="infoTitle">
        <template #info-content>
            <div v-html="infoContent"></div>
        </template>

        <h4>{{ formHeading }}</h4>
        <p>{{ formInstruction }}</p>

        <fn-form ref="fnForm"
                 v-bind="$props"
                 v-on="$listeners"
                 v-model="form"
                 @success="emitClose"
                 @cancel="handleClose"
                 @validated="onValidated"
                 @schema-set="onSchemaSet"
        >
            <template #buttons="{loading, submitting, flags}">
                <div class="mt-5">
                    <fn-ui-button
                        type="submit"
                        variant="primary"
                        class="btn-block"
                        :disabled="loading || submitting || !flags.valid"
                        :submitting="submitting"
                        :text="saveButtonText"
                    />
                    <fn-ui-button
                        type="button"
                        variant="link"
                        class="btn-block"
                        :text="cancelButtonText"
                        @click="handleClose"
                    />
                </div>
            </template>
        </fn-form>
    </panel>
</template>

<script>
import Panel from "./Panel";
import withPanelForm from "@fndry-vue/fn-panels/mixins/withPanelForm";
import withFormInfoPanels from "@fndry-vue/fn-form/mixins/withFormInfoPanels";

export default {
    name: "PanelInfoForm",
    mixins:[
        withPanelForm,
        withFormInfoPanels
    ],
    components: {
        Panel
    },
    props: {
        title: String,
        uri: String,
        uriParams: {
            type: Object,
            required: false
        },
        uriMethod: {
            type: String,
            required: false
        },
        saveButtonText: {
            type: String,
            default(){
                return this.$t("Save");
            }
        },
        cancelButtonText: {
            type: String,
            default(){
                return this.$t("Cancel");
            }
        }
    },
    data(){
        return {
            form: null
        };
    }
};
</script>

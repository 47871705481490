<template>
    <div class="form-buttons d-flex align-items-end justify-content-end">
        <slot>
            <button v-if="cancelButton !== false" type="button" class="btn btn-cancel" :disabled="submitting" @click.prevent="() => handleClick(cancel)">
                {{cancel.label}}
                <b-spinner v-if="(active === 'cancel') && submitting" small label="Loading" type="grow" style="margin-left: 15px"></b-spinner>
            </button>
            <span v-if="buttons">
                <span v-for="(button, index) in buttons" :key="index">
                    <b-button :variant="buttonVariant(button)" :disabled="submitting" @click.prevent="() => handleClick(button)" :key="index">
                        {{button.label}}
                        <b-spinner v-if="(active === index) && submitting" small label="Loading" type="grow" style="margin-left: 15px"></b-spinner>
                    </b-button>
                </span>
            </span>
            <div v-else>
                <button v-if="submitButton !== false" type="submit" class="btn btn-primary" :disabled="!state || submitting" @click.prevent="() => handleClick(submit)">
                    {{submit.label}}
                    <b-spinner v-if="(active === 'submit') && submitting" small label="Loading" type="grow" style="margin-left: 15px"></b-spinner>
                </button>
            </div>
        </slot>
    </div>
</template>

<script>

    import {merge} from "lodash";

    import {BButton} from "bootstrap-vue";

    /**
     * Forms buttons to display
     *
     */
    export default {
        name: "FormButtons",
        components: {
            BButton
        },
        props: {
            /**
             * Controls the state of the buttons
             */
            submitting: {
                type: Boolean,
                default: false
            },
            /**
             * The submit button text
             */
            submitButton: {
                type: [Boolean, String, Object],
                default() {
                    return {
                        label: "Submit"
                    };
                }
            },
            /**
             * The cancel button text
             */
            cancelButton: {
                type: [Boolean, String, Object],
                default() {
                    return {
                        label: "Cancel"
                    };
                }
            },
            /**
             * An array of buttons to display
             */
            buttons: {
                type: [Array, Boolean],
                default() {
                    return false;
                }
            },
            state: {
                type: Boolean,
                required: false
            },
            active: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                /**
                 * The default submit button properties
                 */
                submit: (typeof(this.submitButton) === "string") ? {label: this.submitButton, name: "submit", type: "submit"}: merge({name: "submit", type: "submit"}, this.submitButton),
                /**
                 * The default cancel button properties
                 */
                cancel: (typeof(this.cancelButton) === "string") ? {label: this.cancelButton, name: "cancel", type: "cancel"}: merge({name: "cancel", type: "cancel"}, this.cancelButton),
            };
        },
        methods: {
            /**
             * Handle a click on a button
             *
             * @param button
             */
            handleClick(button){
                /**
                 * Click event
                 *
                 * @property {object} button The button that was clicked
                 */
                this.$emit("click", button);
            },
            /**
             * Determines the variant of the button
             *
             * @param button
             * @returns {null|string}
             */
            buttonVariant(button) {
                if (button.variant) {
                    return button.variant;
                }
                if (button.type === "submit") {
                    return "primary";
                } else {
                    return null;
                }
            }
        }
    };

</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex pb-1 mb-2 pointer",
      on: { click: _vm.goToPaymentDetails },
    },
    [
      _c(
        "fn-ui-icon-layers",
        { staticClass: "align-self-center", staticStyle: { width: "50px" } },
        [
          _c("icon", {
            staticStyle: { color: "#DADEE3", opacity: "0.15" },
            attrs: { icon: "circle", type: "solid", size: "3x" },
          }),
          _c("icon", {
            staticClass: "text-primary",
            attrs: { icon: "dollar-sign", type: "solid" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex-grow-1 ml-1" }, [
        _c("div", { staticClass: "font-14" }, [
          _vm._v(_vm._s(_vm.lease.store_name + " " + _vm.$t("Payment"))),
        ]),
        _c("div", { staticClass: "font-12" }, [
          _vm._v(_vm._s(_vm.lease.store_name)),
        ]),
        _c("div", { staticClass: "font-10" }, [
          _vm._v(_vm._s(_vm.payment["Loan Number"])),
        ]),
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "div",
          { staticClass: "text-primary" },
          [
            _c("fn-format-currency", {
              attrs: { value: _vm.payment.TotalPymtLease },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "font-10 text-muted" },
          [
            _c("fn-format-date", {
              attrs: {
                format: "MM/DD/YYYY",
                value: _vm.payment.dateofpaymt,
                "ignore-time-zone": true,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
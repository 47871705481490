<template>
    <b-tabs v-bind="$props" class="tab-card" content-class="card" v-on="$listeners" v-model="activeTab">
        <slot></slot>
        <template #tabs-end>
            <b-dropdown :text="dropdownTabText" class="dropdown-tab" :toggle-class="{'active' : isDropDownActive }" variant="link" :style="dropdownStyles">
                <template v-for="(tab,index) in dropDownTabs">
                    <b-dropdown-item
                        @click="activateTab(tab.index)"
                        :key="index"
                        :active="tab.index === activeTab"
                    >{{ tab.tabName }}
                    </b-dropdown-item>
                </template>
            </b-dropdown>
        </template>
    </b-tabs>
</template>

<script>
    import resizeObserverMixin from "../../mixins/resizeObserverMixin";
    import {findIndex} from "lodash";

    export default {
        name: "CardTab",
        mixins:[resizeObserverMixin],
        props: {
            value : [String, Number],
            lazy: Boolean,
            fill: Boolean,
            responsive: {
                type: Boolean,
                default: true
            },
            dropdownText: {
                type: String,
                default: 'Inactive Tabs'
            }
        },
        data() {
            return {
                dropDownTabs: [],
                activeTab: this.value,
            };
        },
        computed: {
            dropdownStyles() {
                if(this.responsive && this.dropDownTabs.length > 0) {
                    return {
                        opacity: 1,
                        position: "relative",
                    };
                }
                else {
                    return {
                        opacity: 0,
                        position: "absolute",
                        "z-index" : -1,
                    };
                }

            },
            isDropDownActive() {
                return (this.dropDownTabs.length > 0) && (findIndex(this.dropDownTabs,{index: this.activeTab}) !== -1);
            },
            dropdownTabText() {
                let index = findIndex(this.dropDownTabs,{index: this.activeTab});
                if( index !== -1) {
                    return this.dropDownTabs[index].tabName;
                } else {
                    return this.dropdownText;
                }

            }
        },
        methods: {
            activateTab(index) {
                this.activeTab = index;
            }
        },
        watch: {
            elemWidth(value) {
                if(this.$slots.default && this.$slots.default.length > 0) {
                    let currentTabWidth = 0;
                    let navTabContainerWidth = this.$el.querySelector(".nav-tabs").clientWidth;
                    let navTabs = this.$el.querySelectorAll(".nav-item:not(.dropdown)");

                    this.dropDownTabs = [];

                    for (let i = 0; i < navTabs.length; i++) {
                        navTabs[i].classList.remove("d-none");
                    }

                    for (let i = 0; i < navTabs.length; i++) {
                        let dropdownTab = this.$el.querySelector(".dropdown-tab");
                        let dropdownWidth = dropdownTab ? dropdownTab.clientWidth : 0;

                        if(navTabContainerWidth < (currentTabWidth + navTabs[i].clientWidth + dropdownWidth)) {
                            this.dropDownTabs.push({
                                index: i,
                                tabName: navTabs[i].outerText
                            });
                            navTabs[i].classList.add("d-none");
                        } else {
                            currentTabWidth += navTabs[i].clientWidth;
                        }
                    }
                }
            }
        }
    };
</script>

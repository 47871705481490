<template>
    <div class="fn-ui-file-upload">

        <div  v-if="!uploading" class="file-uploaded d-flex align-items-center border rounded p-2" :class="bgClass">
            <div v-if="!uploading" class="file-upload-thumbnail flex-shrink-1 mr-2">
                <img class="fn-upload-image" v-if="url && false" :src="url"/>
                <div v-else class="fn-placeholder-image d-flex align-items-center justify-content-center small">
                    {{ "." + getName.split('.').pop().substring(0, 3).toUpperCase() }}
                </div>
            </div>

            <div v-if="!uploading" class="flex-grow-1">
                <div class="file-name">{{ original_name }}
                    <slot v-if="enableEdit" name="rename-button">
                        <span class="ml-2 pointer" @click="onRename"><icon icon="edit" class="text-black-50"></icon></span>
                    </slot>
                </div>
                <span class="text-gray-500" v-if="size && !failed">{{ sizeToMb(size) }}</span>
                <span class="text-gray-500 pl-3" v-if="playtime && !failed">{{ playtimeToMinutes(playtime) }}</span>
                <span v-if="failed"><small class="text-danger">{{error}}</small></span>
            </div>

            <div class="file-upload-buttons flex-shrink-1 d-flex justify-content-end align-items-center">
                    <span v-if="!uploading">
                        <slot name="buttons">
                            <button class="btn btn-secondary btn-sm" :title="closeTitle" @click="onClose">
                                <icon :icon="closeIcon" class="text-black-50"></icon>
                            </button>
                        </slot>
                    </span>
            </div>
        </div>



        <div v-if="uploading" class="file-uploading d-flex">

            <div class="fn-placeholder-image small mr-2">
                {{ "." + getName.split('.').pop().substring(0, 3).toUpperCase() }}
            </div>
            <div v-if="uploading" class="flex-grow-1">
                <div class="file-name">{{ getName }}</div>
                <div class="fn-progress-container">
                    <div class="progress" :style="progressStyle">
                        <div :class="progressBarClass" role="progressbar" :style="progressBarStyle" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="failed" class="file-upload-errors">
            <small class="text-danger">
                <span v-for="(error, index) in validation" :key="index" class="file-upload-error mb-1">{{error}}</span>
            </small>
        </div>

    </div>
</template>

<script>
    import {padStart} from "lodash";

    export default {
        name: "FileUpload",
        props: {
            /**
             * The file name of the file
             */
            file: [Object,File],
            /**
             * The file name of the file
             */
            original_name: String,
            /**
             * The file URL
             */
            url: String,
            /**
             * The height in px. I.E. "2px"
             */
            height: String,
            /**
             * The progress between 0 and 100
             */
            progress: Number,
            /**
             * If uploading
             */
            uploading: Boolean,
            /**
             * If uploaded
             */
            uploaded: Boolean,
            /**
             * If failed
             */
            failed: Boolean,
            /**
             * The error text to display as the title of the exclamation icon
             */
            error: String,
            /**
             * Array of error messages
             */
            validation: Array,
            /**
             * The close button title
             */
            closeTitle: {
                type: String,
                default: "Close"
            },
            closeIcon: {
                type: String,
                default: "times"
            },
            successIcon: {
                type: String,
                default: "check"
            },
            errorIcon: {
                type: String,
                default: "exclamation-circle"
            },
            uploadIcon: {
                type: String,
                default: "file-upload"
            },
            border: Boolean,
            /**
             * Don't display the percentage
             */
            noPercent: Boolean,
            /**
             * Display a thumbnail rather than a row
             */
            thumbnail: Boolean,
            /**
             * Show the index of this file within a list
             */
            fileIndex: {
                type: Number,
                default: 1
            },
            /**
             * Show edit file name button
             */
            enableEdit:Boolean,
            /**
             * Background class of the inner cointainer
             */
            bgClass: {
                type: String,
                default: " bg-gray-200"
            },
            size: [Number, String],
            playtime: Number
        },
        computed: {
            progressNumber: function(){
                return Math.round(this.progress);
            },
            progressStyle: function(){
                return {
                    height: this.height
                };
            },
            progressBarStyle: function(){
                return {
                    width: this.progressNumber + "%"
                };
            },
            progressBarClass: function(){
                return {
                    "progress-bar": true,
                    "bg-primary": !this.failed && this.uploaded,
                    "bg-danger": this.failed
                };
            },

            index: function() {
                return padStart((this.fileIndex + 1),2,"0");
            },
            getName() {
                if(this.file && this.file.name){
                    return this.file.name;
                }
                return this.original_name;
            }
        },
        methods: {
            onClose(){
                /**
                 * Emitted when the close button is clicked
                 */
                this.$emit("close");
            },

            onRename() {
                this.$emit("rename");
            },
            sizeToMb(size) {
                return (parseInt(size) / (1024*1024)).toFixed(2) + "mb";
            },

            playtimeToMinutes(time) {
                // Hours, minutes and seconds
                let hrs = Math.floor(time / 3600);
                let mins = Math.floor(time / 60) - (hrs * 60);
                let secs = time - (mins * 60) - (hrs * 3600);

                // Output like "1:01" or "4:03:59" or "123:03:59"
                let ret = "";
                if (hrs > 0) {
                    ret += "" + hrs + "hours ";
                }
                if (mins > 0 || hrs > 0) {
                    ret += "" + mins + "mins";
                }
                if (secs > 0) {
                    ret += " " + secs + "secs";
                }
                return ret;
            }
        }
    };
</script>

<style scoped>

</style>

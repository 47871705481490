<template>
    <div class="fn-ui-report-menu-search-section">
        <div class="fn-ui-report-menu-search-section__title">{{ title }}</div>
        <div class="fn-ui-report-menu-search-section__text">{{ text }}</div>

        <div class="fn-ui-report-menu-search-section__search-wrapper">
            <div class="search-buttons">
<!--                <span @click="handleFilter">Filter</span> |-->
                <span @click="() => handleSearch('')">Clear</span>
            </div>
            <b-form-input :value="value" :placeholder="placeholder" size="sm" @input="handleSearch"></b-form-input>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuSearchSection",
        props: {
            title: String,
            text: String,
            value: String,
            placeholder: {
                type: String,
                default: "Start Typing"
            }
        },
        methods: {
            handleFilter() {
                this.$emit("filter");
            },
            handleSearch(value) {
                this.$emit("input", value);
            }
        }
    };
</script>


<template>
    <a :href="uri">{{ formatted }}</a>
</template>

<script>

    import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

    export default {
        name: 'FnFormatPhone',
        props: {
            value: {
                type: [String, Number],
                required: true,
            },
            format: {
                type: String,
                default() {
                    return 'international'
                }
            }
        },
        data() {
            return {
                number: parsePhoneNumberFromString(this.value, 'US')
            };
        },
        computed: {
            formatted: function() {
                if(this.format === 'none') {
                    return this.value
                }
                else if (this.format === 'national') {
                    return this.number.format('NATIONAL');
                } else {
                    return this.number.format('INTERNATIONAL');
                }
            },
            uri: function() {
                return this.format === 'none' ? 'tel:' + this.value : 'tel:' + this.number.number;
            }
        }
    }

</script>

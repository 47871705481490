<template>
    <div class="fn-ui-layout-navigation">
        <slot name="header"></slot>
        <div class="fn-ui-layout-navigation__body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutNavigation"
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "error-screen__logo-container",
          style: { backgroundImage: "url(" + _vm.wave + ")" },
        },
        [
          _c("img", {
            staticClass: "error-screen__logo",
            attrs: { src: _vm.logo },
          }),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
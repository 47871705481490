<template>
    <fn-ui-screen>
        <template #header>
            <template v-if="!noHeader">
                <fn-ui-layout-column-header :compact="false" :icon="toggleIcon" @toggle-compact="toggleMenu">
                    <div class="font-10 text-primary font-weight-bold mb-1 pointer py-2" v-if="withBack && noToggle" @click="goBack">
                        <icon icon="long-arrow-left" type='solid'></icon>
                        <span class="ml-1 text-uppercase">{{ $t('back')}}</span>
                    </div>
                    <div>{{ title }}</div>
                </fn-ui-layout-column-header>
                <div class="btn btn-primary apply-btn pr-4 pl-3" v-if="withButton" @click="redirectToOkinusCreditOptions">{{ $t('Apply Now') }}</div>
                <div class="header-details" v-if="withDetails">
                    <div class="text-primary font-weight-bold">{{ details.store_name }}</div>
                    <div class="font-10 text-white">
                        <div>{{ details.contact }}</div>
                        <div class="text-uppercase w-100 text-wrap">{{ details.address }}</div>
                    </div>
                </div>
                <div class="tab-navbar-container" v-if="hasTabs">
                    <tab-nav-bar :tabs="tabs"></tab-nav-bar>
                </div>
                <div class="wave" :style="waveZIndex"></div>
            </template>
            <span v-else></span>
        </template>
        <template #default>
            <div class="screen-content" :class="{'clear-wave' : clearWave, 'p-0' : noPadding, 'has-tabs' : hasTabs, 'has-details' : withDetails}">
                <slot></slot>
            </div>
        </template>
    </fn-ui-screen>
</template>

<script>
import {FnUiScreen, FnUiLayoutColumnHeader} from "@fndry-vue/fn-ui";
import {fnLayoutMixin} from "@fndry-vue/fn-layout";
import TabNavBar from "./TabNavBar";

export default {
    name: "Screen",
    mixins: [fnLayoutMixin],
    props: {
        title: String,
        withButton: Boolean,
        waveTop: Boolean,
        noToggle: Boolean,
        clearWave: Boolean,
        noPadding: Boolean,
        withBack: Boolean,
        backRouteName: String,
        backRoute: Object,
        details: Object,
        noHeader: Boolean,
        tabs: Array,
    },
    components: {
        FnUiScreen,
        FnUiLayoutColumnHeader,
        TabNavBar
    },
    methods: {
        toggleMenu() {
            if(!this.noToggle) {
                this.toggleLayoutColumnShow('primaryMenu')
            }
        },
        goBack() {
            if(this.backRoute) {
                this.$router.push(this.backRoute)
            }
            else if(this.backRouteName) {
                this.$router.push({name:this.backRouteName})
            } else {
                this.$router.back();
            }
        },
        redirectToOkinusCreditOptions() {
            const url = 'https://okinus.com/credit-options/';
            window.open(url, '_blank');
        }
    },
    computed: {
        toggleIcon() {
            if(!this.noToggle) {
                return "bars";
            }
            return null;
        },
        clearWaveStyle() {
            let marginTop = 0;
            if(this.clearWave) {
                marginTop = '80px'
            }
            return {
                overflowY: 'auto',
                marginTop: marginTop
            }
        },
        waveZIndex() {
            let zIndex = 0;
            if(this.waveTop) {
                zIndex = 5;
            }
            return {
                zIndex: zIndex
            }
        },
        hasTabs() {
            return this.tabs && this.tabs.length > 0
        },
        withDetails() {
            return this.details;
        }
    }
}
</script>

<template>
    <div class="w-100 fn-types-multiselect">
        <div class="input-group">
            <vue-multiselect
                :id="id"
                :name="name"
                :multiple="multiple"
                :disabled="disabled"
                :placeholder="placeholder"
                :loading="loading"
                v-bind="defaultProps"
                :options="options_"
                v-model="selected"
                class="form-control p-0"
                @input="onInput"
                @search-change="onSearch"
            >
                <template v-if="!defaultProps.taggable || selected.length > 5" v-slot:selection="{isOpen}">
                    <span v-if="hasSelected">{{selected.length}} items selected</span>
                </template>
                <template v-slot:beforeList>
                    <li class="multiselect__element" v-if="selectDisplayedLabel && options_.length" @click.prevent="onSelectDisplayed">
                        <span class="multiselect__option">
                            {{selectDisplayedLabel}}
                        </span>
                    </li>
                    <li class="multiselect__element border-bottom" v-if="selectMatchedLabel && options_.length" @click.prevent="onSelectMatched">
                        <span class="multiselect__option">
                            {{selectMatchedLabel}}
                        </span>
                    </li>
                </template>
                <template v-slot:maxElements>
                    <span>{{ `Maximum of ${defaultProps.max} options selected.` }}</span>
                </template>
                <template v-slot:noResult>
                    <span>{{$t("No results found.")}}</span>
                </template>
                <template v-slot:noOptions>
                    <span>{{$t("No options available.")}}</span>
                </template>
                <template v-slot:afterList v-if="showAfterList">
                    <li class="multiselect__element border-top">
                        <span class="multiselect__option">
                            Showing {{defaultProps.optionsLimit}} of {{options_.length}}
                        </span>
                    </li>
                </template>
            </vue-multiselect>
            <div class="input-group-append" v-if="enableButton">
                <fn-ui-button
                    variant="primary"
                    :text="$t('Select')"
                    :disabled="!hasSelected || loading"
                    @click="onSelect"
                    class="border-0 font-12"
                />
            </div>
        </div>
        <div v-if="showSelectedList && selectedList.length" class="py-2 border-top border-bottom mt-3">
            <div class="row align-items-center" v-for="(item, index) in selectedList" :key="index">
                <div class="title col">
                    <p class="mb-0">{{item.text}}</p>
                </div>
                <div class="col-auto text-right">
                    <fn-ui-button
                        variant="plain"
                        text="secondary"
                        size="sm"
                        title="Delete"
                        @click="onRemove(item.value)"
                    >
                        <icon icon="times" type="light" class="m-0 text-secondary"></icon>
                    </fn-ui-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from "vue-multiselect";
    import "vue-multiselect/dist/vue-multiselect.min.css";
    import SelectInput from "./SelectInput";
    import action from "../mixins/action";
    import {debounce} from "lodash";

    export default {
        name: "MultiSelectInput",
        extends: SelectInput,
        mixins: [
            action,
        ],
        components: {
            VueMultiselect
        },
        props: {
            params: Object,
            selectDisplayedLabel: String,
            selectMatchedLabel: String,
            enableButton: Boolean,
            showSelectedList: Boolean,
        },
        data() {
            return {
                defaultProps: {
                    id: "", // Integer||String - Used to identify the component in events.
                    trackBy: "value", // String - Used to compare objects. Only use if options are objects.
                    label: "text", // String - Label from option Object, that will be visible in the dropdown.
                    searchable: false, // Boolean - Add / removes search input.
                    clearOnSelect: false, // Boolean - Clear the search input after select(). Use only when multiple is true.
                    hideSelected: false, // Boolean - Hide already selected options
                    allowEmpty: true, // Boolean - Allows to remove all selected values. Otherwise one must be left selected.
                    resetAfter: false, // Boolean - Reset this.value, this.search, this.selected after this.value changes.
                    closeOnSelect: false, // Boolean - Enable/disable closing after selecting an option
                    taggable: true, // Disable / Enable tagging
                    tagPlaceholder: "", // String - String to show when highlighting a potential tag
                    tagPosition: "top", // String - By default new tags will appear above the search results. Changing to 'bottom' will revert this behaviour and will proritize the search results
                    max: null, // Number - Number of allowed selected options.
                    optionsLimit: 10, // Number - Limits the options displayed in the dropdown to the first X options.
                    groupValues: "", // String - Name of the property containing the group values
                    groupLabel: "", // String - Name of the property containing the group label
                    groupSelect: false, // String - Allow to select all group values by selecting the group label
                    blockKeys: ["Tab", "Enter"], // Array - Array of keyboard key aliases to block when selecting
                    internalSearch: true, // Boolean - Decide whether to filter the results internally based on search query. Useful for async filtering, where we search through more complex data.
                    preserveSearch: true, // Boolean - If set to true, will preserve the search query when opening/closing the component.
                    preselectFirst: false, // Boolean - Selects the first option if initial value is empty
                    name: "", // String - Name attribute to match optional label element
                    selectLabel: "", // String - String to show when pointing to an option
                    selectGroupLabel: "Press enter to select group", // String - String to show when pointing to an option
                    selectedLabel: "", // String - String to show next to selected option
                    deselectLabel: "", // String - String to show when pointing to an already selected option
                    deselectGroupLabel: "Press enter to deselect group", // String - String to show when pointing to an already selected option
                    showLabels: true, // Boolean - Decide whether to show labels on highlighted options
                    limit: 99999, // Number - Limit the display of selected options. The rest will be hidden within the limitText string.
                    limitText: (count) => `and ${count} more`, // Function => String - Function that process the message shown when selected elements pass the defined limit.
                    disabled: false, // Boolean - Enable/disable the multiselect.
                    maxHeight: 300, // Integer - Sets max-height style value of the dropdown
                    openDirection: "", // String - Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom"
                    showNoResults: true, // Boolean - Show the noResult slot if no results are found.
                    tabindex: 0, // Integer - Specify the tabindex of the Multiselect component
                    showPointer: true, // Boolean - Enable/disable highlighting of the pointed value.
                    optionHeight: 40, // The height of the option element. Required for proper scrolling.
                },
                selected: [],
                selectedList: [],
            };
        },
        computed: {
            hasSelected() {
                return this.selected.length;
            },
            showAfterList() {
                return this.options_.length && (this.defaultProps.optionsLimit < this.options_.length);
            }
        },
        mounted() {
            if (this.data && this.data.multiselect) {
                this.defaultProps = {...this.defaultProps, ...this.data.multiselect};
            }

            // Pre-load options
            if (this.defaultProps.searchable) {
                this.getResults();
            }
        },
        watch: {
            options_: {
                immediate: true,
                handler() {
                    if (this.selected.length === 0) {
                        this.setSelected();
                    }
                },
            },
            params: {
                deep: true,
                handler() {
                    this.getResults();
                },
            },
        },
        methods: {
            isSelected(option) {
                return this.selected.includes(opt => option.value === opt.value);
            },
            setSelected() {
                this.model.forEach(value => {
                    const option = this.options_.find(option => option.value === value);

                    if (option) {
                        this.selected.push(option);
                    }
                });

                this.setSelectedList();
            },
            setSelectedList() {
                this.selectedList = [...this.selected];
            },
            getLabel(index) {
                return (this.selected[index]) ? this.selected[index].text : "";
            },
            onSelectDisplayed() {
                this.selected = [...this.options_.slice(0, this.defaultProps.optionsLimit)];
            },
            onSelectMatched() {
                this.selected = [...this.options_];
            },
            updateModel() {
                this.setSelectedList();

                this.model = this.selected.map(item => item.value);
            },
            onInput() {
                if (!this.enableButton) {
                    this.updateModel();
                }
            },
            onSelect() {
                if (this.enableButton) {
                    this.updateModel();
                }
            },
            onRemove(value) {
                this.selected = this.selected.filter(item => item.value !== value);

                this.onSelect();
            },
            onSearch: debounce(function(query) {
                    if (this.defaultProps.searchable) {
                        this.getResults(query);
                    }
                }, 1000
            ),
        },
    };
</script>

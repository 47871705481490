var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-carousel",
    {
      staticClass: "payment-select",
      attrs: { controls: "", interval: 0 },
      on: { "sliding-end": _vm.handleChangePayment },
    },
    [
      _vm._l(_vm.paymentMethods, function (paymentMethod, index) {
        return _c("b-carousel-slide", {
          key: index,
          scopedSlots: _vm._u(
            [
              {
                key: "img",
                fn: function () {
                  return [
                    paymentMethod.payment_type === "card" &&
                    _vm.isPaylianceCard(paymentMethod.provider)
                      ? _c("card-item", {
                          attrs: {
                            card: paymentMethod,
                            "is-default-payment": _vm.isDefault(
                              "card",
                              paymentMethod.id
                            ),
                            "is-payliance-card": _vm.isPaylianceCard(
                              paymentMethod.provider
                            ),
                            "no-actions": "",
                          },
                        })
                      : _c("bank-item", {
                          attrs: { bank: paymentMethod, "no-actions": "" },
                        }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        })
      }),
      _c("b-carousel-slide", {
        scopedSlots: _vm._u([
          {
            key: "img",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-item card shadow-sm d-flex flex-column justify-content-center align-items-center my-3",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("add-card")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-20 font-weight-medium" }, [
                      _vm._v(_vm._s(_vm.$t("Add New Card"))),
                    ]),
                    _c("icon", {
                      staticClass: "text-primary",
                      attrs: { icon: "plus-circle", size: "2x" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div style="display:contents" v-if="buttonOnly">
        <b-form-input
            v-mask="maskValue"
            v-model="textInput"
            :placeholder="format"
            :disabled="disabled"
            :readonly="readonly"
            :state="state"
            :autocomplete="autocomplete"
            lazy
        ></b-form-input>
        <div class="d-flex position-relative">
            <b-form-datepicker
                :id="id"
                :name="name"
                v-model="model"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                :required="required"
                :state="state"
                :size="size"
                :initial-date="defaultValue"
                :min="minDate"
                :max="maxDate"
                :show-decade-nav="showDecadeNav"
                :button-only="buttonOnly"
                :button-variant="buttonVariant"
            ></b-form-datepicker>
        </div>
    </div>
    <b-form-datepicker
        v-else
        :id="id"
        :name="name"
        v-model="model"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :state="state"
        :size="size"
        :initial-date="defaultValue"
        :min="minDate"
        :max="maxDate"
        :show-decade-nav="showDecadeNav"
    ></b-form-datepicker>
</template>

<script>
    import {BFormDatepicker} from 'bootstrap-vue';

    import TextInput from "./TextInput";
    import format from "../mixins/format";
    import input from "../mixins/input";
    import moment from "moment"

    export default {
        name: "DateInput",
        extends: TextInput,
        mixins: [
            input,
            format
        ],
        components: {
            BFormDatepicker
        },
        computed: {
            dateTime() {
                return this.data.datetime;
            },
            minDate() {
                return (this.dateTime && this.dateTime.minDate) ? this.dateTime.minDate : "";
            },
            maxDate() {
                return (this.dateTime && this.dateTime.maxDate) ? this.dateTime.maxDate : "";
            },
            showDecadeNav() {
                return this.dateTime?.showDecadeNav;
            },
            buttonOnly() {
                return this.dateTime?.buttonOnly
            },
            buttonVariant() {
                return this.dateTime?.buttonVariant
            },
            model: {
                get() {
                    return moment(this.value).format('YYYY-MM-DD')
                },
                set(value) {
                    let formattedValue = value;
                    if(this.format) {
                        let momentValue = moment(value, 'YYYY-MM-DD')
                        if(momentValue.isValid()) {
                            formattedValue = momentValue.format(this.format)
                        }
                    }
                    this.textInput = formattedValue;
                }
            },
            textInput: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col font-18 text-primary font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("Payment Details"))),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Payment Method"))),
      ]),
      _c("div", { staticClass: "col text-right" }, [
        _vm._v(_vm._s(_vm.payment.PaymentType)),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Payment Amount"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-currency", {
            attrs: { value: _vm.payment.TotalPymtLease },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Payment Date"))),
      ]),
      _c(
        "div",
        { staticClass: "col text-right" },
        [
          _c("fn-format-date", {
            attrs: {
              format: "MM/DD/YYYY",
              value: _vm.payment.dateofpaymt,
              "ignore-time-zone": true,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-3" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Payment ID"))),
      ]),
      _c("div", { staticClass: "col text-right" }, [
        _vm._v(_vm._s(_vm.payment.PaymentId)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fn-app" },
    [
      !_vm.loaded
        ? _c("loader", { attrs: { loading: true } })
        : _c("div", { staticClass: "fn-app__view" }, [_c("router-view")], 1),
      _c("fn-panels", {
        attrs: { panels: _vm.panels },
        on: { close: _vm.onPanelClose, cancel: _vm.onPanelsCancel },
      }),
      _c("fn-modals", { attrs: { modals: _vm.modals } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-form-textarea type="text"
                     :id="id"
                     :name="name"
                     :value="value"
                     :class="className"
                     :placeholder="placeholder"
                     :disabled="disabled"
                     :readonly="readonly"
                     :autocomplete="autocomplete"
                     :autofocus="autofocus"
                     :required="required"
                     :state="state"
                     :size="size"
                     :rows="multiline"
                     v-on="$listeners"
    ></b-form-textarea>
</template>

<script>

    import {BFormTextarea} from "bootstrap-vue";

    import TextInput from "./TextInput";

    export default {
        name: "TextAreaInput",
        extends: TextInput,
        props: {
            multiline: {
                type: Number,
                default: 5
            }
        },
        components: {
            BFormTextarea
        }
    };
</script>

<template>
    <validation-observer ref="observer" v-slot="{ invalid, validate, errors, valid }" tag="form" @submit.prevent="onSubmit" slim>
        <div class="filter">
            <div class="filter-body">
                <div class="row" v-for="row in layout">
                    <div class="col" v-for="name in row">
                        <fn-form-group v-if="filters && filters[name]" :model="form" :errors="errors" :schema="filters[name]" @input="onFilterInput" @change="onFilterChange"></fn-form-group>
                    </div>
                </div>
            </div>
            <div class="filter-buttons">
                <b-button variant="primary" type="submit" :disabled="invalid">Apply</b-button>&nbsp;
                <b-button variant="info" @click="$emit('reset')">Reset</b-button>
            </div>
        </div>
    </validation-observer>
</template>

<script>

    import {forEach} from "lodash";

    export default {
        name: "FilterForm",
        props: {
            layout: {
                type: Array,
                required: true
            },
            filters: {
                type: Object,
                required: false
            },
            params: {
                type: Object
            }
        },
        data() {
            let form = {};
            if (this.filters) {
                forEach(this.filters, (filter, name) => {
                    form[name] = (filter.value) ? filter.value : ((filter.default) ? filter.default : null);
                });
            }
            return {
                form: merge({}, form, this.params),
                fields: null
            };
        },
        methods: {
            onFilterInput(name, value){
                this.form = merge({}, this.form, {[name]: value});
            },
            onFilterChange(name, value){
                this.form = merge({}, this.form, {[name]: value});
            },
            onSubmit() {
                const isValid = this.$refs.observer.validate();
                if (!isValid) {
                    return;
                }
                this.$emit("filter", this.form);
            }
        },
        watch: {
            params: function(newValue, oldValue){
                let form = {};
                if (this.filters) {
                    forEach(this.filters, (filter, name) => {
                        form[name] = null;
                    });
                }
                this.form = merge({}, form, newValue);
            }
        }
    };
</script>

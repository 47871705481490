<template>
    <div class="fn-ui-profile-card card card-contrast">
        <div class="card-body">
            <div class="fn-ui-profile-card__header">
                <div class="card fn-ui-profile-card__image">
                    <slot name="image">
                        <fn-ui-avatar class="h-100" size="xl" type="image" :src="headerImageUrl"></fn-ui-avatar>
                    </slot>
                </div>

                <div class="fn-ui-profile-card__text-container">
                    <div class="header-text">
                        <slot name="header">
                            <span>{{ headerText }}</span>
                        </slot>
                    </div>

                    <div class="subheader-text">
                        <slot name="subheader">
                            <div>{{ subheaderText }}</div>
                        </slot>
                    </div>
                </div>
            </div>

            <div class="fn-ui-profile-card__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import {FnUiAvatar} from "@fndry-vue/fn-ui";

export default {
    name: "ProfileCard",
    props: {
        headerText: {
            type: String,
            default: ""
        },
        headerImageUrl: {
            type: String,
            default:""
        },
        subheaderText: String,
    },
    components: {
        FnUiAvatar,
    }
};
</script>

<template>
    <b-dropdown toggle-class="fn-ui-report-range-selector-dropdown" variant="link" :text="selected.text" size="sm">
        <b-dropdown-item v-for="type in types"
                         :key="type.value"
                         @click="() => handleChange(type.value)">{{ type.text }}</b-dropdown-item>
    </b-dropdown>
</template>

<script>
    import {find} from "lodash";

    export default {
        name: "ReportRangeSelectorDropDown",
        props: {
            value: String,
            types: {
                type: Array,
                required: true
            }
        },
        computed:{
            selected: function(){
                if (this.value) {
                    return find(this.types, (type) => type.value === this.value);
                }
                return this.types[0];
            }
        },
        methods: {
            handleChange(value) {
                this.$emit("change", value);
            }
        }
    };
</script>

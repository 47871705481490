<template>
    <b-tabs fill class="dashboard-tabs">
        <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab.name"
            @click="goToRoute(tab)"
            :active="isActive(tab.route_name)"
        ></b-tab>
    </b-tabs>
</template>

<script>

export default {
    name: "TabNavBar",
    props: {
        tabs: Array
    },
    data() {
        return {
            activeTab: 0,
        }
    },
    methods: {
        goToRoute(route) {
            if(route) {
                let routeObject = {
                    name : route.route_name,
                    params : route?.params
                }
                this.$router.push(routeObject)
            }
        },
        isActive(tab) {
            return this.$route.name === tab;
        }
    }
}
</script>

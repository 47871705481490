<template>
    <span :title="formatted">{{ displayDate }}</span>
</template>

<script>

    import moment from "moment";
    import {isObject, isNumber} from "lodash";

    export default {
        name: "FnFormatDate",
        props: {
            value: {
                type: [String, Object, Number],
                required: false,
            },
            format: {
                type: String,
                default() {
                    return "dddd, MMMM Do YYYY, h:mm:ss a";
                }
            },
            source: {
                type: String,
                default() {
                    return "YYYY-MM-DD HH:mm:ss.SSSSSS";
                }
            },
            timeago: Boolean,
            from: {
                type: [Boolean, String]
            },
            to: {
                type: [Boolean, String]
            },
            humanDiff: {
                type: [Boolean, String]
            },
        },
        computed: {
            displayDate: function(){
                let date = this.date;
                if (date) {
                    if (this.from || this.timeago) {
                        console.log(date);
                        return date.fromNow();
                    } else if (this.to) {
                        return date.toNow();
                    } else if(this.humanDiff) {
                        return this.calculateAge();
                    } else {
                        return this.formatted;
                    }
                }
                return null;
            },
            formatted: function() {
                return (this.date) ? this.date.format(this.format) : null;
            },
            date: function(){
                if (this.value) {
                    if (isNumber(this.value)) {
                        return moment.unix(this.value).utc().local();
                    } else {
                        return moment.utc((isObject && this.value.date) ? this.value.date : this.value, this.source).local();
                    }
                } else {
                    return null;
                }
            }
        },
        methods: {
            calculateAge() {
                var today = moment();
                var date = moment(this.value);

                var years = today.diff(date, 'year');
                date.add(years, 'years');
                var months = today.diff(date, 'months');
                date.add(months, 'months');

                return `${years} years and ${months} months`;
            }
        }
    };

</script>

<template>
  <fn-ui-card-layout body-class="p-0" class="finder-tab">
    <b-tabs fill @input="emitTab" class="search-tabs">
      <b-tab :title="$t('Location')">
        <div class="p-3">
          <loader :loading="loading">
            <div class="font-12" v-if="currentAddress">{{ $t('Your current location is') }}</div>
            <div class="font-12 text-primary">{{ this.currentAddress }}</div>
            <fn-type-select-input
                class="mt-3"
                v-model="selectedRadius"
                :empty="$t('Search Radius')"
                text-key="name"
                value-key="id"
                :options="radiusArray"
            ></fn-type-select-input>
          </loader>
        </div>
      </b-tab>
      <b-tab :title="$t('Full Address')">
        <div class="p-3">
          <b-form-input :placeholder="$t('Enter Street Address')" v-model="addressLookup"></b-form-input>
          <div class="row">
            <div class="col">
              <b-form-input :placeholder="$t('Enter State')" v-model="stateLookup"></b-form-input>
            </div>
            <div class="col">
              <b-form-input :placeholder="$t('Enter Zip')" v-model="zipLookup"></b-form-input>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('Zip Code')">
        <div class="p-3">
          <b-form-input :placeholder="$t('Enter Zip Code')" v-model="zipCode"></b-form-input>
        </div>
      </b-tab>
      <div class="pt-0 pr-3 pl-3 pb-3">
        <button class="pl-3 btn hover btn-outline-primary btn-alt" :disabled="isDisabled" @click="handleClick">{{ $t('Filter Results') }}</button>
        <button class="mt-2 btn btn-transparent btn-block btn-sm" @click="$emit('cancel')">{{ $t('Cancel') }}</button>
      </div>
    </b-tabs>
  </fn-ui-card-layout>
</template>

<script>
import {FnUiCardLayout} from "@fndry-vue/fn-ui";
import {FnTypeSelectInput} from "@fndry-vue/fn-types";

export default {
  name: "SearchTab",
  components: {
    FnUiCardLayout,
    FnTypeSelectInput
  },
  props: {
    coords : Object,
    currentAddress : String,
  },
  data() {
    return {
      activeTab : 0,
      tabArr: ['location', 'address', 'zip'],
      loading: false,
      selectedRadius: null,
      zipCode: null,
      zipCoords: null,
      addressLookup: null,
      stateLookup: null,
      zipLookup : null,
      radiusArray:[
        {
          id: "5",
          name: this.$t("Within 5 miles")
        },
        {
          id: "10",
          name: this.$t("Within 10 miles")
        },
        {
          id: "20",
          name: this.$t("Within 20 miles")
        },
        {
          id: "50",
          name: this.$t("Within 50 miles")
        },
        {
          id: "80",
          name: this.$t("Within 80 miles")
        },
        {
          id: "100",
          name: this.$t('Within 100 miles')
        },
        {
          id: "10000",
          name: this.$t("All")
        }
      ],
    }
  },
  computed: {
    isDisabled() {
      let tab = this.tabArr[this.activeTab];
      if(tab === 'location') {
        return !this.selectedRadius;
      } else if(tab === 'zip') {
        return !this.zipCode;
      }
    }
  },
  methods: {
    emitTab(tabIndex) {
      this.activeTab = tabIndex;
      this.$emit('tab-changed', this.tabArr[tabIndex]);
    },
    handleClick() {
      let tab = this.tabArr[this.activeTab];
      if(tab === 'zip') {
        this.getCoordsFromZip();
      } else {
        let address = [this.addressLookup, this.stateLookup, this.zipLookup]
        address = address.filter((n) => n)
        let fullAddress = address.join(', ')

        this.$emit('filter', {
          address: fullAddress,
          zip: this.zipCode,
          radius: this.selectedRadius
        })
      }
    },
    getCoordsFromZip() {
      this.loading = true;
      this.$fnApi
          .call(`/api/v1/mobile/address/zip`, "GET", {
            zip: this.zipCode,
          })
          .then(response => {
            if(response.data?.lat && response.data?.lng) {

              this.$emit('filter', {
                lat: response.data.lat,
                lng: response.data.lng
              })

            } else {
              this.$fnNotify({
                message: this.$t('Unable to locate zip code'),
                type: 'error'
              })
            }

          }).finally(() => {
        this.loading = false;
      })
    },
  },
}
</script>
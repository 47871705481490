<template>
    <div class="fn-types-rating-input">
        <star-rating
            v-for="(rating, index) in stars"
            :key="index"
            :id="`rating${rating}`"
            :name="name"
            :value="value"
            :input-value="rating"
            :disabled="disabled"
            v-on="$listeners"
        />
    </div>
</template>

<script>

    import NumberInput from "./NumberInput";
    import StarRating from "./Rating/StarRating";

    export default {
        name: "RatingInput",
        extends: NumberInput,
        components: {
            StarRating
        },
        computed: {
            stars: function(){
                return this.max ?? 5;
            }
        }
    }
</script>

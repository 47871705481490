<template>
    <div :class="{'fn-api-downloads': true}">
        <div :class="{'fn-api-download': true, 'active': show}">
            <div class="fn-api-download--title">
                Downloading "{{filename}}"
            </div>
            <b-progress class="fn-api-downloads--download"
                        :value="progress"
                        :max="100"
                        height="5px"
            ></b-progress>
        </div>
    </div>
</template>

<script>

import {BProgress} from "bootstrap-vue";

export default {
    name: "ApiDownloads",
    components: {
        BProgress
    },
    computed: {
        show: function(){
            return this.key !== null;
        },
        key: function(){
            return this.$store.state.fnApi.downloading.key;
        },
        progress: function(){
            return this.$store.state.fnApi.downloading.progress;
        },
        filename: function(){
            return this.$store.state.fnApi.downloading.filename;
        }
    }
};
</script>

<style lang="scss">
    .fn-api-downloads {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 10000;

        .fn-api-download {
            position: absolute;
            right: 35px;
            bottom: -200px;
            width: 300px;
            background: #fff;
            border-radius: 5px;
            box-shadow: 3px 3px 12px rgba(0,0,0,0.5);
            overflow: hidden;
            transition: .5s ease;

            &.active {
                bottom: 15px;
            }
        }

        .fn-api-download--title {
            padding: 15px
        }

        .progress-bar {
            &::after, &::before {
                border-radius: 0 !important;
            }

        }
    }
</style>

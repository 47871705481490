<template>
    <div :class="['fn-ui-report-menu-section', { 'selected' : selected }]">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuSection",
        props: {
            selected: {
                type : Boolean,
                default: null
            }
        }
    };
</script>

<template>
  <b-tabs fill @input="emitTab" class="finder-tab">
    <b-tab :title="$t('Filter')"></b-tab>
    <b-tab :title="$t('Search')"></b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: "FinderTabs",
  methods: {
    emitTab(tabIndex) {
      let tabArr = ['filter', 'search']
      this.$emit('tab-changed', tabArr[tabIndex]);
    }
  }
}
</script>
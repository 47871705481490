var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h4", { staticClass: "text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("OTP Verification"))),
      ]),
    ]),
    _c(
      "div",
      [
        _c("p", { staticClass: "lead text-center text-gray-600" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "Please check your mail for a 5 digit pin and enter it below to continue."
              )
            )
          ),
        ]),
        _c("fn-form", {
          staticClass: "mt-3",
          attrs: {
            uri: _vm.formUri,
            "uri-params": _vm.formUriParams,
            "uri-method": "POST",
            value: _vm.form,
          },
          on: { success: _vm.onSuccess },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function ({ submit, invalid, loading, submitting }) {
                return [
                  _c(
                    "div",
                    { staticClass: "form-group mt-4" },
                    [
                      _c(
                        "fn-ui-button",
                        {
                          staticClass: "btn-block btn-rounded",
                          attrs: {
                            variant: "primary",
                            disabled: invalid || submitting,
                            submitting: submitting,
                            type: "submit",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Confirm")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Didn't receive the One Time Pin?")) + " "
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "#", disabled: _vm.resendingPin },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.resendPin.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(_vm.$t("Resend")) + " "),
                          _vm.resendingPin
                            ? _c("b-spinner", {
                                staticClass: "ml-1",
                                attrs: {
                                  small: "",
                                  variant: "primary",
                                  label: _vm.$t("Loading"),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-form-select
        :id="id"
        :name="name"
        v-model="model"
        :disabled="disabled"
        :readonly="readonly"
        :state="state"
        :size="size"
        :required="required"
        :options="countryOptions"
        v-on="$listeners"
    ></b-form-select>
</template>

<script>
    import {find, isEmpty} from 'lodash';
    import {BFormSelect} from 'bootstrap-vue';

    import input from "../mixins/input";
    import location from "../mixins/location";
    import options from "../mixins/options";

    export default {
        name: "CountryInput",
        mixins: [
            input,
            location,
            options
        ],
        components: {
            BFormSelect
        },
        data() {
            return {
                geoValue: null
            };
        },
        computed: {
            model: {
                get: function(){
                    if (!this.value) {
                        if(this.geoValue) {
                            return this.getCountryCodeToId(this.geoValue)
                        }
                        else {
                            return null;
                        }
                    }

                    return this.value;
                },
                set: function(value){
                    this.emitSelected(value);
                }
            },
            countryOptions() {
                let options = [];

                if(isEmpty(this.value)) {
                    options.push({
                        value: null, text: "Please select a country", disabled: true
                    });
                }

                this.options.forEach((country) => {
                    options.push({
                        value: country.id, text: country.label
                    });
                });

                return options;
            }
        },
        mounted() {
            if(isEmpty(this.value)) { //if a value is already set, no need to check actual user location
                this.getUserLocation();
            }
            else {
                this.emitSelected(this.value);
            }
        },
        methods: {
            getUserLocation() {
                if(typeof navigator.geolocation !== "undefined") { //check if browser supports geolocation api
                    navigator.geolocation.getCurrentPosition(this.success);
                }
            },
            async success(position) {
                this.geoValue = await this.getCountryCode(position.coords.latitude, position.coords.longitude)
            },
            getCountryCodeToId(value) {
                if(this.options && this.options.length > 0) {
                    let item =  find(this.options, function (item) {
                       return value == item.identifier;
                    });

                   return item?.id;
                }
            },

            getIdToCountryCode(value) {
                if(this.options && this.options.length > 0) {
                    let item =  find(this.options, function (item) {
                        return value == item.id;
                    });

                    return item?.identifier;
                }
            },

            emitSelected(value) {
                let countryCode = this.getIdToCountryCode(value);
                this.$emit("input", value, countryCode);
            }
        }
    };
</script>

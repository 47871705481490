<template>
    <div class="fn-ui-report-menu-history-item">
        <span>
            <b-form-radio class="mr-2" type="checkbox" v-model="selected_" size="sm" :value="value">
                <span class="fn-ui-report-menu-history-item__title">{{ title }}</span>
            </b-form-radio>
        </span>
        <b-dropdown variant="link" toggle-class="text-decoration-none text-secondary px-0" right
                    class="dropdown-container flex-grow-0" no-caret v-if="hasDropdown">
            <template #button-content>
                <icon icon="ellipsis-v" type="solid" size="sm"></icon>
            </template>
            <slot name="dropdown">
            </slot>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuHistoryItem",
        props: {
            value: [String,Number],
            title: String,
            checked: [String,Number]
        },
        computed: {
            hasDropdown() {
                return !!this.$slots.dropdown;
            },
            selected_: {
                get() {
                    return this.checked;
                },
                set(checked) {
                    this.$emit("input", checked);
                }
            }
        }
    };
</script>

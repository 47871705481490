<template>
    <div :class="['fn-ui-report-column']">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ReportColumn",
        props: {
            hover: Boolean,
            selected: Boolean,
        }
    }
</script>

<template>
    <screen class="customer-panel" :title="$t('Add New Credit Card')" :with-button="false" no-toggle clear-wave>
        <add-card-screen is-panel @success="handleSuccess" @cancel="handleCancel" :ssn="ssn"></add-card-screen>
    </screen>
</template>

<script>
import {FnUiPanel} from "@fndry-vue/fn-ui";
import Screen from "@theme/ok-customer/components/Screen";
import AddCardScreen from "../../profile/screens/AddCardScreen";

export default {
    name: "AddNewCardPanel",
    components: {
        FnUiPanel,
        Screen,
        AddCardScreen,
    },
    props: {
        ssn: {
            type: String,
            default: null
        }
    },
    methods: {
        handleSuccess() {
            this.$emit('success')
            this.$emit('close')
        },
        handleCancel() {
            this.$emit('close')
        }
    }
}
</script>

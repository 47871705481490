var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loggedOut
      ? _c("div", [
          _c("div", [
            _c("h4", { staticClass: "text-center text-gray-600" }, [
              _vm._v(_vm._s(_vm.$t("One moment please..."))),
            ]),
          ]),
          _c("div", [_c("loader", { attrs: { loading: true } })], 1),
        ])
      : _c("div", [
          _c("div", [
            _c("h4", { staticClass: "text-center text-gray-600" }, [
              _vm._v(_vm._s(_vm.$t("You are now logged out"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary btn-block",
                  attrs: { to: _vm.loginSystem },
                },
                [_vm._v(_vm._s(_vm.$t("Login")) + " ")]
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-form-input type="hidden"
                  :id="id"
                  :name="name"
                  :value="value"
                  :disabled="disabled"
                  :readonly="readonly"
                  :required="required"
                  v-on="$listeners"
    ></b-form-input>
</template>

<script>

    import {BFormInput} from 'bootstrap-vue';

    import TextInput from "./TextInput";

    export default {
        name: "HiddenInput",
        extends: TextInput,
        components: {
            BFormInput
        }
    };
</script>

<template>
    <div class="card fn-ui-nav-card">
        <div class="card-body">
            <div class="nav-card__header">
                {{headerText}}
            </div>

            <div class="nav-card__list">
                <slot>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavCard",
    props: {
        headerText: {
            type: String,
            default: "Navigation Cards"
        }
    }
};
</script>

<template>
    <div role="group" aria-label="Collection">
        <b-button
            v-if="canMoveUp"
            @click="() => $emit('move-up')"
            variant="link"
            size="sm">
            <icon icon="chevron-up"/>
        </b-button>
        <b-button
            v-if="canMoveDown"
            @click="() => $emit('move-down')"
            variant="link"
            size="sm">
            <icon icon="chevron-down"/>
        </b-button>
        <b-button
            v-if="canRemove"
            class="collection-remove"
            size="sm"
            variant="link"
            @click="() => $emit('remove')">
            <icon icon="trash"/>
        </b-button>
    </div>
</template>
<script>
export default {
    name: 'CollectionButtons',
    props: {
        canMoveUp: Boolean,
        canMoveDown: Boolean,
        canRemove: Boolean
    }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h4", { staticClass: "text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("Reset Password"))),
      ]),
    ]),
    _c("div", [
      _c("p", { staticClass: "lead text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("Enter your new password below."))),
      ]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "fn-form-schema",
            {
              ref: "formSchema",
              attrs: { value: _vm.model, "no-inject": "" },
              on: { input: _vm.onUpdate, validated: _vm.onValidated },
            },
            [
              _c("fn-form-group", { attrs: { schema: _vm.schema.password } }),
              _c("fn-form-group", {
                attrs: { schema: _vm.schema.password_confirmation },
              }),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "fn-ui-button",
                    {
                      staticClass: "btn-block btn-rounded",
                      attrs: {
                        variant: "primary",
                        disabled:
                          _vm.flags.invalid || _vm.loading || _vm.submitting,
                        submitting: _vm.submitting,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Reset Password")))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "mt-4" }, [
                _c(
                  "p",
                  { staticClass: "text-center small" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("Need to resend instructions?")) + " "
                    ),
                    _c("router-link", { attrs: { to: _vm.forgotRoute } }, [
                      _vm._v(_vm._s(_vm.$t("Restart"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "text-center small mb-0" },
                  [
                    _vm._v(_vm._s(_vm.$t("Remembered your password?")) + " "),
                    _c("router-link", { attrs: { to: _vm.loginRoute } }, [
                      _vm._v(_vm._s(_vm.$t("Log In"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <table role="table" aria-busy="false" class="table b-table table-striped tree-grid-table">
        <thead role="rowgroup">
            <tr role="row">
                <th
                    v-for="(field, key) in fields"
                    :class="[ field['thClass'] ? field['thClass'] : null, 'b-table-sort-icon-left' ]"
                    role="columnheader"
                    scope="col"
                    :aria-colindex="key"
                    :aria-sort="field.sortable ? getAriaSort(field.key) : undefined"
                    :key="key"
                    @click="field.sortable ? handleSortBy(field.key) : undefined"
                >
                    {{ field.label }}
                </th>
            </tr>
        </thead>
        <tbody role="rowgroup">
            <template v-for="(item, index) in data">
                <tree-grid-table-row
                    :item="item"
                    :depth="item.depth"
                    :key="index"
                >
                    <template v-slot:columns="{item, depth, hasChildren, toggleChildRows, depthPadding}">
                        <slot name="columns" v-bind="{item, depth, hasChildren, toggleChildRows, depthPadding}"></slot>
                    </template>
                </tree-grid-table-row>
            </template>
        </tbody>
    </table>
</template>

<script>
    import TreeGridTableRow from "./TreeGridTableRow";

    export default {
        name: "TreeGridTable",
        components: {
            TreeGridTableRow
        },
        props: {
            fields: {
                type: Array,
                required: true,
                default() {
                    return [];
                }
            },
            data: {
                type: Array,
                required: true,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                sortKey: null,
                sortDesc: false,
            };
        },
        methods: {
            handleSortBy(sortBy) {
                if (sortBy === this.sortKey) {
                    this.sortDesc = !this.sortDesc;
                } else {
                    this.sortDesc = false;
                }

                this.sortKey = sortBy;

                this.$emit("sort-changed", {sortBy: this.sortKey, sortDesc: this.sortDesc});
            },
            getAriaSort(key) {
                if (key === this.sortKey) {
                    return this.sortDesc ? "descending" : "ascending";
                }

                return "none";
            },
        },
    }
</script>

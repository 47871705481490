<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t("Reset Password")}}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{$t("Enter your new password below.")}}</p>
            <fn-form class="mt-3" :uri="formUri" :uri-params="formUriParams" uri-method="POST" :value="form" @success="onSuccess" @fail="onFail">
                <template #buttons="{submit, invalid, flags, loading, submitting}">
                    <div class="form-group">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="!flags.valid || loading || submitting" :submitting="submitting" type="submit">{{$t("Reset Password")}}</fn-ui-button>
                    </div>
                    <div class="mt-4">
                        <p class="text-center small">{{$t("Need to resend instructions?")}} <router-link :to="forgotRoute">{{$t("Restart")}}</router-link></p>
                        <p class="text-center small mb-0">{{$t("Remembered your password?")}} <router-link  :to="loginRoute">{{$t("Log In")}}</router-link></p>
                    </div>
                </template>
            </fn-form>
        </div>
    </div>
</template>

<script>
    import {AUTH_FORGOT, AUTH_LOGIN} from "./index";
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

    export default {
        name: "AuthReset",
        mixins: [
            withAutoGuard,
        ],
        props:{
            token: {
                type: String,
                required: true
            },
            email: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                form: {
                    email: this.email,
                    password: "",
                    password_confirmation: "",
                    confirm: null,
                    token: this.token
                }
            };
        },
        computed: {
            formUri() {
                return "/api/v2/auth/reset";
            },
            formUriParams() {
                return this.getGuardUriParams();
            },
            forgotRoute() {
                return this.getGuardRoute(AUTH_FORGOT);
            },
            loginRoute() {
                return {
                    name: AUTH_LOGIN
                };
            }
        },
        methods: {
            onSuccess(){
                this.$fnNotify(this.$t("Password reset!"));
                this.$router.push(this.loginRoute);
            },
            onFail(response){
                if (response.code === 422) {
                    this.$refs.observer.setErrors(response.data);
                }
            }
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-items-stretch" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col d-flex flex-column align-items-center" },
          [
            _c("fn-ui-avatar", {
              staticClass: "h-100",
              attrs: { size: "xl", type: "image", src: _vm.avatar },
            }),
            _c("h4", [_vm._v(_vm._s(_vm.displayName))]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row mt-3" }, [
        _c("div", { staticClass: "col" }, [
          _vm._v(_vm._s(_vm.$t("Mobile Number"))),
        ]),
        _c("div", { staticClass: "col text-right" }, [
          _vm._v(_vm._s(_vm.user.cell_phone ?? "N/A")),
        ]),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col" }, [_vm._v(_vm._s(_vm.$t("Username")))]),
        _c("div", { staticClass: "col text-right" }, [
          _vm._v(_vm._s(_vm.user.email)),
        ]),
      ]),
      _c(
        "loader",
        { attrs: { loading: _vm.loading } },
        [
          _c("fn-form", {
            staticClass: "mt-4",
            attrs: { "no-autoload": "", "no-buttons": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ loading, loaded, submit, flags }) {
                  return [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _vm._v(_vm._s(_vm.$t("General Notifications"))),
                      ]),
                      _c("div", { staticClass: "col-8 font-10 mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "These are general notifications regarding system payments etc"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-2 offset-2 mt-1" },
                        [
                          _c("fn-form-group", {
                            attrs: { schema: _vm.inputs.notifications },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _vm._v(_vm._s(_vm.$t("Marketing Notifications"))),
                      ]),
                      _c("div", { staticClass: "col-8 font-10 mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "These are general notifications regarding system payments etc"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-2 offset-2 mt-1" },
                        [
                          _c("fn-form-group", {
                            attrs: { schema: _vm.inputs.solicitations },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          {
            staticClass: "col",
            on: {
              click: function ($event) {
                return _vm.retrieveLeases()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
              },
              [
                _c("span", { staticClass: "font-14" }, [
                  _vm._v(_vm._s(_vm.$t("Retrieve Past Leases"))),
                ]),
                _c("icon", {
                  staticClass: "ml-auto",
                  attrs: { icon: "long-arrow-right", size: "xl" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          {
            staticClass: "col",
            on: {
              click: function ($event) {
                return _vm.changeEmailAddress()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
              },
              [
                _c("span", { staticClass: "font-14" }, [
                  _vm._v(_vm._s(_vm.$t("Change Email Address"))),
                ]),
                _c("icon", {
                  staticClass: "ml-auto",
                  attrs: { icon: "long-arrow-right", size: "xl" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          {
            staticClass: "col",
            on: {
              click: function ($event) {
                return _vm.changePassword()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
              },
              [
                _c("span", { staticClass: "font-14" }, [
                  _vm._v(_vm._s(_vm.$t("Change Password"))),
                ]),
                _c("icon", {
                  staticClass: "ml-auto",
                  attrs: { icon: "long-arrow-right", size: "xl" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "row mt-3" }, [
        _c(
          "div",
          {
            staticClass: "col",
            on: {
              click: function ($event) {
                return _vm.logout()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
              },
              [
                _c("span", { staticClass: "font-14" }, [
                  _vm._v(_vm._s(_vm.$t("Logout"))),
                ]),
                _c("icon", {
                  staticClass: "ml-auto",
                  attrs: { icon: "long-arrow-right", size: "xl" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
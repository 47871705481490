<template>
    <font-awesome-icon :icon="classes" :size="size" class="icon" :flip="flip" :transform="transform"/>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {defaultFontType} from "../../index";

export default {
    name: "Icon",
    props: {
        /**
         * The icon class or name to use
         *
         * @type {String}
         */
        icon: {
            type: String
        },
        /**
         * Set the size of the icon
         *
         * @type {String} sm, lg, xl, or leave blank for default size
         */
        size: {
            type: String,
            default: null
        },
        /**
         * The type of icon to display
         *
         * @type {String} These are the style types which can be solid, regular, light, duotone
         */
        type: {
            type: String,
            default() {
                return defaultFontType;
            }
        },
        /**
         * The flip orientation of the icon
         *
         * @type {String}
         */
        flip: {
            type: String,
            default: null
        },

        /**
         * Add fontawesome transform classes
         *
         * @type {String}
         */
        transform: {
            type: String,
            default: ''
        }
    },
    components: {
        FontAwesomeIcon
    },
    computed: {
        classes: function () {
            let classes = [];
            switch (this.type) {
                case "brand":
                    classes.push("fab");
                    break;
                case "solid":
                    classes.push("fas");
                    break;
                case "regular":
                    classes.push("far");
                    break;
                case "light":
                    classes.push("fal");
                    break;
                case "duo":
                    classes.push("fad");
                    break;
                default:
                    classes.push("fas");
                    break;
            }
            classes.push(this.icon);
            return classes;
        }
    }

};
</script>

<style scoped>

</style>

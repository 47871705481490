<template>
    <div class="fn-types-treeselect">
        <treeselect
            :id="id"
            :name="name"
            :multiple="multiple"
            :disabled="disabled"
            :placeholder="placeholder"
            :loading="loading"
            v-bind="defaultProps"
            :options="options_"
            v-model="model"
            @focus="$emit('focus')"
            class="form-control p-0 h-auto"
        />
    </div>
</template>

<script>
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import SelectInput from "./SelectInput";
    import action from "../mixins/action";
    import {forEach} from "lodash";

    export default {
        name: "TreeSelectInput",
        extends: SelectInput,
        mixins: [
            action,
        ],
        components: {
            Treeselect
        },
        data() {
            return {
                defaultProps: {
                    allowClearingDisabled: false, // Whether to allow resetting value even if there are disabled selected nodes.
                    allowSelectingDisabledDescendants: false, // When an ancestor node is selected/deselected, whether its disabled descendants should be selected/deselected. You may want to use this in conjunction with allowClearingDisabled prop.
                    alwaysOpen: false, // Whether the menu should be always open.
                    appendToBody: false, // Append the menu to <body />.
                    async: false, // Whether to enable async search mode.
                    autoFocus: false, // Automatically focus the component on mount.
                    autoLoadRootOptions: true, // Automatically load root options on mount. When set to false, root options will be loaded when the menu is opened.
                    autoDeselectAncestors: false, // When user deselects a node, automatically deselect its ancestors. Applies to flat mode only.
                    autoDeselectDescendants: false, // When user deselects a node, automatically deselect its descendants. Applies to flat mode only.
                    autoSelectAncestors: false, // When user selects a node, automatically select its ancestors. Applies to flat mode only.
                    autoSelectDescendants: false, // When user selects a node, automatically select its descendants. Applies to flat mode only.
                    backspaceRemoves: true, // Whether Backspace removes the last item if there is no text input.
                    beforeClearAll: () => true, // Function that processes before clearing all input fields. Return false to stop values being cleared.
                    branchNodesFirst: false, // Show branch nodes before leaf nodes.
                    cacheOptions: true, // Whether to cache results of each search request for async search mode.
                    clearable: true, // Whether to show an "×" button that resets value.
                    clearAllText: "Clear all", // Title for the "×" button when :multiple="true".
                    clearOnSelect: false, // Whether to clear the search input after selecting an option. Use only when :multiple="true". For single-select mode, it always clears the input after selecting regardless of the prop value.
                    clearValueText: "Clear value", // Title for the "×" button.
                    closeOnSelect: true, // Whether to close the menu after selecting an option. Use only when :multiple="true".
                    defaultExpandLevel: 0, // How many levels of branch nodes should be automatically expanded when loaded. Set Infinity to make all branch nodes expanded by default.
                    defaultOptions: false, // The default set of options to show before the user starts searching. Used for async search mode. When set to true, the results for search query as a empty string will be autoloaded.
                    deleteRemoves: true, // Whether Delete removes the last item if there is no text input.
                    delimiter: ",", // Delimiter to use to join multiple values for the hidden field value.
                    flattenSearchResults: false, // Whether to flatten the tree when searching (sync search mode only). See here for example.
                    disableBranchNodes: false, // Whether to prevent branch nodes from being selected. See here for example.
                    disabled: false, // Whether to disable the control or not.
                    disableFuzzyMatching: false, // Set to true to disable the fuzzy matching functionality, which is enabled by default.
                    flat: false, // Whether to enable flat mode or not. See here for detailed information.
                    instanceId: "<auto-incrementing number>$$", // Will be passed with all events as the last param. Useful for identifying events origin.
                    joinValues: false, // Joins multiple values into a single form field with the delimiter (legacy mode).
                    limit: Infinity, // Limit the display of selected options. The rest will be hidden within the limitText string.
                    limitText: count => `and ${count} more`, // Function that processes the message shown when selected elements pass the defined limit.
                    loadingText: "Loading...", // Text displayed when loading options.
                    loadOptions: () => {}, // Used for dynamically loading options. See here for detailed information. Possible values of action: "LOAD_ROOT_OPTIONS", "LOAD_CHILDREN_OPTIONS" or "ASYNC_SEARCH". callback - a function that accepts an optional error argument parentNode - only presents when loading children options searchQuery - only presents when searching async options instanceId - eqauls to the value of instanceId prop you passed to vue-treeselect
                    matchKeys: [ "label" ], // Which keys of a node object to filter on.
                    maxHeight: 300, // Sets maxHeight style value of the menu.
                    noChildrenText: "No sub-options.", // Text displayed when a branch node has no children.
                    noOptionsText: "No options available.", /// Text displayed when there are no available options.
                    noResultsText: "No results found...", // Text displayed when there are no matching search results.
                    normalizer: node => node, // Used for normalizing source data. See here for detailed information.
                    openDirection: "auto", // By default ("auto"), the menu will open below the control. If there is not enough space, vue-treeselect will automatically flip the menu. You can use one of other four options to force the menu to be always opened to specified direction. Acceptable values: "auto", "below", "bottom", "above" or "top".
                    openOnClick: true, // Whether to automatically open the menu when the control is clicked.
                    openOnFocus: false, // Whether to automatically open the menu when the control is focused
                    retryText: "Retry?", // Text displayed asking user whether to retry loading children options.
                    retryTitle: "Click to retry", // Title for the retry button.
                    searchable: true, // Whether to enable searching feature or not.
                    searchNested: false, // Set true if the search query should search in all ancestor nodes too. See here for example.
                    searchPromptText: "Type to search...", // Text tip to prompt for async search. Used for async search mode.
                    showCount: false, // Whether to show a children count next to the label of each branch node. See here for example.
                    showCountOf: "ALL_CHILDREN", // Used in conjunction with showCount to specify which type of count number should be displayed. Acceptable values: "ALL_CHILDREN", "ALL_DESCENDANTS", "LEAF_CHILDREN" or "LEAF_DESCENDANTS".
                    showCountOnSearch: false, // Whether to show children count when searching. Fallbacks to the value of showCount when not specified.
                    sortValueBy: "ORDER_SELECTED", // In which order the selected options should be displayed in trigger & sorted in value array. Use only when :multiple="true". See here for example. Acceptable values: "ORDER_SELECTED", "LEVEL" or "INDEX".
                    tabIndex: 0, // Tab index of the control.
                    value: null, // The value of the control. Should be id or node object when :multiple="false", or an array of id or node object when :multiple="true". Its format depends on the valueFormat prop. For most cases, just use v-model instead.
                    valueConsistsOf: "BRANCH_PRIORITY", // Which kind of nodes should be included in the value array in multi-select mode. See here for example. Acceptable values: "ALL", "BRANCH_PRIORITY", "LEAF_PRIORITY" or "ALL_WITH_INDETERMINATE".
                    valueFormat: "id", // Format of value prop. Note that, when set to "object", only id & label properties are required in each node object in value. Acceptable values: "id" or "object".
                    zIndex: 999 //z-index of the menu.
                }
            };
        },
        mounted() {
            if (this.data && this.data.treeselect) {
                this.defaultProps = {...this.defaultProps, ...this.data.treeselect};
            }
        },
        methods: {
            setOptions(){
                this.options_ = [];
                forEach(this.options, (option) => {
                    this.options_.push(this.getOption(option));
                });
                this.key++;
            },
            getOption(option) {
                let _option = this.extractItem(option);

                if (option.children && option.children.length) {
                    const children = [];

                    forEach(option.children, (child) => {
                        children.push(this.getOption(child));
                    });

                    _option.children = children;
                }

                return _option;
            },
            extractItem(item){
                return {
                    label: this.extractItemText(item, this.textKey),
                    id: item[this.valueKey],
                    isDisabled: item.disabled,
                };
            },
        }
    };
</script>

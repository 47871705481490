<template>
    <div class="fn-ui-staged-progress-bar">

        <div class="fn-ui-staged-progress-bar__action-points">
            <template v-for="(action, index) in actions">
                <div :class="{'action-item': true, 'current-action' : isCurrentAction(action)}"
                     :key="index"
                     :style="getActionItemStyle(action)"
                >
                    <div class="action-item__icon">
                        <icon
                            :icon="getActionIconName(action)"
                            :class="getActionIconClass(action)"
                            size="2x">
                        </icon>
                    </div>
                    <div class="action-item__text">{{ action.text }}</div>
                </div>
            </template>
        </div>

        <b-progress  :max="100" class="fn-ui-staged-progress-bar__action-bar">
            <b-progress-bar :value="completedActionPercent"></b-progress-bar>
        </b-progress>

        <div class="fn-ui-staged-progress-bar__step-points">
            <template v-for="(step, index) in steps">
                <div class="step-item" :key="index" :style="{'width': getStepActionPercentage(step, index)+'%'}">
                    <div class="step-item__title">{{ step.text }}</div>
                    <div :class="['step-item__complete', getStepCompletedClass(step)]">{{ getStepCompletedText(step) }}</div>
                </div>
            </template>
        </div>

        <b-progress  :max="100" class="fn-ui-staged-progress-bar__step-bar">
            <b-progress-bar :value="completedActionPercent"></b-progress-bar>
        </b-progress>

    </div>
</template>

<script>
import {findIndex} from "lodash";

export default {
    name: "StagedProgressBar",
    props: {
        steps: {
            type: Array,
            required: true
        },
        currentAction: {
            type: String,
            required: true
        }
    },
    computed: {
        actions() {
            let actionArray = [];
            this.steps.forEach((item) => {
                actionArray = [...actionArray, ...item.actions];
            });

            return actionArray;
        },
        completedStepPercent() {
            let stepCount = this.steps.length;
            let stepsCompleted = 0;

            if(stepCount === 0) {
                return 0;
            }

            for(let i = 0; i < this.steps.length; i++ ) {
                if(this.isStepCompleted(this.steps[i])) {
                    stepsCompleted++;
                } else {
                    break;
                }
            }

            return (stepsCompleted/stepCount) * 100;
        },
        completedActionPercent() {
            let actionCount = this.actions.length;
            let actionsCompleted = 0;

            if(actionCount === 0) {
                return 0;
            }

            for(let i = 0; i < this.actions.length; i++ ) {
                if(this.actions[i].completed) {
                    actionsCompleted++;
                } else {
                    break;
                }
            }

            return ((actionsCompleted - 1)/(actionCount - 1)) * 100;
        }
    },
    methods: {
        isCurrentAction(action) {
            return action.text === this.currentAction;
        },
        isStepCompleted(step) {
            if(step.actions.length === 0) {
                return false;
            }

            return step.actions.every((action) => {
                return action.completed;
            });
        },
        getStepActionPercentage(step, index) {
            let actionLength = step.actions?.length;
            if(index === 0 && actionLength > 0) {
                actionLength--;
            }
            return (actionLength / (this.actions.length - 1)) * 100;
        },
        getActionItemStyle(action) {
            let index = findIndex(this.actions, action);

            return {
                "left" : "calc("+index * (100/(this.actions.length-1)) + "% - 18px)",
            };
        },
        getActionIconName(action) {
            if(this.isCurrentAction(action)) {
                return "circle";
            }
            else if(action.completed) {
                return "check-circle";
            }
            else {
                return "times-circle";
            }
        },
        getActionIconClass(action) {
            if(this.isCurrentAction(action)) {
                return "text-secondary";
            }
            else if(action.completed) {
                return "text-primary";
            }
            else {
                return "text-light";
            }
        },
        getStepCompletedText(step) {
            return this.isStepCompleted(step) ? "complete" : "incomplete";
        },
        getStepCompletedClass(step) {
            return this.isStepCompleted(step) ? "text-primary" : "text-dark";
        }
    }
};
</script>

import HtmlInput from "./inputs/MediumHtmlInput";

export {
    HtmlInput as FnTypeMediumEditor
};

/**
 * FnTypesMediumEditorPlugin
 * (c) 2020
 */
const FnTypesMediumEditorPlugin = {};

/**
 * FnTypesMediumEditorPlugin Plugin install
 *
 * @param {Object} Vue The vue instance
 * @param {function} extend The extend method provided by vee-validate
 */
FnTypesMediumEditorPlugin.install = function (Vue) {

};

export default FnTypesMediumEditorPlugin;

<template>
    <div class="fn-ui-breakpoint" style="width: 100%;"></div>
</template>

<script>

export default {
    name: "Breakpoint",
    props: {
        delay: {
            type: Number,
            default: 500
        },
        breakpoints: {
            type: Array,
            default(){
                return [
                    {
                        size: "xs",
                        width: 0,
                    },
                    {
                        size: "sm",
                        width: 576,
                    },
                    {
                        size: "md",
                        width: 768,
                    },
                    {
                        size: "lg",
                        width: 992,
                    },
                    {
                        size: "xl",
                        width: 1200,
                    },
                    {
                        size: "xxl",
                        width: 1400,
                    },
                ];
            }
        }
    },
    data() {
        return {
            width: 0,
            size: null,
            timeout: undefined
        };
    },
    created(){
        window.addEventListener("resize", this.handleResize);
    },
    mounted() {
        this.width = this.$el.offsetWidth;
        this.emitResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        emitResize(){
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                let size;
                for (let b in this.breakpoints) {
                    if (this.width > this.breakpoints[b].width) {
                        size = this.breakpoints[b].size;
                    } else if (this.width < this.breakpoints[b].width) {
                        break;
                    }
                }
                if (this.size !== size) {
                    this.size = size;
                    this.$emit("change", this.size, this.width);
                }
                clearTimeout(this.timeout);
            }, this.delay);
        },
        handleResize(){
            this.width = this.$el.offsetWidth;
            this.emitResize();
        }
    }
};
</script>

<template>
    <div class="fn-ui-nav-item" :class="{'selected': selected}" @click.prevent="$emit('select')">
        <div class="fn-ui-nav-item__image-container">
            <fn-ui-avatar size="sm" type="image" :src="imageUrl"></fn-ui-avatar>
        </div>
        <div class="fn-ui-nav-item__info-container">
            <div class="header-text">{{title}}</div>
            <div>
                <span class="badge badge-pill badge-secondary mr-2">{{ primaryBadge }}</span>
                <span class="badge badge-pill badge-primary text-white">{{ secondaryBadge }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {FnUiAvatar} from "@fndry-vue/fn-ui";

export default {
    name: "NavItem",
    components: {
        FnUiAvatar
    },
    props: {
        imageUrl: String,
        title: String,
        primaryBadge: String,
        secondaryBadge: String,
        selected: {
            type: Boolean,
            default: false
        }
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "loader",
    { attrs: { loading: _vm.loadingLeases || _vm.loadingHistory } },
    [
      _vm.paymentHistory.length > 0
        ? _c(
            "div",
            _vm._l(_vm.paymentHistory, function (payment, index) {
              return _c("payment-history-item", {
                key: index,
                attrs: {
                  payment: payment,
                  lease: _vm.getLeaseFromLoan(payment["Loan Number"]),
                },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
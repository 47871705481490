<template>
    <div class="fn-ui-layout fn-ui-layout--login d-block d-lg-flex justify-content-center align-items-center"
         :style="bgStyle">
        <div class="row fn-ui-layout--login__card">

            <div class="col bg-gray-100 d-none d-lg-flex justify-content-center align-items-center fn-ui-layout--login__w-lg">
                <img :src="logo" :alt="alt" class="logo w-50"/>
            </div>

            <div class="col bg-white d-flex flex-column fn-ui-layout--login__h fn-ui-layout--login__h-lg fn-ui-layout--login__w-lg">

                <div class="bg-primary d-flex justify-content-center align-items-center d-lg-none">
                    <img :src="logo" :alt="alt" class="logo"/>
                </div>

                <div class="p-5 pb-3">
                    <router-view></router-view>
                </div>

                <div class="mt-auto mb-3">
                    <small class="d-block text-center text-gray-600">{{ $t('Built and Managed by Co-Foundry.') }}</small>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

//todo change this to a svg
import logo from "@theme/portal/assets/images/logos/logo-brand.png";
import loginBg from "@theme/portal/assets/images/furniture-pattern.jpg";

export default {
    name: "DefaultLayout",
    components: {},
    computed: {
        logo: function () {
            return logo;
        },
        alt: function () {
            return this.$config("app.name");
        },
        bgStyle: function () {
            return {
                backgroundImage: `url(${loginBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            };
        }
    }
};
</script>

<template>
    <div class="progress-circle">
        <div class="progress-circle__outer" :class="outerCircleClass" :style="outerCircleStyle">
            <div class="progress-circle__inner" :class="innerCircleClass">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressCircle",
    props: {
        max: {
            type: [String, Number],
            default: 100
        },
        value: {
            type: [String, Number],
            required: true
        },
        variant: {
            type: String,
            default: 'primary'
        },
        unitSize: {
            type: String,
            default: "110px"
        },
        innerColor: {
            type: String,
            default: "default"
        }
    },
    computed: {
        outerCircleClass() {
            return 'progress-circle__'+ this.variant;
        },
        outerCircleStyle() {
            let currentDegree = (this.value/this.max) * 360;

            return {
                '--currentDegree': currentDegree + 'deg',
                '--circleSize' : this. unitSize,
            };
        },
        innerCircleClass() {
            return 'progress-circle__inner-bg_' + this.innerColor;
        }
    }
};
</script>

<template>
  <fn-ui-card-layout body-class="p-3" class="filter-tab">
    <loader :loading="loading">
      <div class="font-12">{{ $t('What to only see stores in a particular sector') }}</div>
      <div class="font-18 font-weight-medium">{{ $t('Filter Your results') }}</div>
      <fn-type-select-input
          class="mt-3"
          v-model="model"
          :empty="$t('All Categories')"
          text-key="label"
          value-key="id"
          :options="options"
      ></fn-type-select-input>
      <button class="pl-3 mt-3 btn hover btn-outline-primary btn-alt" @click="handleClick">{{ $t('Filter Results') }}</button>
      <button class="mt-2 btn btn-transparent btn-block btn-sm" @click="$emit('cancel')">{{ $t('Cancel') }}</button>
    </loader>
  </fn-ui-card-layout>
</template>

<script>
import {FnUiCardLayout} from "@fndry-vue/fn-ui";
import {FnTypeSelectInput} from "@fndry-vue/fn-types";

export default {
  name: "FilterTab",
  components: {
    FnUiCardLayout,
    FnTypeSelectInput
  },
  props: {
    type: [String, Number],
  },
  data() {
    return {
      value: null,
      loading: false,
      options: []
    }
  },
  computed: {
    model: {
      get() {
        return this.value ?? this.type;
      },
      set(value) {
        this.value = value;
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('filter', {
        type : this.value
      })
    },
    getTypes() {
      this.loading = true;
      this.$fnApi
        .call(`/api/v1/mobile/stores/types`, "GET")
        .then(response => {
          this.options = response.data
        }).finally(() => {
          this.loading = false;
      })
    }
  },
  mounted() {
    this.getTypes()
  }
}
</script>
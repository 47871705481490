<template>
    <b-form-input type="text"
                  :id="id"
                  :name="name"
                  :value="model"
                  @change="onChange"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :min="minLength"
                  :max="maxLength"
                  :required="required"
                  :state="state"
                  :size="size"
                  :step="step"
                  @keypress="isValidKey"
                  @blur="$emit('blur')"
    ></b-form-input>
</template>

<script>

import {isBoolean} from "lodash";

import {BFormInput} from "bootstrap-vue";
import NumberInput from "./NumberInput";

export default {
    name: "MoneyInput",
    extends: NumberInput,
    components: {
        BFormInput,
    },
    computed: {
        computedState() {
            // If not a boolean, ensure that value is null
            return isBoolean(this.state) ? this.state : null
        },
        stateClass() {
            const state = this.computedState
            return state === true ? 'is-valid' : state === false ? 'is-invalid' : null
        },
    }

};
</script>

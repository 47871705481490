<template>
    <b-form-radio-group
        :id="id"
        :name="name"
        :checked="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :autofocus="autofocus"
        :required="required"
        :state="state"
        :size="size"
        :stacked="isStacked"
        v-on="$listeners"
        >
        <b-form-radio
            class="radio-group-item"
            v-for="(option,index) in options_"
            :value="option.value"
            :key="index"
            :inline="isStacked">
            {{option.text}}
            <div class="radio-group-help-key" v-if="option.help">
                <small>({{ option.help }})</small>
            </div>
        </b-form-radio>
    </b-form-radio-group>

</template>

<script>
    import {BFormRadioGroup, BFormRadio} from 'bootstrap-vue';

    import input from "../mixins/input";
    import options from "../mixins/options";

    export default {
        name: "RadioInput",
        mixins: [
            input,
            options
        ],
        props: {
            stacked: Boolean,
        },
        components: {
            BFormRadioGroup,
            BFormRadio
        },
        computed: {
            isStacked: function() {
                return this.stacked || this.options.some((item) => item.helpKey);
            }
        }
    }
</script>

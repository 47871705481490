<template>
    <validation-provider ref="provider" :vid="vid" :rules="schema.rules" :name="fieldLabel" v-slot="{ dirty, validate, validated, errors, valid, invalid }">
        <b-form-group :id="fieldId"
                      :description="schema.help"
                      :label="(showLabel) ? schema.label : null"
                      :label-for="schema.id"
                      :state="getValidationState({valid, validated, dirty})"
                      :required="fieldRequired"
                      :class="classes"
                      :label-cols="(schema.inline) ? 2: null"
        >
            <div class="field-wrap">
                <div class="input-group">
                    <slot name="prepend">
                        <div class="input-group-prepend" v-if="schema.prepend">
                            <span  v-if="isPrependObject" class="input-group-text"><component :is="schema.prepend.component" v-bind="schema.prepend.props">{{schema.prepend.content}}</component></span>
                            <span v-else class="input-group-text">{{schema.prepend}}</span>
                        </div>
                    </slot>
                    <input-schema :schema="schema"
                                  :key="schema.key"
                                  :name-key="nameKey"
                                  :state="getValidationState({valid, validated, dirty})"
                                  :values="values"
                                  @focus="onFocus"
                                  @blur="onBlur"
                                  v-model="model"
                                  v-on="$listeners"
                                  :errors="errors"
                    ></input-schema>
                    <slot name="append">
                        <div class="input-group-append" v-if="schema.append">
                            <span  v-if="isAppendObject" class="input-group-text"><component :is="schema.append.component" v-bind="schema.append.props">{{schema.append.content}}</component></span>
                            <span v-else class="input-group-text">{{schema.append}}</span>
                        </div>
                    </slot>
                </div>
            </div>
            <b-form-invalid-feedback :state="getValidationState({valid, validated, dirty})">
                <span v-for="(error, index) in errors" :key="index">{{error}}</span>
            </b-form-invalid-feedback>
            <slot name="end"></slot>
        </b-form-group>
    </validation-provider>
</template>

<script>

    import {BFormGroup, BFormInvalidFeedback} from "bootstrap-vue";

    import {isNil, isObject} from "lodash";
    import InputSchema from "./InputSchema";
    import withFormInput from "../mixins/withFormInput";

    export default {
        name: "FormGroup",
        mixins: [
            withFormInput
        ],
        components: {
            InputSchema,
            BFormGroup,
            BFormInvalidFeedback
        },
        data() {
            return {
                focus: false
            };
        },
        props: {
            /**
             * Disable the displaying of the labels above inputs
             *
             * This should only be disabled if the inputs have a placeholder
             */
            noLabel: {
                type: Boolean,
                required: false
            },
            /**
             * A key to help uniquely identify this input in the form
             */
            nameKey: String
        },
        computed: {

            hasValue() {
                return this.model !== null && this.model !== "";
            },

            isPrependObject() {
                return isObject(this.schema.prepend);
            },

            isAppendObject() {
                return isObject(this.schema.append);
            },

            classes(){
                return [
                    `fieldset-${this.schema.type}`,
                    (this.schema.class_name) ? this.schema.class_name : "",
                    {
                        required: this.fieldRequired,
                        "in-focus": this.focus,
                        "has-value": this.hasValue
                    }
                ];
            },

            showLabel: function(){
                if (this.noLabel || this.schema.label == null) {
                    return false;
                }
                if (this.schema.type === "switch") {
                    return false;
                }
                return true;
            },

            fieldLabel: function(){
                if (this.schema.label) {
                    return this.schema.label;
                } else if (this.schema.placeholder) {
                    return this.schema.placeholder;
                } else {
                    return this.schema.name;
                }
            },

            fieldId: function(){
                return `fieldset-${this.schema.id}`;
            },

            vid: function(){
                return this.nameKey ?? this.schema.name;
            },

            isDisabled: function () {
                // let disabled = this.schema.disabled;
                // if (!disabled && this.schema.overridable) {
                //     if (this.model._override.length == 0) {
                //         return true;
                //     } else if (this.model._override.indexOf(this.schema.name) === -1) {
                //         return true;
                //     } else {
                //         return false;
                //     }
                // }
                return this.schema.disabled;
            },
            /**
             * Generates a string value of the fields name
             *
             * @param field
             * @returns {string}
             */
            fieldName: function() {
                return this.schema.name;
            },

            /**
             * Determines if the field is disabled
             *
             * @param field
             * @returns {boolean}
             */
            fieldDisabled: function() {
                if (isNil(this.schema.readonly)) return false;

                return this.schema.disabled;
            },

            /**
             * Determines if the field is readonly
             *
             * @param field
             * @returns {boolean}
             */
            fieldReadonly: function() {
                if (isNil(this.schema.readonly)) return false;

                return this.schema.readonly;
            },

            /**
             * Determines if the field is required
             *
             * @param field
             * @returns {boolean|*}
             */
            fieldRequired: function() {
                if (isNil(this.schema.required)) return false;

                return this.schema.required;
            },

            /**
             * Determines if the field has a label
             *
             * @param field
             * @returns {boolean}
             */
            fieldTypeHasLabel() {
                if (isNil(this.schema.label) || this.schema.type === "hidden") return false;

                let relevantType = "";
                if (this.schema.type === "input") {
                    relevantType = this.schema.inputType;
                } else {
                    relevantType = this.schema.type;
                }

                switch (relevantType) {
                    case "button":
                    case "submit":
                    case "reset":
                        return false;
                    default:
                        return true;
                }
            },
            /**
             * Determines if a field is visible
             *
             * @returns {boolean}
             */
            fieldVisible: function() {
                return this.schema.visible === undefined || this.schema.visible;
            }
        },
        methods: {
            getValidationState({dirty, validated, valid}){
                return dirty || validated ? valid : null;
            },
            onUpdate(value){
                this.model = value;
            },
            onFocus(){
                this.focus = true;
            },
            onBlur(){
                this.focus = false;
            }
        }
    };
</script>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "loader",
    {
      staticClass: "vh-100 bg-white",
      attrs: { loading: _vm.loading, height: "100vh" },
    },
    [
      !_vm.loading
        ? _c(
            "b-carousel",
            {
              staticClass: "welcome-carousel h-auto",
              attrs: { indicators: "" },
            },
            _vm._l(_vm.slides, function (slide, index) {
              return _c(
                "b-carousel-slide",
                {
                  key: index,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "img",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "welcome-carousel__item" },
                              [
                                _c("img", {
                                  staticClass: "welcome-carousel__img",
                                  attrs: { src: slide.english_image },
                                }),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  [
                    slide.has_login_button
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center",
                            on: { click: _vm.handleLogin },
                          },
                          [
                            _c("span", { staticClass: "font-14" }, [
                              _vm._v(_vm._s(_vm.$t("Login"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    slide.has_login_button
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center",
                            on: { click: _vm.handlePayAsGuest },
                          },
                          [
                            _c("span", { staticClass: "font-14" }, [
                              _vm._v(_vm._s(_vm.$t("Pay As Guest"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    slide.has_help_button
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center",
                            on: { click: _vm.handleHelp },
                          },
                          [
                            _c("span", { staticClass: "font-14" }, [
                              _vm._v(_vm._s(_vm.$t("Help"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center",
                        on: { click: _vm.handleRegister },
                      },
                      [
                        _c("span", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("Register"))),
                        ]),
                      ]
                    ),
                  ],
                ],
                2
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-report-table fn-ui-report-controls">
        <slot></slot>
    </div>
</template>

<script>
    import ReportTable from "./ReportTable";

    export default {
        extends: ReportTable,
        name: "ReportControls"
    }
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "loader",
    {
      attrs: {
        loading:
          _vm.loadingCards ||
          _vm.loadingBanks ||
          _vm.loadingPending ||
          _vm.loadingLeases,
      },
    },
    [
      _vm.pendingPayments.length > 0
        ? _c(
            "div",
            _vm._l(_vm.pendingPayments, function (pending, index) {
              return _c("pending-payment-item", {
                key: index,
                attrs: {
                  lease: _vm.getCurrentLeaseFromLoan(pending.loan_number),
                  "default-payment": _vm.defaultPayment,
                },
                on: {
                  "pay-early": function ($event) {
                    return _vm.handlePayEarly(pending)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <fn-ui-card-layout body-class="p-3" class="finder-tab">
      <div class="font-18 font-weight-bold">{{ shopDetails.Name }}</div>
      <div class="font-10">{{ fullAddress }}</div>

      <button class="pl-3 mt-3 btn hover btn-outline-primary btn-alt">{{ $t('Apply Now') }}</button>
      <button class="mt-2 btn btn-transparent btn-block btn-sm" @click="$emit('cancel')">{{ $t('Cancel') }}</button>
  </fn-ui-card-layout>
</template>

<script>
import {FnUiCardLayout} from "@fndry-vue/fn-ui";

export default {
  name: "ShopDetail",
  components: {
    FnUiCardLayout,
  },
  props: {
    shopDetails: Object
  },
  computed: {
    fullAddress() {
      let addressArr = [
          this.shopDetails?.Street1,
          this.shopDetails?.Street2,
          this.shopDetails?.City,
          this.shopDetails?.State,
          this.shopDetails?.Zip,
      ];

      return addressArr.filter(n => n).join(' ');
    }
  }
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h4", { staticClass: "text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("Forgot Password"))),
      ]),
    ]),
    _c("div", [
      _c("p", { staticClass: "text-center text-gray-600" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "Enter your email address and we will send you instructions on how to reset your password."
            )
          )
        ),
      ]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "fn-form-schema",
            {
              ref: "formSchema",
              attrs: { value: _vm.model, "no-inject": "" },
              on: { input: _vm.onUpdate, validated: _vm.onValidated },
            },
            [_c("fn-form-group", { attrs: { schema: _vm.inputs.email } })],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "fn-ui-button",
                {
                  staticClass: "btn-block btn-rounded",
                  attrs: {
                    variant: "primary",
                    disabled:
                      _vm.flags.invalid || _vm.loading || _vm.submitting,
                    submitting: _vm.submitting,
                    type: "submit",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Send Reset Email")) + " ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "mt-4 text-gray-600" }, [
            _c(
              "p",
              { staticClass: "text-center" },
              [
                _vm._v(_vm._s(_vm.$t("Remembered your password?")) + " "),
                _c(
                  "router-link",
                  {
                    staticClass: "text-primary",
                    attrs: { to: _vm.loginRoute },
                  },
                  [_vm._v(_vm._s(_vm.$t("Log In")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
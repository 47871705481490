<template>
    <div class="card-header d-flex">
        <div class="flex-grow-1">
            <slot></slot>
        </div>
        <b-dropdown variant="link" toggle-class="text-decoration-none text-secondary px-0" right
                    class="dropdown-container flex-grow-0" no-caret v-if="withDropdown">
            <template #button-content>
                <icon icon="ellipsis-v" type="solid" size="sm"></icon>
            </template>
            <slot name="dropdown">
            </slot>
        </b-dropdown>
    </div>
</template>
<script>
export default {
    name: 'CardHeader',
    props: {
        withDropdown: Boolean,
        withHeader: Boolean
    }
}
</script>

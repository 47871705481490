<template>
    <div id="search-nav" class="fn-ui-layout-navigation-search fn-ui-layout-navigation-link position-relative">
        <div class="fn-ui-layout-navigation-search__link h-100" @click="toggleSearchInput">
            <span class="fn-ui-layout-navigation-link__icon">
                <icon :icon="icon"></icon>
            </span>
            <span class="fn-ui-layout-navigation-link__text">{{text}}</span>
        </div>
        <div class="fn-ui-layout-navigation-search__form">
            <div class="fn-ui-layout-navigation-search__close" @click="toggleSearchInput">
                <icon icon="times" size="lg"></icon>
            </div>
            <div class="fn-ui-layout-navigation-search__input input-group w-100">
                <input type="text" class="form-control" v-model="searchText" :placeholder="text">
                <div class="input-group-append">
                    <button class="btn btn-link focus" @click="handleSearch">
                        <icon icon="search" size="lg"></icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import gsap from "gsap";

    export default {
        name: "LayoutSearchNavigation",
        props: {
            compact: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: null
            },
            text: {
                type: String,
                required: false
            },
            to: Object,
        },
        data() {
            return {
                showSearchInput: false,
                searchText: "",
            };
        },
        methods: {
            handleSearch() {
                this.$router.push({
                    ...this.to,
                    query: {
                        q: this.searchText
                    }
                });
            },
            toggleSearchInput() {
                this.searchText = "";

                if (this.compact) {
                    this.handleSearch();
                    return;
                }

                this.showSearchInput = !this.showSearchInput;
                if(this.showSearchInput) {
                    this.animateShowSearchInput()
                }
                else {
                    this.animateHideSearchInput()
                }

            },
            animateShowSearchInput() {
                gsap.to(".fn-ui-layout-navigation-search__link .fn-ui-layout-navigation-link__icon",{x:250, ease:"circ.inOut", duration: 0.5})
                gsap.to(".fn-ui-layout-navigation-search__link",{display: "none", duration: 0.5})
                gsap.fromTo(".fn-ui-layout-navigation-search__form",{display: "none"},{display: "block", duration: 0})
                gsap.fromTo(".fn-ui-layout-navigation-search__form",{autoAlpha: 0},{autoAlpha: 1, ease:"circ.inOut", duration: 1.25})
                gsap.fromTo(".fn-ui-layout-navigation-search__input input",{scaleX: 0},{scaleX: 1, ease:"circ.inOut", duration: 0.5})
                gsap.to(".fn-ui-layout-navigation-search__form",{position:"relative", delay: 0.5})

            },
            animateHideSearchInput() {
                gsap.to(".fn-ui-layout-navigation-search__form",{position:"absolute", delay: 0})
                gsap.fromTo(".fn-ui-layout-navigation-search__input input",{scaleX: 1},{scaleX: 0, ease:"circ.inOut", duration: 0.5})
                gsap.fromTo(".fn-ui-layout-navigation-search__form",{autoAlpha: 1},{autoAlpha: 0, ease:"circ.inOut", duration: 0.25})
                gsap.fromTo(".fn-ui-layout-navigation-search__form",{display: "block"},{display: "none", duration: 0.5})
                gsap.to(".fn-ui-layout-navigation-search__link",{display: "block", duration: 1})
                gsap.to(".fn-ui-layout-navigation-search__link .fn-ui-layout-navigation-link__icon",{x: 0, ease:"circ.inOut", duration: 0.5})
            },
        },
        watch: {
            compact: {
                handler(newValue) {
                    if (newValue === true && this.showSearchInput) {
                        this.showSearchInput = false;
                        this.animateHideSearchInput();
                    }
                }
            }
        }

    }
</script>

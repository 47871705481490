<template>
    <div :class="[schema.class, 'fn-type-collection']" :id="schema.id">
        <div class="fn-type-collection__multiple">
            <div class="fn-type-collection__children">
                <p v-if="!children || children.length === 0" class="fn-type-collection__none">{{noContentText}}</p>
                <div :class="{'fn-type-collection__items': true, 'fn-type-collection__items--accordion': mode === 'accordion'}" :role="(mode === 'accordion') ? 'tablist' : null">
                    <fieldset
                        :key="`${index}-${child.id}`"
                        v-for="(child, index) in children"
                    >
                        <legend class="fn-type-collection__item-legend">
                            <div class="fn-type-collection__item-header" v-b-toggle="`fn-type-collection__item-${index}`">
                                <div class="fn-type-collection__item-title">{{title}} #{{index + 1}}</div>
                                <icon v-if="mode === 'accordion'" icon="plus" class="fn-type-collection__item-toggle fn-type-collection__item-toggle--plus"></icon>
                                <icon v-if="mode === 'accordion'" icon="minus" class="fn-type-collection__item-toggle fn-type-collection__item-toggle--minus"></icon>
                            </div>
                            <div class="fn-type-collection__item-buttons">
                                <collection-buttons :can-move-up="canMoveChild && (index > 0)"
                                                    :can-move-down="canMoveChild && (index < children.length - 1)"
                                                    :can-remove="removeChildButton && canRemoveChild"
                                                    @remove="() => handleRemoveChild(index)"
                                                    @move-down="() => moveChildDown(index)"
                                                    @move-up="() => moveChildUp(index)"
                                />
                            </div>
                        </legend>
                        <component :is="(mode === 'accordion') ? 'b-collapse' : 'div'" :id="`fn-type-collection__item-${index}`">
                            <fn-form-schema no-inject
                                            :schema="child"
                                            :value="model[index]"
                                            :no-label="noChildLabel(index)"
                                            @input="(val) => onUpdateChild(index, val)"
                                            :name-key="nameKey+'.'+index"
                            />
                        </component>
                    </fieldset>
                </div>
            </div>
            <div class="fn-type-collection__buttons">
                <b-button v-if="addChildButton && canAddChild" class="fn-type-collection__button-add" :variant="addChildButton.variant" @click="handleAddChild">
                    <icon icon="plus" /> {{addChildButton.label}}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>

import {find, merge, uniqueId} from 'lodash';
import {BButton} from 'bootstrap-vue';
import input from "../mixins/input";
import CollectionButtons from "./Collection/CollectionButtons";
import minMax from "../mixins/minMax";
import CollectionInput from "./CollectionInput";

export default {
        name: "MultiCollectionInput",
        extends: CollectionInput,
        components: {
            BButton,
            CollectionButtons,
        },
        mixins: [
            minMax,
        ],
        props: {
            mode: {
                type: String,
                default: 'expanded'
            },
            buttons: Array,
            moveButtons: Boolean,
            itemLabel: String
        },
        data(){
            return {
                children: []
            }
        },
        computed: {
            model: {
                get: function(){
                    return this.value || [];
                },
                set: function(value){
                    this.$emit('input', {...value});
                }
            },

            title: function(){
                if (this.itemLabel) {
                    return this.itemLabel;
                } else if (this.label) {
                    return this.label;
                } else {
                    return 'Item';
                }
            },
            noContentText: function(){
                if (this.schema.no_content_text) {
                    return this.schema.no_content_text;
                } else {
                    return 'No items added yet';
                }
            },
            removeChildButton: function(){
                let button = find(this.buttons, (button) => button.buttonType === 'remove-button');
                if (button) {
                    return button;
                }
                return undefined;
            },
            addChildButton: function(){
                let button = find(this.buttons, (button) => button.buttonType === 'add-button');
                if (button) {
                    return button;
                }
                return undefined;
            },
            canAddChild: function(){
                if (this.max) {
                    return (this.model.length < this.max);
                }
                return true;
            },
            canRemoveChild: function(){
                if (this.min) {
                    return (this.model.length > this.min);
                }
                return true;
            },
            canMoveChild: function() {
                if (this.moveButtons) {
                    return true;
                }
                return false;
            },
        },
        created() {
            this.initMultipleChildren();
        },
        methods: {
            randomId() {
                return uniqueId("Collection_");
            },

            initMultipleChildren(){
                if (this.model && this.model.length > 0) {
                    let children = this.children;
                    for (let i=0;i<this.model.length;i++) {
                        children.push(merge({}, this.schema, {id: this.randomId()}));
                    }
                    this.children = [...children];
                } else if (this.min) {
                    this.addChildren(this.min);
                }
            },
            addChildren(quantity){
                let children = this.children;
                let model = this.model;
                let i = 1;
                while (i <= quantity) {
                    children.push(merge({}, this.schema, {id: this.randomId()}));
                    model.push(merge({}, this.schema.values));
                    i++;
                }
                this.children = [...children];

                this.$emit('input', [...model]);
            },
            handleAddChild(){
                this.addChildren(1);
            },
            handleRemoveChild(index){

                this.$fnModals.confirm('Are you sure you want to delete this?')
                    .then(value => {
                        if (value) {
                            let children = this.children;
                            children.splice(index, 1);
                            this.children = [...children];

                            let model = this.model;
                            model.splice(index, 1);
                            this.$emit('input', [...model]);
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })

            },
            moveChildUp(index){
                // Only the value need to move, not the children
                //
                let children = this.children;
                let child = children.splice(index, 1);
                children.splice(index - 1, 0, child[0]);
                this.children = [...children];

                let model = this.model;
                let value = model.splice(index, 1);
                model.splice(index - 1, 0, value[0]);
                this.$emit('input', [...model]);

            },
            moveChildDown(index){

                // Only the value need to move, not the children
                //
                let children = this.children;
                let child = children.splice(index, 1);
                children.splice(index + 1, 0, child[0]);
                this.children = [...children];

                let model = this.model;
                let value = model.splice(index, 1);
                model.splice(index + 1, 0, value[0]);
                this.$emit('input', [...model]);
            },
            onUpdateChild(index, value) {
                let model = this.model;
                model[index] = {...value};
                this.$emit('input', [...model]);
            },
            noChildLabel(index) {
                return (index > 0);
            }
        }

    };
</script>

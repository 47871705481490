<template>
    <b-modal v-bind="$props"
             visible
             ok-only
             v-on="$listeners"
         >{{message}}</b-modal>
</template>

<script>
export default {
    name: "ConfirmModal",
    props: {
        title: {
            type: String,
            default: "Alert"
        },
        size: {
            type: String,
            default: "md"
        },
        okVariant: {
            type: String,
            default: "primary"
        },
        okTitle: {
            type: String,
            default: "Close"
        },
        centered: Boolean,
        message: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped>

</style>

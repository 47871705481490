<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t("Set Password")}}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{$t("You need to set / change your password.")}}</p>
            <fn-form class="mt-3" :uri="formUri" uri-method="POST" :value="form" @success="onSuccess" @fail="onFail">
                <template #buttons="{submit, invalid, flags, loading, submitting}">
                    <div class="form-group">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="!flags.valid || loading || submitting" :submitting="submitting" type="submit">{{$t("Set Password")}}</fn-ui-button>
                    </div>
                </template>
            </fn-form>
        </div>
    </div>
</template>

<script>
    import {AUTH_CHECK} from "./index";
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

    export default {
        name: "AuthSetPassword",
        mixins: [
            withAutoGuard
        ],
        data() {
            return {
                form: {
                    password: "",
                    password_confirmation: "",
                    terms: false
                }
            };
        },
        computed: {
            formUri() {
                return "/api/v2/auth/set-password";
            }
        },
        methods: {
            onSuccess(){
                this.$fnNotify(this.$t("Password set!"));
                this.$router.push({
                    name: AUTH_CHECK
                });
            },
            onFail(response){
                if (response.code === 422) {
                    this.$refs.observer.setErrors(response.data);
                }
            }
        }
    };
</script>

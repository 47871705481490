<template>
    <b-tr :class="['fn-ui-report-row', {'fn-ui-report-row-total': isTotal}, {'fn-ui-report-row-controls': hasControls}]" :style="cssVars">
        <slot></slot>
    </b-tr>
</template>

<script>
    export default {
        name: "ReportRow",
        props: {
            isTotal: {
                type: Boolean,
                default: false,
            },
            hasControls: {
                type: Boolean,
                default: false
            },
            columns: {
                type: Number,
                default: 13
            },
        },
        computed: {
            cssVars() {
                return {
                    "--columns": this.columns
                };
            }
        }
    };
</script>

/**
 * Actions Buttons for compacting or expanding a set of actions
 *
 * This component will show all actions if it is less than or equal to 4.
 * If in 'compact mode', it will just render a dropdown with all the actions available, otherwise it  will show a max of 3 actions and a dropdown
 *
 * This actions corresponds to the elements passed on to this component's default slot.
 * The elements passed can be any element or component. The only requirement is it should have the 'title' attribute. It will use this to render the text in the dropdown list.
 * Note: If using the fn-api-button component, use the 'tooltip' prop instead of 'title' attribute
 *
 */
export default {
    name: "ActionButtons",
    props: {
        /**
         * @values "compact", "expanded"
         */
        mode: {
            type: String,
            default: "expanded"
        }
    },
    data() {
        return {
            isCollapse: false,
        }
    },
    mounted() {
    },
    computed: {
        isMobile() {
            return screen.width < 768;
        }
    },
    render(createElement) {
        let actions = this.$slots.default;

        if(actions.length > 0) {
            let visibleActions = [];
            let hiddenActions = [];

            actions.forEach( (vnode,index) => {

                if (vnode.tag === undefined) {
                    return;
                }

                if(visibleActions.length < 3 || actions.length <= 4) {
                    visibleActions.push(vnode)
                }

                let text = vnode.data?.attrs?.title ?? (vnode.componentOptions?.propsData?.tooltip ?? "Untitled Action");

                let actionElement = createElement("span", {
                    class: "d-none",
                    ref: "actionElem-" + index
                }, [vnode]);

                let collapseItem = createElement("b-dropdown-item",{
                    on: {
                        click: () => {
                            this.$refs["actionElem-" + index].children[0].click()
                        }
                    }
                },[
                    text, actionElement
                ]);

                hiddenActions.push(
                    createElement('div', [collapseItem])
                )

            });

            let collapseContainer = createElement();

            if(actions.length > 4 || this.$props.mode === "compact") {
                collapseContainer = createElement('b-dropdown', {
                    class: "dropdown-container flex-grow-0 ml-1",
                    attrs: {
                        id: "collapse-" + this._uid
                    },
                    props: {
                        variant: "link",
                        'toggle-class': "action-button-ellipsis text-decoration-none my-n3",
                        'menu-class': "action-button-dropdown",
                        right: true,
                        'no-caret': true
                    }
                }, [ createElement('template',{
                    slot: 'button-content'
                }, [
                    createElement("icon", {
                        props: {
                            icon: "ellipsis-v",
                            type: "solid",
                            size: "sm"
                        }
                    })
                ]), ...hiddenActions]);
            }

            let children = [];

            if(this.$props.mode === "expanded") {
                children = [...visibleActions, collapseContainer];
            }
            else if(this.$props.mode === "compact") {
                children = [collapseContainer];
            }

            return createElement("div", {class: 'action-buttons'}, children);
        }
        else {
            return createElement(this.$slots.default);
        }
    }
}

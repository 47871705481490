<template>
    <layout-column
        :mode="mode"
        :class="['fn-ui-layout__nav fn-ui-layout__primary-nav', {'show': show}]"
        @mouseover.native="onMouseOver"
        @mouseleave.native="onMouseLeave"
    >
        <template #header>
            <slot name="header" v-bind="{toggleCompact, toggleShow}">
                <layout-column-header-branded
                    :icon="logo"
                    :icon-compact="icon"
                    :compact="computedCompact"
                    @toggle-compact="toggleCompact"
                    @toggle-show="toggleShow"
                />
            </slot>
        </template>
        <template #default v-bind="{toggleCompact}">
            <slot>
                <div class="fn-ui-layout-column-body">
                    <layout-navigation-list
                        v-if="mainMenu.length"
                        title="Application"
                        title-compact="App"
                        :compact="computedCompact"
                        :items="mainMenu"
                        menu-collapsed-text="Open Application Menu"
                        :menu-collapsed="expandedMenu !== 'app'"
                        @expand-menu="() => handleExpandMenu('app')"
                        @show-children="() => toggleCompact(false)"
                    />
                </div>
            </slot>
        </template>
        <template #footer>
            <slot name="footer">
                <div class="fn-ui-layout-column-body" v-if="adminMenu.length || userMenu.length">
                    <layout-navigation-list
                        v-if="adminMenu.length"
                        title="Admin"
                        title-compact="Admin"
                        :compact="computedCompact"
                        :items="adminMenu"
                        menu-collapsed-text="Open Admin Menu"
                        :menu-collapsed="expandedMenu !== 'admin'"
                        @expand-menu="() => handleExpandMenu('admin')"
                    />
                    <layout-navigation-list
                        v-if="userMenu.length"
                        title="User"
                        title-compact="Usr"
                        :compact="computedCompact"
                        :items="userMenu"
                    />
                </div>
            </slot>
        </template>
    </layout-column>
</template>

<script>
import {LayoutColumn, LayoutColumnHeaderBranded, LayoutNavigationList} from "../layout";
import {fnMenuHoverMixin} from "@fndry-vue/fn-menus";

export default {
    name: "PrimaryMenuColumn",
    components: {
        LayoutColumn,
        LayoutColumnHeaderBranded,
        LayoutNavigationList,
    },
    mixins: [fnMenuHoverMixin],
    props: {
        compact: Boolean,
        show: Boolean,
        icon: String,
        logo: String,
        mainMenu: {
            type: Array,
            default() {
                return [];
            }
        },
        adminMenu: {
            type: Array,
            default() {
                return [];
            }
        },
        userMenu: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            expandedMenu: "app",
        };
    },
    computed: {
        mode() {
            return this.computedCompact ? "compact" : "expanded";
        },
    },
    methods: {
        toggleCompact(compact) {
            if(compact !== undefined) {
                this.compactWhileHovered = compact;
            } else {
                this.compactWhileHovered = !this.compactWhileHovered;
            }
            this.$emit("toggle-compact", compact);
        },
        toggleShow() {
            this.$emit("toggle-show");
        },
        handleExpandMenu(name) {
            this.expandedMenu = name;
        }
    },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h4", { staticClass: "text-center text-gray-600" }, [
        _vm._v(_vm._s(_vm.$t("Password Reset Request Processed"))),
      ]),
    ]),
    _c("div", [
      _c("p", { staticClass: "lead text-center text-gray-600" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "Please check your mail for a reset link. After cliking that link, and entering a new password, you can proceed to login."
            )
          )
        ),
      ]),
      _c(
        "div",
        { staticClass: "form-group mt-4" },
        [
          _c(
            "fn-ui-button",
            {
              staticClass: "btn-block btn-rounded",
              attrs: { variant: "primary", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goToLogin.apply(null, arguments)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Proceed to login")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm.$t("Didn't receive the reset link?")) + " "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.resendPin.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(_vm._s(_vm.$t("Resend")) + " "),
              _vm.resendingPin
                ? _c("b-spinner", {
                    staticClass: "ml-1",
                    attrs: {
                      small: "",
                      variant: "primary",
                      label: _vm.$t("Loading"),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-form-input type="text"
                  :id="id"
                  :name="name"
                  :value="model"
                  @change="onChange"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :min="min"
                  :max="max"
                  :required="required"
                  :state="state"
                  :size="size"
                  :step="step"
                  @keypress="isValidKey"
                  @blur="$emit('blur')"
    ></b-form-input>
</template>

<script>
import TextInput from "./TextInput";
import minMax from "../mixins/minMax";
import format from "../mixins/format";
import mask from "../mixins/mask";
import {BFormInput} from "bootstrap-vue";
import numeral from "numeral";

export default {
    name: "PercentageInput",
    extends: TextInput,
    mixins: [
        minMax,
        format,
        mask
    ],
    components: {
        BFormInput
    },
    props: {
        step: {
            type: [Number, String],
            required: false
        },
    },
    computed: {
        model: {
            get: function () {
                if (this.value !== null) {
                    return numeral(this.value * 100).format("00.[00]");
                } else {
                    return this.value;
                }
            },
            set: function (value) {
                this.$emit("input", numeral(value).value() / 100);
            }
        }
    },
    methods: {
        onChange(value){
            this.model = value;
        },
        isValidKey(val) {
            if (isNaN(Number(val.key)) && val.key !== "." && val.key !== "-") {
                return val.preventDefault();
            }
        }
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fn-form", {
    ref: "fnForm",
    attrs: {
      "no-autoload": "",
      "no-buttons": "",
      uri: _vm.formUri,
      "uri-method": "PUT",
    },
    on: { success: _vm.onSuccess, fail: _vm.onFail, cancel: _vm.handleCancel },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ loading, loaded, submit, flags, cancel }) {
          return [
            _c(
              "fn-form-schema",
              [
                _c("fn-form-group", { attrs: { schema: _vm.inputs.email } }),
                _c("fn-form-group", { attrs: { schema: _vm.inputs.password } }),
              ],
              1
            ),
            _c(
              "fn-ui-button",
              {
                staticClass: "btn-block",
                attrs: {
                  variant: "outline-primary",
                  disabled: !flags.valid || loading || _vm.submitting,
                  submitting: _vm.submitting,
                  type: "submit",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Change Email")) + " ")]
            ),
            _c(
              "fn-ui-button",
              {
                staticClass: "btn-block",
                attrs: { variant: "outline-primary" },
                on: { click: cancel },
              },
              [_vm._v(_vm._s(_vm.$t("Cancel")))]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v
      },
      expression: "form",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-layout">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Layout"
    };
</script>

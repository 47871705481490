var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "text-primary font-weight-bold" }, [
      _vm._v(_vm._s(_vm.topic.title)),
    ]),
    _c(
      "div",
      { staticClass: "mt-5 pt-5" },
      [
        _c("FnUiAccordion", {
          staticClass: "faq-accordion",
          attrs: { list: _vm.questions },
          scopedSlots: _vm._u([
            {
              key: "header(default)",
              fn: function (item) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-4 py-3 border-bottom border-light d-flex justify-content-between align-items-center pointer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mr-3 bg-primary rounded-circle p-2" },
                        [
                          _c("icon", {
                            staticClass: "text-white",
                            attrs: {
                              icon: "bullseye-pointer",
                              type: "solid",
                              size: "xl",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "font-16 text-dark" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("icon", {
                        staticClass: "ml-auto text-dark icon-not-collapsed",
                        attrs: { icon: "chevron-down", size: "xl" },
                      }),
                      _c("icon", {
                        staticClass: "ml-auto text-dark icon-collapsed",
                        attrs: { icon: "chevron-right", size: "xl" },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./TopicItem.vue?vue&type=template&id=217a7a52&"
import script from "./TopicItem.vue?vue&type=script&lang=js&"
export * from "./TopicItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\takaj\\Documents\\COFOUNDRY\\okinus-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('217a7a52')) {
      api.createRecord('217a7a52', component.options)
    } else {
      api.reload('217a7a52', component.options)
    }
    module.hot.accept("./TopicItem.vue?vue&type=template&id=217a7a52&", function () {
      api.rerender('217a7a52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/support/components/TopicItem.vue"
export default component.exports
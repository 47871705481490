var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column h-100 justify-content-between" },
    [
      _c("div", [
        _c("h4", { staticClass: "text-left text-primary" }, [
          _vm._v(_vm._s(_vm.subTitle)),
        ]),
      ]),
      _c(
        "div",
        [
          _c("div", { staticClass: "mt-3" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c("fn-form-schema", {
                  ref: "formSchema",
                  attrs: { value: _vm.model, "no-inject": "" },
                  on: { input: _vm.onUpdate, validated: _vm.onValidated },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ updateField }) {
                        return [
                          _c("fn-form-group", {
                            attrs: { schema: _vm.schema.last_name },
                          }),
                          _c("fn-form-group", {
                            attrs: { schema: _vm.schema.ssn },
                          }),
                          _c("fn-form-group", {
                            attrs: { schema: _vm.dateOfBirth },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "div",
                  { staticClass: "form-group mt-4" },
                  [
                    _c(
                      "fn-ui-button",
                      {
                        staticClass: "btn-block btn-rounded",
                        attrs: {
                          variant: "primary",
                          disabled: _vm.flags.invalid || _vm.submitting,
                          submitting: _vm.submitting,
                          type: "submit",
                        },
                      },
                      [_vm._v(_vm._s(_vm.submitButtonText))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._t("further-actions"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-report-taggable-list">
        <div class="fn-ui-report-taggable-list__header">
            {{ title }}
        </div>

        <div class="fn-ui-report-taggable-list__items">
            <report-taggable-list-item
                v-for="(item, index) in items"
                :key="index"
                :label="item.label"
                :action-text="actionText"
                @cancel="() => handleCancel(index)"
                @action="() => handleAction(index)"
            >
            </report-taggable-list-item>
        </div>
    </div>
</template>

<script>
    import ReportTaggableListItem from "./ReportTaggableListItem";
    export default {
        name: "ReportTaggableList",
        components: {
            ReportTaggableListItem
        },
        props: {
            title: String,
            actionText: String,
            items: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            handleCancel(index) {
                this.$emit("cancel", index);
            },
            handleAction(index) {
                this.$emit("action", index);
            }
        }
    };
</script>

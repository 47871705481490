<template>
    <div class="fn-ui-report-hierarchy-selector">
        <div class="hierarchy-selector-wrapper">
            <transition name="slide" :duration="{ enter: 600, leave:300 }" appear v-if="tier[1].show" v-on:after-leave="$emit('back')">
                    <layout-column mode="expanded" class="tier-1">
                        <template #header>
                            <report-menu-select-header :title="headerTitle + ' 1'" class="bg-gray-100" @back="handleClose()"></report-menu-select-header>
                        </template>
                        <template #default>
                            <div class="bg-gray-100 h-100">
                                <report-menu-section>
                                    <report-menu-search-section :title="searchTitle  + ' 1'" :text="searchText"></report-menu-search-section>
                                </report-menu-section>

                                <report-menu-section>
                                    <report-menu-items-selection :items="selectItems" :expanded="tier[2].show" @expand="handleTier2Expand" @collapse="handleClose(2)"></report-menu-items-selection>
                                </report-menu-section>
                            </div>
                        </template>
                    </layout-column>
            </transition>

            <transition name="slide" :duration="{ enter: 600, leave:300 }">
                    <layout-column mode="expanded" class="tier-2" v-if="tier[2].show">
                        <template #header>
                            <report-menu-select-header :title="headerTitle + ' 2'" class="bg-gray-100" @back="handleClose(2)"></report-menu-select-header>
                        </template>
                        <template #default>
                            <div class="bg-gray-100 h-100">
                                <report-menu-section>
                                    <report-menu-search-section :title="searchTitle + ' 2'" :text="searchText"></report-menu-search-section>
                                </report-menu-section>

                                <report-menu-section>
                                    <report-menu-items-selection :items="tier[2].items" :expanded="tier[3].show" @expand="handleTier3Expand" @collapse="handleClose(3)"></report-menu-items-selection>
                                </report-menu-section>
                            </div>
                        </template>
                    </layout-column>
            </transition>
            <transition name="slide" :duration="{ enter: 600, leave:300 }">
                    <layout-column mode="expanded" class="tier-3" v-if="tier[3].show">
                        <template #header>
                            <report-menu-select-header :title="headerTitle + ' 3'" class="bg-gray-100" @back="handleClose(3)"></report-menu-select-header>
                        </template>
                        <template #default>
                            <div class="bg-gray-100 h-100">
                                <report-menu-section>
                                    <report-menu-search-section :title="searchTitle + ' 3'" :text="searchText"></report-menu-search-section>
                                </report-menu-section>

                                <report-menu-section>
                                    <report-menu-items-selection :items="tier[3].items"></report-menu-items-selection>
                                </report-menu-section>
                            </div>
                        </template>
                    </layout-column>
            </transition>

        </div>
    </div>
</template>

<script>
    import LayoutColumn from "../../layout/LayoutColumn";
    import LayoutRow from "../../layout/LayoutRow";
    import ReportMenuSelectHeader from "../ReportMenu/ReportMenuSelectHeader/ReportMenuSelectHeader";
    import ReportMenuSearchSection from "../ReportMenu/ReportMenuSearchSection/ReportMenuSearchSection";
    import ReportMenuItemsSelection from "../ReportMenu/ReportMenuItemsSelection/ReportMenuItemsSelection";
    import ReportMenuSection from "../ReportMenu/ReportMenuSection/ReportMenuSection";

    export default {
        name: "ReportHierarchySelector",
        components: {
            LayoutColumn,
            LayoutRow,
            ReportMenuSelectHeader,
            ReportMenuItemsSelection,
            ReportMenuSearchSection,
            ReportMenuSection,
        },
        props: {
            headerTitle: String,
            searchTitle: String,
            searchText: String,
            selectItems: {
                type: Array,
                default() {
                    return []
                }
            },
        },
        data() {
            return {
                tier: {
                    1: {
                        show: true,
                        items: this.selectItems
                    },
                    2: {
                        show: false,
                        items: []
                    },
                    3: {
                        show: false,
                        items: []
                    }
                }
            }
        },
        methods: {
            handleClose(value = 1) {
                [1,2,3].forEach((i) => {
                    if(i >= value) {
                        this.tier[i].show = false;
                    }
                })

            },
            handleTier2Expand(items) {
                if(!this.tier[2].show) {
                    this.tier[2].show = true;
                    this.tier[2].items = items;
                }
                else {
                    this.tier[2].show = false;
                    this.tier[2].items = [];
                }
            },

            handleTier3Expand(items) {
                if(!this.tier[3].show) {
                    this.tier[3].show = true;
                    this.tier[3].items = items;
                }
                else {
                    this.tier[3].show = false;
                    this.tier[3].items = [];
                }
            }
        }
    }
</script>

<style lang="scss">
    .fn-ui-layout-column {
        &.tier-1 {
            z-index: 28;
        }
        &.tier-2 {
            z-index: 27;
        }
        &.tier-3 {
            z-index: 26;
        }
    }
</style>

<template>
    <report-menu-section class="fn-ui-report-currency-control">
        <div class="fn-ui-report-menu-search-section__title">
            <span>{{ $t('Set Currency') }}</span>
            <span class="float-right small text-primary pointer">{{ $t('Reset to default') }}</span>
        </div>
        <div class="fn-ui-report-menu-search-section__text">{{ $t('Select currency defaults') }}</div>

        <div class="fn-ui-report-menu-search-section__title mt-4">{{ $t('Conversion Value') }}</div>

        <div class="row mb-3" v-for="(item,index) in currencies" :key="index">
            <div class="col-6 d-flex justify-content-start align-items-center">
                <b-checkbox size="sm" @click="$emit('')"></b-checkbox>
                <div class="small ml-2">[{{ activeCurrency }}] to {{ item.label }}</div>
            </div>
            <div class="col-6">
                <b-input size="sm" :value="item.conversion_rate"></b-input>
            </div>
        </div>

        <b-button class="fn-ui-report-currency-control__calculate" variant="primary" block @click="handleCalculate">Calculate</b-button>
    </report-menu-section>
</template>

<script>
    import ReportMenuSection from "../ReportMenu/ReportMenuSection/ReportMenuSection";

    export default {
        name: "ReportCurrencyControl",
        components: {
            ReportMenuSection
        },
        props: {
            activeCurrency: String,
            currencies: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        methods: {
            handleCalculate() {
                this.$emit('calculate', this.currencies)
            }
        }
    }
</script>

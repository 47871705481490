<template>
    <div :class="['fn-ui-report-range-selector', 'fn-ui-report-range-selector--'+ viewType]">
        <report-range-selector-drop-down :types="types" :value="type" @change="setViewType"></report-range-selector-drop-down>
        <div class="fn-ui-report-range-selector__range">
            <div class="fn-ui-report-range-selector__range-wrapper">
                <report-range-selector-legend :dates="range" :type="viewType"></report-range-selector-legend>
                <report-range-selector-slider
                    :start-index="startIndex"
                    :end-index="endIndex"
                    :dates="range"
                    @change="handleSelectedDateChange"
                    :max-columns="maxColumns"
                ></report-range-selector-slider>
            </div>
        </div>
    </div>
</template>

<script>
import ReportRangeSelectorLegend from "./ReportRangeSelectorLegend";
import ReportRangeSelectorDropDown from "./ReportRangeSelectorDropDown";
import ReportRangeSelectorSlider from "./ReportRangeSelectorSlider";
import {debounce} from "lodash/function";

export default {
    name: "ReportRangeSelector",
    props: {
        startIndex: {
            type: Number,
            required: true,
        },
        endIndex: {
            type: Number,
            required: true,
        },
        range: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        types: {
            type: Array,
            required: true
        },
        maxColumns: {
            type: [Number, String],
            default: 12
        },
    },
    components: {
        ReportRangeSelectorLegend,
        ReportRangeSelectorDropDown,
        ReportRangeSelectorSlider,
    },
    data() {
        return {};
    },
    computed: {
        viewType: {
            get() {
                return this.type;
            },
            set(value) {
                this.$emit("change-timeline", value);
            }
        }
    },
    methods: {
        setViewType(value) {
            this.viewType = value;
        },
        handleSelectedDateChange: debounce(function(data) {
            this.$emit("change-range", data);
        }, 500)
    }
};
</script>

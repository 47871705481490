<template>
    <panel @close="handleClose" :title="title">
        <fn-form ref="fnForm" v-bind="$props" v-on="$listeners" @success="emitClose" @cancel="handleClose" v-model="form" @validated="onValidated"></fn-form>
    </panel>
</template>

<script>
import Panel from "./Panel";
import {fnPanelFormMixin} from "@fndry-vue/fn-panels";

export default {
    name: "PanelForm",
    mixins:[
        fnPanelFormMixin
    ],
    components: {
        Panel
    },
    props: {
        title: String,
        uri: String,
        uriParams: {
            type: Object,
            required: false
        },
        uriMethod: {
            type: String,
            required: false
        }
    },
    data(){
        return {
            form: null
        };
    }
};
</script>

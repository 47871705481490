var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "current-lease-item" }, [
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Merchant Name"))),
      ]),
      _c("div", { staticClass: "col text-right" }, [
        _vm._v(_vm._s(_vm.lease.store_name + " ")),
      ]),
    ]),
    _c("div", { staticClass: "row row-cols-2 mb-2" }, [
      _c("div", { staticClass: "col text-left" }, [
        _vm._v(_vm._s(_vm.$t("Start Date"))),
      ]),
      _c("div", { staticClass: "col text-right" }, [
        _vm.lease.extra.RECGoodsDate
          ? _c("span", [_vm._v(_vm._s(_vm.formattedStartDate ?? ""))])
          : _c("span", [_vm._v("---")]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col", on: { click: () => _vm.handleGuestPay() } },
        [
          _c(
            "div",
            {
              staticClass:
                "btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center",
            },
            [
              _c("span", { staticClass: "font-14" }, [
                _vm._v(_vm._s(_vm.$t("Make Payment"))),
              ]),
              _c("icon", {
                staticClass: "ml-auto",
                attrs: { icon: "long-arrow-right", size: "xl" },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div :class="['fn-ui-report-column', 'fn-ui-report-column-header']">
        <slot></slot>
    </div>
</template>

<script>
    import ReportColumn from "./ReportColumn";

    export default {
        extends: ReportColumn,
        name: "ReportColumnHeader"
    }
</script>

import { render, staticRenderFns } from "./LayoutNavigationHeader.vue?vue&type=template&id=4a211f68&"
import script from "./LayoutNavigationHeader.vue?vue&type=script&lang=js&"
export * from "./LayoutNavigationHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\takaj\\Documents\\COFOUNDRY\\okinus-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a211f68')) {
      api.createRecord('4a211f68', component.options)
    } else {
      api.reload('4a211f68', component.options)
    }
    module.hot.accept("./LayoutNavigationHeader.vue?vue&type=template&id=4a211f68&", function () {
      api.rerender('4a211f68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "packages/fndry-vue/packages/fn-ui/components/layout/LayoutNavigationHeader.vue"
export default component.exports
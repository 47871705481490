<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{ $t("Forgot Password") }}</h4>
        </div>
        <div>
            <p class="text-center text-gray-600">{{ $t("Enter your email address and we will send you instructions on how to reset your password.") }}</p>
            <fn-form class="mt-3" ref="form" :uri="formUri" :uri-params="formUriParams" uri-method="POST" v-model="form"
                     @success="onSuccess">
                <template #buttons="{submit, invalid, loading, submitting, flags}">
                    <div class="form-group">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded"
                                      :disabled="!flags.valid || loading || submitting" :submitting="submitting"
                                      type="submit">{{ $t("Forgot Password") }}
                        </fn-ui-button>
                    </div>
                    <div class="mt-4 text-gray-600">
                        <p class="text-center">{{ $t("Remembered your password?") }}
                            <router-link class="text-primary" :to="loginRoute">{{ $t("Log In") }}</router-link>
                        </p>
                    </div>
                </template>
            </fn-form>
        </div>
    </div>
</template>

<script>
import {AUTH_FORGOT_NOTIFY, AUTH_LOGIN} from "./index";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

export default {
    name: "AuthForgot",
    mixins: [
        withAutoGuard
    ],
    data() {
        return {
            form: {
                email: ""
            }
        };
    },
    computed: {
        formUri() {
            return "/api/v2/auth/forgot";
        },
        formUriParams() {
            return this.getGuardUriParams();
        },
        loginRoute() {
            return {
                name: AUTH_LOGIN
            };
        }
    },
    methods: {
        onSuccess(response) {
            if (response.status) {
                this.$router.push({
                    name: AUTH_FORGOT_NOTIFY,
                    params: {
                        token: response.data.token,
                        email: this.form.email
                    }
                });
            }
        }
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    { staticClass: "dashboard-tabs", attrs: { fill: "" } },
    _vm._l(_vm.tabs, function (tab, index) {
      return _c("b-tab", {
        key: index,
        attrs: { title: tab.name, active: _vm.isActive(tab.route_name) },
        on: {
          click: function ($event) {
            return _vm.goToRoute(tab.route_name)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
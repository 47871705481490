import { render, staticRenderFns } from "./AuthCheck.vue?vue&type=template&id=97e55202&"
import script from "./AuthCheck.vue?vue&type=script&lang=js&"
export * from "./AuthCheck.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\takaj\\Documents\\COFOUNDRY\\okinus-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97e55202')) {
      api.createRecord('97e55202', component.options)
    } else {
      api.reload('97e55202', component.options)
    }
    module.hot.accept("./AuthCheck.vue?vue&type=template&id=97e55202&", function () {
      api.rerender('97e55202', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "auth/src/features/auth/screens/AuthCheck.vue"
export default component.exports
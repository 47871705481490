<!--
Accordion Component

To add custom content on the accordion header or body, add a 'name' property to the item in the 'list' prop and
refer to the slot as #header(<name>) and #body(<name>). Scoped slot is the item itself.

eg.

<template #header(my_accordion_item_name)="item">
    <span>custom template here</span>
</template>

You can also omit passing a list prop by just inserting a 'header' and 'body' template slot with matching parameter

<template #header(my_accordion_item_name1)>
    my header
</template>
<template #body(my_accordion_item_name1)>
    my body
</template>

-->
<template>
    <div class="accordion">
        <b-card no-body v-for="(item, index) in items" :key="index">
            <b-card-header class="accordion-item__header" :header-class="item.headerContainerClass">
                <div v-b-toggle="getUid(index).toString()">
                    <slot :name="'header('+item.name+')'" v-bind="item">
                        <div :class="item.headerClass ? item.headerClass : ''">{{ item.title }}</div>
                        <icon v-if="item.icon" class="accordion-item__icon" :icon="item.icon"
                              :type="item.iconType ? item.iconType : 'regular'">
                        </icon>
                    </slot>
                </div>
            </b-card-header>
            <b-collapse :id="getUid(index)" v-model="item.visible" :accordion="getUid().toString()">
                <b-card-body>
                    <slot :name="'body('+item.name+')'" v-bind="item">
                        <b-card-text>{{ item.text }}</b-card-text>
                    </slot>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import {has, pickBy, startsWith} from "lodash";

export default {
    name: "Accordion",
    props: {
        list: {
            type: Array,
            required: false,
            validator(value) {
                return value.every((obj) => {
                    return (has(obj, 'title') && has(obj, 'text')) ||  has(obj, 'name');
                });
            }
        }
    },
    computed: {
        items() {
            if(this.list) {
                return this.list;
            }

            let headerSlots = pickBy(this.$scopedSlots, function(val,key) {
                return startsWith(key, "header");
            });

            let arrayList = [];

            if(Object.keys(headerSlots).length > 0) {
                Object.keys(headerSlots).forEach(function(key, index) {
                    const rx =  /\((.*)\)/;
                    let matches = key.match(rx);
                    if(matches[1]) {
                        arrayList.push({
                            name: matches[1]
                        });
                    }
                });
            }

            return arrayList;
        }
    },
    methods: {
        getUid(key = null) {
            if(key) {
                return 'accordion_'+ this._uid + '_' + key;
            }
            return 'accordion_'+ this._uid;
        }
    }
}
</script>

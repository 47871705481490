<template>
    <div class="fn-ui-report-switch">
        <input
            class="fn-ui-report-switch__input"
            type="checkbox"
            :id="'fn-ui-report-switch_'+_uid"
            :name="name"
            v-model="value_"
            v-on="$listeners"
            :disabled="disabled"
        >
        <label class="fn-ui-report-switch__label" :for="'fn-ui-report-switch_'+_uid"></label>
    </div>
</template>

<script>
    export default {
        name: "ReportSwitch",
        props: {
            name: String,
            value: {
                type: Boolean,
                default: false
            },
            disabled: Boolean
        },
        computed: {
            value_: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            }
        }
    };
</script>

<template>
    <vue-tel-input
        :key="`tel-input-${key}`"
        v-model="phone"
        :all-countries="allCountries_"
        :input-options="inputOptions"
        :dropdown-options="dropdownOptions"
        :disabled="disabled"
        valid-characters-only
        :auto-format="!noCountryDropdown"
        :auto-default-country="autoDefaultCountry_"
        :default-country="defaultCountry_"
        :mode="mode_"
        :class="computedClasses"
        @validate="onTelValidate"
        @country-changed="onCountryChanged"
        :invalid-msg="noCountryDropdown ? '' : invalidMsg"
    >
        <template #arrow-icon="{open}">
            <div class="ml-2">
                <b-icon-caret-up-fill v-if="open" font-scale="0.8"></b-icon-caret-up-fill>
                <b-icon-caret-down-fill v-else font-scale="0.8"></b-icon-caret-down-fill>
            </div>
        </template>
    </vue-tel-input>
</template>

<script>
    import {BIconCaretUpFill, BIconCaretDownFill} from "bootstrap-vue";
    import {VueTelInput} from "vue-tel-input";
    import "vue-tel-input/dist/vue-tel-input.css";
    import TextInput from "./TextInput";

    export default {
        name: "TelInput",
        extends: TextInput,
        props: {
            noCountryDropdown: {
                type: Boolean,
                default: false
            },
            /**
             * "auto", "international" or "national"
             */
            mode: {
                type: String,
                default: "auto"
            },
            invalidMsg: {
                type: String,
                default: "Invalid number format"
            },
            defaultCountry: {
                type: String,
                default: ""
            },
            autoDefaultCountry: {
                type: Boolean,
                default: true
            },
            allCountries: {
                type: Array,
                default() {return [];}
            }
        },
        components: {
            VueTelInput,
            BIconCaretUpFill,
            BIconCaretDownFill
        },
        data() {
            return {
                telInput: null,
                telState_: null,
                key: 0,
            };
        },
        methods: {
            isValidKey(val) {
                if (isNaN(Number(val.key)) && val.key !== "+") {
                    return val.preventDefault();
                }
            },
            onTelValidate(phoneObject) {
                if(this.state === false) {
                    this.telState = this.state;
                }
                else {
                    this.telState = phoneObject.valid;
                }

            },
            onCountryChanged(country) {
                this.phone = `+${country.dialCode}`;
            }
        },
        computed: {
            telState: {
                get: function() {
                    if(this.state === false) {
                        return this.state;
                    }
                    else {
                        return this.telState_;
                    }
                },
                set: function(newVal) {
                    if(this.state === false) {
                        return this.state;
                    }
                    else{
                        this.telState_ = newVal;
                    }
                }
            },
            phone: {
                get: function() {
                    if(this.telInput) {
                        return this.telInput;
                    }
                    else if(this.value){
                        return this.value;
                    }
                    else return "";
                },
                set: function(newVal) {
                    this.telInput = newVal;

                    let tempVal = this.value ?? "";

                    if(tempVal !== newVal) {
                        this.$emit("input", newVal);
                    }
                }
            },
            inputOptions() {
                return {
                    id: this.id,
                    name: this.name,
                    type: "tel",
                    placeholder: this.placeholder,
                    autocomplete: this.autocomplete,
                    autofocus: this.autofocus,
                    readonly: this.readonly,
                    required: this.required,
                    maxlength: this.maxlength,
                    styleClasses: {
                        "form-control":true,
                        "is-valid": this.telState,
                        "is-invalid": this.telState === false,
                        "rounded" : this.noCountryDropdown
                    }
                };
            },
            dropdownOptions() {
                return {
                    showDialCodeInList: true,
                    showDialCodeInSelection: false,
                    showFlags: true,
                    showSearchBox: (this.allCountries_.length > 10),
                };
            },
            computedClasses() {
                return {
                    "border" : true,
                    "rounded" : true,
                    "bg-white": true,
                    "border-success" : this.telState,
                    "border-danger" : this.telState === false,
                    "no-dropdown" : this.noCountryDropdown
                };
            },
            defaultCountry_() {
                if(this.data["defaultCountry"]) {
                    return this.data["defaultCountry"];
                }
                else if(this.defaultCountry) {
                    return this.defaultCountry;
                }
                else {
                    return this.noCountryDropdown ? "none" : "";
                }
            },
            autoDefaultCountry_() {
                if(this.defaultCountry_ && this.autoDefaultCountry === false){
                    return false;
                }
                else {
                    return !this.noCountryDropdown;
                }
            },
            mode_() {
                if(this.data["mode"]) {
                    return this.data["mode"];
                }
                if(this.mode){
                    return this.mode;
                }
                else {
                    return "auto";
                }
            },
            allCountries_() {
                if(this.data["allCountries"]) {
                    return this.data["allCountries"];
                }
                if(this.allCountries.length){
                    return this.allCountries;
                }
                else {
                    return [];
                }
            }
        },
        watch: {
            defaultCountry_: {
                handler(newValue) {
                    this.key++;

                    const country = this.allCountries_.find(country => country.iso2 === newValue);

                    if (country) {
                        const prefix = `+${country.dialCode}`;

                        if (!this.phone.startsWith(prefix)) {
                            this.phone = "";
                        }
                    }
                }
            }
        }
    };
</script>

<style lang="scss">
    .vue-tel-input {
        width: 100%;
        position: relative;

        input {
            border: none;
        }
        .vti__dropdown-list {
            z-index: 2;
            width: auto;
            right: 0;
        }
        .vti__dropdown {
            position: initial;
            background-color: transparent !important;
        }
        .vti__search_box {
            border: 1px solid #ccc!important;
            border-radius: 2px;
            padding: 5px 15px;
            margin: 10px;
        }
    }
    .vue-tel-input.no-dropdown {
        .vti__dropdown {
            display: none;
        }
    }
</style>

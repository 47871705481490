import { render, staticRenderFns } from "./AddCardScreen.vue?vue&type=template&id=76d5b654&scoped=true&"
import script from "./AddCardScreen.vue?vue&type=script&lang=js&"
export * from "./AddCardScreen.vue?vue&type=script&lang=js&"
import style0 from "./AddCardScreen.vue?vue&type=style&index=0&id=76d5b654&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d5b654",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\takaj\\Documents\\COFOUNDRY\\okinus-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76d5b654')) {
      api.createRecord('76d5b654', component.options)
    } else {
      api.reload('76d5b654', component.options)
    }
    module.hot.accept("./AddCardScreen.vue?vue&type=template&id=76d5b654&scoped=true&", function () {
      api.rerender('76d5b654', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/profile/screens/AddCardScreen.vue"
export default component.exports
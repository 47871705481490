<template>
    <component :is="tag" v-bind="{variant: $props.variant, size: $props.size, disabled: disabled}" v-on="$listeners" :title="tooltip" class="fn-ui-button align-items-center">
        <icon v-if="icon" :icon="icon" aria-hidden="true"></icon>
        <slot>
            <span v-if="text" class="fn-ui-button-label">{{text}}</span>
        </slot>
        <span v-if="submitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    </component>
</template>

<script>

    import {BButton} from "bootstrap-vue";

    export default {
        name: "Button",
        components: {
            BButton
        },
        props: {
            title: String,
            size: {
                type: String,
                default() {
                    return "";
                }
            },
            tag: {
                type: [String, Object],
                default: "b-button"
            },
            disabled: Boolean,
            submitting: Boolean,
            variant: {
                type: String,
                default() {
                    return "";
                }
            },
            icon: {
                type: [String, Boolean],
                default() {
                    return false;
                }
            },
            text: {
                type: [String, Boolean],
                default() {
                    return false;
                }
            },
            spinnerClass: {
                type: String,
                default: () => "spinner-grow spinner-grow-sm"
            },
            tooltip: {
                type: String
            }
        }
    };
</script>

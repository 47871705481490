var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-details-card" }, [
    _c("div", { staticClass: "payment-status" }, [
      _vm._m(0),
      _c("p", { staticClass: "amount" }, [_vm._v("$" + _vm._s(this.amount))]),
    ]),
    _c("div", { staticClass: "payment-details" }, [
      _c("div", { staticClass: "detail-item" }, [
        _c("strong", [_vm._v("Payment Date:")]),
        _vm._v(" " + _vm._s(_vm.paymentDate)),
      ]),
      _c("div", { staticClass: "detail-item" }, [
        _c("strong", [_vm._v("Payment Time:")]),
        _vm._v(" " + _vm._s(_vm.paymentTime)),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "detail-item" }, [
        _vm._v(_vm._s(_vm.$t("We have emailed you your receipt."))),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _vm._m(2),
        _c(
          "fn-ui-button",
          {
            staticClass: "btn-block",
            attrs: { variant: "outline-primary" },
            on: { click: _vm.goToRegister },
          },
          [_vm._v(_vm._s(_vm.$t("Create Account")))]
        ),
        _c(
          "fn-ui-button",
          {
            staticClass: "btn-block",
            attrs: { variant: "primary" },
            on: { click: _vm.goToLogin },
          },
          [_vm._v(_vm._s(_vm.$t("Login")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [
      _c("i", { staticClass: "fas fa-check-circle" }),
      _vm._v(" Payment Successful"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "detail-item" }, [
      _c("strong", [_vm._v("Payment Method:")]),
      _vm._v("Card"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "account-prompt" }, [
      _c("p", [
        _vm._v(
          "We notice you might not have an account yet, click Create Account to create your account so that you can easily manage your leases through our Customer Portal."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
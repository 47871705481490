<template>
    <b-tab :title="tabTitle" :title-item-class="{'right-tab': alignRight }" :active="active" :lazy="lazy" :disabled="disabled" v-on="$listeners">
        <template #title>
            <slot name="tab-title"></slot>
        </template>
        <slot></slot>
    </b-tab>
</template>

<script>
    export default {
        name: "CardTabItem",
        props:{
            tabTitle: String,
            alignRight: {
                type: Boolean,
                default: false
            },
            active : {
                type: Boolean,
                default: false
            },
            lazy : {
                type: Boolean,
                default: false
            },
            disabled : {
                type: Boolean,
                default: false
            },
        }
    };
</script>

<template>
    <b-form-input type="text"
                  :id="id"
                  :name="name"
                  v-model="model"
                  placeholder="00:00:00"
                  :class="className"
                  :readonly="readonly"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :required="required"
                  :state="state"
                  :size="size"
                  v-on="$listeners"
                  @keypress="isValidKey"
                  @click="setCursorToEnd"
                  @blur="validateTime"
                  :formatter="formatter"
                  ref="inputRef"
    ></b-form-input>
</template>

<script>
    import {BFormInput} from "bootstrap-vue";

    import input from "../mixins/input";

    export default {
        name: "TimecodeInput",
        mixins: [
            input,
        ],
        props: {
            minTime: Number,
            maxTime: Number,
            limitHours: {
                type: Boolean,
                default: true
            },
            cursorEnd: {
                type: Boolean,
                default: true
            }
        },
        components: {
            BFormInput
        },
        computed: {
            model: {
                get: function() {
                    if(this.value) {
                        return this.value;
                    }

                    return "00:00:00";
                },

                set: function(newVal) {
                    this.$emit("input", newVal);
                }
            }
        },
        methods: {
            formatter(value) {
                //remove colon
                value = value.replaceAll(":", "");

                //remove zeroes to the left
                value = parseInt(value, 10).toString();

                //add zeroes to the left if less than 6 digits
                if(value.length < 6) {
                    value = value.padStart(6,"0");
                }

                let timeCode = this.toTimeCodeFormat(value);

                return timeCode;
            },

            toTimeCodeFormat(string) {

                let sec = isNaN(string.slice(-2)) ?  "00" : string.slice(-2);

                let min = isNaN(string.slice(-4, -2)) ? "00" : string.slice(-4, -2);

                let hr;

                if(this.limitHours) {
                     hr = string.slice(-6, -4) ?? "00";
                }
                else {
                    hr = string.slice(0, -4) ?? "00";
                }

                return hr + ":" + min + ":" + sec;
            },

            isValidKey(val) {
                if (isNaN(Number(val.key))) {
                    return val.preventDefault();
                }
            },

            setCursorToEnd() {
                if(this.cursorEnd) {
                    let input = this.$refs["inputRef"];
                    input.selectionStart = input.selectionEnd = 99999;
                }
            },

            timeToSeconds(time) {
                time = time.split(":");

                return parseInt(time[0]) * 60 * 60 + parseInt(time[1]) * 60 + parseInt(time[2]);
            },

            validateTime() {
                let seconds = this.timeToSeconds(this.model);

                if (seconds > this.maxTime) {
                    seconds = this.maxTime;
                } else if (seconds < this.minTime) {
                    seconds = this.minTime;
                }

                this.model = this.secondsToTime(seconds);
            },

            secondsToTime(seconds) {
                const _seconds = parseInt(seconds);
                return new Date(_seconds * 1000).toISOString().substr(11, 8);
            }
        },

        watch: {
            maxTime() {
                this.validateTime();
            },
            minTime() {
                this.validateTime();
            }
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Bar", {
    attrs: {
      "chart-data": _vm.data,
      "chart-options": _vm.chartOptions,
      "chart-id": "bar-chart",
      "dataset-id-key": "label",
      width: 600,
      height: 400,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
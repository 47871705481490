<template>
    <div class="fn-filters">
        <form class="fn-filters__form" @submit.prevent="applyFilter">
            <div class="fn-filters__controls">
                <div v-if="groups && groups.length > 0" class="fn-filters__group">
                    <b-form-select ref="group" v-model="group" @change="onGroupChange">
                        <b-form-select-option :value="null">{{$t("Source...")}}</b-form-select-option>
                        <b-form-select-option v-for="group in groups" :key="group.name" :value="group.name">{{group.label}}</b-form-select-option>
                    </b-form-select>
                </div>
                <div v-if="inputs && inputs.length > 0" class="fn-filters__input">
                    <b-form-select ref="input" v-model="input" @change="onInputChange">
                        <b-form-select-option :value="null">{{$t("Filter By...")}}</b-form-select-option>
                        <b-form-select-option v-for="input_ in inputs" :key="input_.name" :value="input_.name">{{input_.label || input_.statement}}</b-form-select-option>
                    </b-form-select>
                </div>
                <div v-if="control" class="fn-filters__control">
                    <fn-form-schema>
                        <template>
                            <div><fn-form-group :schema="control" v-model="value" no-label :key="control.name"></fn-form-group></div>
                        </template>
                    </fn-form-schema>
                </div>
            </div>
            <div class="fn-filters__buttons form-group">
                <b-button v-if="control" variant="outline-secondary" type="button" @click.prevent="() => clearCurrentFilter(control.name)"><icon icon="times"></icon></b-button>
                <b-button v-if="control" class="ml-1" variant="outline-primary" type="submit" :disabled="disabled"><icon icon="search"></icon></b-button>

                <b-dropdown
                    class="ml-1" no-caret
                    toggle-class="bg-white"
                    offset="-100"
                    v-b-tooltip.hover.topleft="'Saved Filters'"
                    @show="fetchSavedFilters"
                >
                    <template #button-content>
                        <icon-layers>
                            <icon class="m-0" icon="filter"></icon>
                            <icon icon="usd-circle" transform="shrink-3 right-8 down-4"/>
                        </icon-layers>
                    </template>

                    <b-dropdown-item v-if="!saved_filters" class="font-italic" disabled>{{ $t('Loading') }}...</b-dropdown-item>
                    <template v-if="saved_filters && saved_filters.length > 0">
                        <b-dropdown-item v-for="(filter,index) in saved_filters" :key="index" @click="activateFilters(filter.input_values)">{{ filter.name }}</b-dropdown-item>
                    </template>
                    <b-dropdown-item v-else-if="saved_filters" class="font-italic" disabled>{{ $t('No Saved Filters') }}</b-dropdown-item>

                </b-dropdown>
            </div>
        </form>
        <div class="fn-filters__active-filters" v-if="active.length > 0">
            <template v-for="filter in active">
                <b-badge :key="filter.name" variant="primary" class="filter-badge">
                    <span class="filter-name" @click="() => loadFilter(filter)">{{filter.input.label || filter.input.statement}}: {{ getFilterValue(filter) }}</span>
                    <span class="remove-filter" @click="() => removeFilter(filter.name)"><icon icon="times"></icon></span>
                </b-badge>
            </template>

           <span class="float-right mb-2 mr-n2">
               <fn-panel-button
                   v-if="filters.name"
                   v-can="`system.filters.add`"
                   variant="outline-secondary"
                   :panel="addFilterPanel"
                   @success="saveFilterSuccess"
               >
                   <icon icon="save"></icon>
               </fn-panel-button>

               <fn-panel-button
                   class="ml-1"
                   v-if="filters.name"
                   v-can="`system.filters.global.add`"
                   variant="outline-secondary"
                   :panel="addGlobalFilterPanel"
                   @success="saveFilterSuccess"
               >
                   <icon-layers>
                        <icon icon="save" />
                        <icon icon="globe" transform="shrink-3 right-8 down-8"/>
                    </icon-layers>
               </fn-panel-button>
           </span>
        </div>
    </div>
</template>

<script>

    import {find, get, merge, set, uniqueId} from "lodash";
    import {FnUiPanelForm} from "@fndry-vue/fn-ui";

export default {
    name: "Filters",
    props: {
        filters: {
            type: Object,
            required: true
        },
        apiBaseUrl: {
            type: String,
            default: '/api'
        }
    },
    data(){
        return {
            group: null,
            input: null,
            control: null,
            value: undefined,

            saved_filters:null,
        };
    },
    computed: {
        disabled(){
            return this.input == null || this.control == null || this.value == undefined;
        },
        groups(){
            return this.$fnFilters.getFilterGroups(this.name);
        },
        inputs(){
            return this.$fnFilters.getFilterInputs(this.name, this.group);
        },
        values(){
            return this.$fnFilters.getFilterValues(this.name);
        },
        active(){
            return this.$fnFilters.getActiveFilters(this.name);
        },
        addFilterPanel() {
            return {
                component: FnUiPanelForm,
                overlay: true,
                props: {
                    uri: `${this.apiBaseUrl}/system/filters/add`,
                    title: this.$t("Save Filter"),
                    uriParams: {
                        filters_class_name : this.name,
                        input_values : this.values,
                    },
                }
            };
        },
        addGlobalFilterPanel() {
            return {
                ...this.addFilterPanel,
                props:{
                    ...this.addFilterPanel.props,
                    uri:`${this.apiBaseUrl}/system/filters/global-add`
                }
            };
        },
        name() {
            return this.filters.name;
        },
    },
    created() {
        this.$fnFilters.createFilter(this.name, this.filters.filters);
    },
    destroyed() {
        this.$fnFilters.destroyFilter(this.name);
    },
    methods: {
        applyFilter(){
            let values = this.values;
            set(values, this.input, this.value);
            this.$fnFilters.setFilterValues(this.name, values);
            this.$nextTick(() => {
                this.$emit("filter", this.values);
            });
        },
        loadFilter(filter){
            this.group = filter.input.group;
            this.input = filter.input.name;
            this.control = filter.input;
            this.value = filter.rawValue;
        },
        removeFilter(inputName){
            this.$fnFilters.clearFilterValue(this.name, inputName);
            this.$nextTick(() => {
                this.$emit("filter", this.values);
            });
        },
        clearCurrentFilter(inputName){
            this.value = undefined;
            // this.removeFilter(inputName);
        },
        onGroupChange(){
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
        onInputChange(){
            this.$nextTick(() => {
                this.controlFocus();
            });
        },
        controlFocus(){
            if (document.getElementById(this.control.id)) {
                document.getElementById(this.control.id).focus();
            }
        },
        saveFilterSuccess() {
            this.$fnNotify(this.$t("Active Filters Saved"));
        },
        activateFilters(values) {
            this.active.forEach((item) => {
                this.$fnFilters.clearFilterValue(this.name, item.name);
            });

            this.$fnFilters.setFilterValues(this.name, values);
            this.$nextTick(() => {
                this.$emit("filter", this.values);
            });
        },
        async fetchSavedFilters() {
            this.saved_filters = null;
            let response = await this.$fnApi.call(`${this.apiBaseUrl}/system/filters`, "GET", {
                input_class: this.name
            });

            if (response.status) {
                this.saved_filters = response.data;
            }
        },
        getFilterValue(filter) {
            if(["select", "checkboxes"].includes(filter.input.type)) {
                let options = filter.input.options;

                let valueKey = filter.input?.valueKey ?? "value";
                let textKey = filter.input?.textKey ?? "text";

                let value = filter.value;
                let value_ = [];

                if(Array.isArray(value)) {
                    value.forEach((item) => {
                        let found = options.find((option) => {
                            return option[valueKey] === item;
                        });

                        if(found) {
                            value_.push(found[textKey]);
                        }
                    });
                    return value_;
                }

                else {
                    let found = options.find((option) => {
                        return option[valueKey] === value;
                    });

                    if(found) {
                        return found[textKey];
                    }
                }

            } else {
                return filter.value;
            }
        }
    },
    watch: {
        input: function(newVal, oldVal){
            if (newVal !== oldVal) {
                if (newVal !== null) {
                    let control = find(this.inputs, (input) => input.name === newVal);
                    this.control = {
                        ...control,
                        label: undefined,
                        placeholder: this.$t("Search by") + " " + control.label,
                    };
                    this.value = get(this.values, newVal);
                } else {
                    this.control = null;
                    this.value = undefined;
                }
            }
        }
    }
};
</script>

<style scoped>

</style>

<template>
    <div class="fn-ui-layout-column" :class="[mode]">
        <div v-if="$slots['header']" class="fn-ui-layout-column__header">
            <slot name="header"></slot>
        </div>
        <div class="fn-ui-layout-column__wrapper">
            <div class="fn-ui-layout-column__body">
                <slot></slot>
            </div>
            <div v-if="$slots['footer']" class="fn-ui-layout-column__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutColumn",
        props: {
            mode: {
                type: String,
                validator: (value) => {
                    return ["compact", "expanded", "responsive"].indexOf(value) !== -1;
                },
                default: "expanded",
            }
        }
    };
</script>

<template>
    <div class="fn-types-password-input">
        <b-input-group>
            <b-form-input :type="type"
                          :id="id"
                          :name="name"
                          :value="value"
                          :placeholder="placeholder"
                          :disabled="disabled"
                          :autocomplete="autocomplete"
                          :autofocus="autofocus"
                          :min="minLength"
                          :max="maxLength"
                          :required="required"
                          :state="state"
                          :size="size"
                          v-on="$listeners"
                          @update="checkPassword"
            ></b-form-input>
            <b-input-group-append class="input-group-append" @click="toggleVisible" data-password="false" is-text>
                <icon v-if="hidden" icon="eye"></icon>
                <icon v-else icon="eye-slash"></icon>
            </b-input-group-append>
        </b-input-group>
        <template v-if="showMeter">
            <b-progress v-if="meterValue > 0" class="password-meter" :value="meterValue" :height="meterHeight" max="100" :variant="barColor"></b-progress>
            <small v-if="passwordGuide && meterValue > 0 && meterValue < 100 ">{{ passwordGuide }}</small>
        </template>
    </div>
</template>

<script>

    import {BFormInput, BProgress, BInputGroup, BInputGroupAppend} from 'bootstrap-vue';

    import TextInput from "./TextInput";

    export default {
        name: "PasswordInput",
        extends: TextInput,
        components: {
            BFormInput,
            BInputGroup,
            BProgress,
            BInputGroupAppend
        },
        props: {
            showMeter: {
                type: Boolean,
                default: false
            },
            meterHeight: {
                type: String,
                required: false,
                default: "10px"
            },
            passwordStrengthRules: {
                type: Array,
                default: () => [
                    {
                        pattern: "^(?=.*[a-z])",
                        string: "Password should contain a lowercase character.",
                        factor: 1
                    },
                    {
                        pattern: "^(?=.*[A-Z])",
                        string: "Password should contain an uppercase character.",
                        factor: 2
                    },
                    {
                        pattern: "^(?=.*[0-9])",
                        string: "Password should contain a numeric character.",
                        factor: 2
                    },
                    {
                        pattern: "^(?=.*[!@#$%^&*])",
                        string: "Password should contain a special character.",
                        factor: 2
                    },
                    {
                        pattern: "^(?=.{8,})",
                        string: "Password should be 8 characters or longer.",
                        factor: 3
                    }
                ]
            }
        },
        data() {
            return {
                meterValue: 0,
                totalFactor: 0,
                passwordGuide: "",
                hidden: true
            };
        },
        computed: {
            barColor() {
                if(this.meterValue > 75 ) {
                    return "success";
                }
                else if(this.meterValue > 50) {
                    return "info";
                }
                else if(this.meterValue > 25) {
                    return "warning";
                }
                else {
                    return "danger";
                }
            },
            type: function(){
                return (this.hidden) ? 'password' : 'text';
            }
        },
        mounted() {
            this.getTotalFactor();
            this.checkPassword(this.value);
        },
        methods: {
            toggleVisible() {
                this.hidden = !this.hidden;
            },
            checkPassword(value) {
                let meterValue = 0;
                if (value) {
                    this.passwordStrengthRules.forEach((item) => {
                        if(value.match(item.pattern)) {
                            meterValue += (item.factor/this.totalFactor) * 100;
                        }
                    });
                    this.getRuleNotMatched(value);
                }
                this.meterValue = meterValue;
            },
            getTotalFactor() {
                this.passwordStrengthRules.forEach((item) => {
                    this.totalFactor += item.factor;
                });
            },
            getRuleNotMatched(value) {
                this.passwordStrengthRules.forEach((item) => {
                    if(!value.match(item.pattern)) {
                        this.passwordGuide = item.string;
                    }
                });
            }
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-error", [
    _c("div", { staticClass: "error-content" }, [
      _c("h3", { staticClass: "text-white mt-3" }, [
        _vm._v(_vm._s(_vm.$t("Permission Denied"))),
      ]),
      _c("h4", { staticClass: "text-secondary mt-2" }, [
        _vm._v(_vm._s(_vm.$t("Error code 403"))),
      ]),
      _c("p", { staticClass: "text-muted mt-3" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "It's looking like you may not have access to that. Don't worry... you can talk to the system admin to assist you with the permission should you really need it."
            )
          ) + " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.authKey,
      staticClass: "d-flex flex-column h-100 justify-content-around",
    },
    [
      _c("div", [
        _c("h4", { staticClass: "text-left text-primary" }, [
          _vm._v(_vm._s(_vm.$t("Welcome back!"))),
        ]),
      ]),
      _c("div", { staticClass: "mt-auto" }, [
        _c(
          "div",
          { staticClass: "mt-3" },
          [
            _c("fn-form", {
              attrs: {
                "no-autoload": "",
                "no-buttons": "",
                uri: _vm.formUri,
                "uri-params": _vm.formUriParams,
                "uri-method": "POST",
              },
              on: { success: _vm.onSuccess, fail: _vm.onFail },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({
                    loading,
                    loaded,
                    submitting,
                    submit,
                    flags,
                  }) {
                    return [
                      _c(
                        "fn-form-schema",
                        [
                          _c("fn-form-group", {
                            attrs: { schema: _vm.inputs.email },
                          }),
                          _c("fn-form-group", {
                            attrs: { schema: _vm.inputs.password },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "end",
                                  fn: function () {
                                    return [
                                      _c(
                                        "router-link",
                                        { attrs: { to: _vm.forgotRoute } },
                                        [
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("Forgot your password?")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "fn-ui-button",
                            {
                              staticClass: "btn-block btn-rounded",
                              attrs: {
                                variant: "primary",
                                disabled: !flags.valid || loading || submitting,
                                submitting: submitting,
                                type: "submit",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Log In")) + " ")]
                          ),
                          _c(
                            "fn-ui-button",
                            {
                              staticClass: "btn-block btn-rounded",
                              attrs: { variant: "primary", type: "button" },
                              on: { click: _vm.goToVerifyMyIdentity },
                            },
                            [_vm._v(_vm._s(_vm.$t("Pay As Guest")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 pointer" },
          [
            _c(
              "router-link",
              {
                staticClass: "text-dark font-14",
                attrs: { to: _vm.registerRoute },
              },
              [_vm._v(" " + _vm._s(_vm.$t("Or Create My Account")) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-report-range-selector-slider">
        <div ref="slider"
             id="fn-ui-report-range-selector-slider__slider"
             class="fn-ui-report-range-selector-slider__slider"
        ></div>
    </div>
</template>

<script>
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

export default {
    name: "ReportRangeSelectorSlider",
    props: {
        dates: {
            type: Array,
            required: true
        },
        startIndex: {
            type: Number,
        },
        endIndex: {
            type: Number,
        },
        maxColumns: {
            type: Number,
            default: 12
        }
    },
    computed: {
        range() {
            let object = {
                "min": 0,
                "max": this.dates.length - 1
            };
            return object;
        },
        sliderOptions() {
            return {
                start: [this.startIndex, this.endIndex],
                behaviour: "drag",
                step: 1,
                margin: 3,
                limit: this.maxColumns - 1,
                connect: true,
                range: {
                    "min": 0,
                    "max": this.dates.length - 1
                },
            };
        }
    },
    mounted() {
        this.initSlider();
    },
    watch: {
        dates(newVal, oldVal) {
            if (newVal != oldVal) {
                this.$refs.slider.noUiSlider.updateOptions({
                    range: this.range
                });
            }
        }
    },
    methods: {
        initSlider() {
            noUiSlider.create(this.$refs.slider, this.sliderOptions);

            this.$refs.slider.noUiSlider.on("change", ([val1, val2]) => {
                this.$emit("change", {
                    fromIndex: parseInt(val1),
                    toIndex: parseInt(val2)
                });
            });
        }
    }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-container",
      class: { "menu-open": _vm.primaryColumn?.show },
    },
    [
      _c(
        "fn-ui-layout",
        { staticClass: "primary-menu-column" },
        [
          _c(
            "fn-ui-primary-menu-column",
            {
              attrs: { compact: false, show: _vm.primaryColumn?.show },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [_vm._t("header-content")]
                    },
                    proxy: true,
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "p-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn btn-block btn-primary apply-btn",
                              on: { click: _vm.redirectToOkinusCreditOptions },
                            },
                            [_vm._v(_vm._s(_vm.$t("Apply Now")))]
                          ),
                        ]),
                        _c("hr"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "fn-ui-layout-column-body px-3 mb-3 font-12 text-muted",
                          },
                          [
                            _c("div", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.footerCompanyText())),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "147 W Railroad St S, Pelham, GA 31779, USA"
                                  )
                                )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("Phone")) +
                                  ": " +
                                  _vm._s(_vm.phoneNumber())
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("Email")) +
                                  ": " +
                                  _vm._s(_vm.emailAdrress())
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Working Hours: Monday – Friday 9 AM – 8 PM"
                                  )
                                )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Saturday – 10 AM – 8 PM Sunday 1 PM – 7 PM"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c(
                "div",
                { staticClass: "fn-ui-layout-column-body" },
                [
                  _vm.mainMenu.length
                    ? _c("fn-ui-layout-navigation-list", {
                        attrs: {
                          compact: false,
                          items: _vm.mainMenu,
                          "menu-collapsed": false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.primaryColumn?.show,
            expression: "primaryColumn?.show",
          },
        ],
        staticClass: "primary-column-overlay",
        on: {
          click: function ($event) {
            return _vm.toggleLayoutColumnShow(_vm.primaryMenu)
          },
        },
      }),
      _c("fn-ui-layout", [
        _c(
          "div",
          { staticClass: "fn-layout__screen w-100 position-relative" },
          [
            _c(
              "ok-customer-screen",
              _vm._b(
                { class: _vm.screenClass, attrs: { title: _vm.screenTitle } },
                "ok-customer-screen",
                _vm.screenState.data,
                false
              ),
              [_c("router-view")],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="fn-ui-report-menu-summary-section">
        <div class="fn-ui-report-menu-summary-section__header">
            <div class="fn-ui-report-menu-summary-section__title-wrapper">
                <span class="fn-ui-report-menu-summary-section__title">{{title}}</span>
<!--                <span class="fn-ui-report-menu-summary-section__reset" @click="handleReset">Reset to default</span>-->
            </div>
            <div class="fn-ui-report-menu-summary-section__text">
                {{text}}
            </div>
        </div>
        <div class="fn-ui-report-menu-summary-section__body">
            <slot></slot>
        </div>
        <div v-if="legend" class="fn-ui-report-menu-summary-section__legend">
            {{ legend }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuSummarySection",
        props: {
            title: String,
            text: String,
            legend: String,
        },
        methods: {
            handleReset() {
                this.$emit("reset");
            }
        }
    };
</script>

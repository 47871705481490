<template>
    <b-table
        class="fn-ui-data-table"
        ref="table"
        :items="items"
        :fields="visibleFields"
        :sort-icon-left="sortIconLeft"
        :hover="hover"
        :striped="striped"
        :bordered="bordered"
        :outlined="outlined"
        :small="small"
        :selectable="rowSelectable"
        selected-variant=""
        @row-selected="handleRowSelect"
        :no-local-sorting="noLocalSorting"
        @sort-changed="handleSortChange"
        details-td-class="details-row container p-0"
        :responsive="responsive"
        :class="{'show-dropdown': (responsive && collapsedFields.length > 0)}"
        v-on="$listeners">

        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
        <template v-slot:cell(checkbox_)="{rowSelected, selectRow, unselectRow}">
            <b-checkbox :checked="rowSelected" @change="(value) => handleCheckboxChange(value, selectRow, unselectRow)"/>
        </template>

        <template v-slot:cell(dropdown_toggle_)="{item, toggleDetails}">
        <span class="pointer user-select-none" @click="toggleDetails" v-if="collapsedFields.length > 0">
            <icon :icon="item._showDetails ? 'chevron-up' : 'chevron-down'"></icon>
        </span>
        </template>

        <template #row-details="row">
            <div class="row m-0" v-for="(col,index) in collapsedFields" :key="index">
                <span class="col-6 text-left border font-weight-semibold text-wrap">{{ col.colName }}</span>
                <span class="col-6 text-left border">
                    <template v-if="$scopedSlots['cell('+col.colKey+')']">
                        <slot :name="'cell('+col.colKey+')'" v-bind="row"></slot>
                    </template>
                    <template v-else>
                        {{ row.item[col.colKey] }}
                    </template>
                </span>

            </div>
        </template>
    </b-table>
</template>

<script>
    import {fnUiTableCardColumnSelectableMixin} from "@fndry-vue/fn-ui";
    import resizeObserverMixin from "../../mixins/resizeObserverMixin";

    import {findIndex, cloneDeep, difference} from "lodash";

    export default {
        name: "DataTable",
        mixins: [
            fnUiTableCardColumnSelectableMixin,
            resizeObserverMixin,
        ],
        props: {
            items: {
                type: Array,
                default(){return [];}
            },
            fields: {
                type: Array,
                default(){return [];}
            },
            rowSelectable: {
                type: Boolean,
                default: false
            },
            selectAllRows: Boolean,
            hover: Boolean,
            striped: Boolean,
            bordered: Boolean,
            outlined: Boolean,
            small: Boolean,
            responsive: Boolean,
            sortIconLeft: Boolean,
            noLocalSorting: Boolean,
        },
        data() {
            return {
                collapsedFields: [],
            };
        },
        computed: {
            computedFields() {
                if(this.fields.length > 0) {
                    let fields = cloneDeep(this.fields);

                    if(this.collapsedFields.length > 0) {
                        this.collapsedFields.forEach((item) => {
                            fields.splice(item.index);
                        });
                    }

                    if(this.rowSelectable) {
                        fields.unshift({
                          key: 'checkbox_',
                          label: '',
                          sortable: false,
                          selectable: false,
                          selected:true,
                          tdClass: 'pl-5',
                        });
                    }

                    if(this.responsive && this.collapsedFields.length > 0) {
                        fields.push({
                            key: 'dropdown_toggle_',
                            label: '',
                            sortable: false,
                            selectable: false,
                            selected:true,
                            class: 'dropdown-details-toggle',
                        });
                    }
                    return fields;
                }

                return this.fields;
            },
            rowCount() {
                return this.items.length;
            },
        },
        methods: {
            handleSortChange(ctx){
                this.$emit('sort', ctx);
            },
            handleRowSelect(rows) {
                let areAllRowsSelected = rows.length === this.rowCount;
                this.$emit('selected-rows', {rows, areAllRowsSelected})
            },
            handleCheckboxChange(value, select, unselect) {
                if(value) {
                    select();
                } else {
                    unselect();
                }
            }
        },
        watch: {
            selectAllRows(val) {
                if(val) {
                    this.$refs.table.selectAllRows();
                } else {
                    this.$refs.table.clearSelected();
                }
            },
            elemWidth(elementWidth) {
                if(this.items.length > 0 && this.fields.length > 0) {
                    let currentRowWidth = 0;
                    this.collapsedFields = [];

                    this.$nextTick(()=>{
                        let rowHeader = this.$el.querySelectorAll("th[role='columnheader']:not(.dropdown-details-toggle)");
                        let colIndexArr = [];

                        for (let i = 0; i < rowHeader.length; i++) {
                            if(elementWidth+10 < (currentRowWidth + rowHeader[i].getBoundingClientRect().width)) {
                                break;

                            } else {
                                currentRowWidth += rowHeader[i].getBoundingClientRect().width;
                                colIndexArr.push(i);
                            }

                        }

                        let collapsedFields = [];

                        this.fields.forEach((item,index) => {
                            if(!colIndexArr.includes(index)) {
                                collapsedFields.push({
                                    index: index,
                                    colName: item.label,
                                    colKey: item.key
                                });
                            }
                        });

                        this.collapsedFields = collapsedFields;
                    });
                }
            }
        }
    };
</script>

<template>
    <div class="fn-ui-layout-column__content">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "LayoutColumnContent"
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fn-form", {
    ref: "fnForm",
    attrs: { "no-autoload": "", "no-buttons": "", uri: _vm.formUri },
    on: { success: _vm.onSuccess, fail: _vm.onFail, cancel: _vm.handleCancel },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ loading, loaded, submitting, submit, flags, cancel }) {
          return [
            _c(
              "fn-form-schema",
              [
                _c("fn-form-group", {
                  attrs: { schema: _vm.inputs.current_password },
                }),
                _c("fn-form-group", { attrs: { schema: _vm.inputs.password } }),
                _c("fn-form-group", {
                  attrs: { schema: _vm.inputs.password_confirmation },
                }),
                _c(
                  "fn-ui-button",
                  {
                    staticClass: "btn-block",
                    attrs: {
                      variant: "outline-primary",
                      disabled: !flags.valid || loading || submitting,
                      submitting: submitting,
                      type: "submit",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Change Password")) + " ")]
                ),
                _c(
                  "fn-ui-button",
                  {
                    staticClass: "btn-block",
                    attrs: { variant: "outline-primary" },
                    on: { click: cancel },
                  },
                  [_vm._v(_vm._s(_vm.$t("Cancel")))]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v
      },
      expression: "form",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="card card-layout">
        <card-header v-if="withHeader" :with-dropdown="withDropdown" :class="headerClass">
            <slot name="header"></slot>
            <template #dropdown>
                <slot name="dropdown"></slot>
            </template>
        </card-header>
        <card-body :class="bodyClass">
            <slot></slot>
        </card-body>
        <card-footer  v-if="withFooter" :class="footerClass">
            <slot name="footer"></slot>
        </card-footer>
    </div>
</template>

<script>
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";

export default {
        name: "CardLayout",
        components: {CardBody, CardHeader, CardFooter},
        props: {
            withHeader: {
                type: Boolean,
                default: false
            },
            withDropdown: {
                type: Boolean,
                default: false
            },
            headerClass : [String, Array],
            bodyClass : [String, Array],
            footerClass : [String, Array],
            withFooter: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<template>
    <b-form-tags
        :input-id="id"
        :value="value"
        :name="name"
        :placeholder="placeholder"
        :tag-variant="tagVariant"
        :required="required"
        :state="state"
        :size="size"
        :disabled="disabled"
        :readonly="readonly"
        v-on="$listeners"
        :separator="separator"
        :limit="limit"
        :tag-pills="tagPills"
        :no-tag-remove="noTagRemove"
        no-add-on-enter
        add-on-change
    ></b-form-tags>
</template>

<script>
    import {BFormTags} from 'bootstrap-vue';
    import input from "../mixins/input";

    export default {
        name: "TagInput",
        components: {
            BFormTags
        },
        mixins: [
            input,
        ],
        props: {
            tagVariant: String,
            tagPills: Boolean,
            separator: String,
            limit: Number,
            noTagRemove: Boolean
        }
    };
</script>

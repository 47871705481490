<template>
    <div class="fn-ui-report-header">
        <div class="fn-ui-report-header__heading">
            <div class="fn-ui-report-header__title">{{ title }}</div>
            <slot name="header" v-bind="{subtitle}"><span class="fn-ui-report-header__subtitle" v-if="subtitle">{{subtitle}}</span></slot>
        </div>
        <div class="fn-ui-report-header__buttons">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportHeader",
        props: {
            title: String,
            subtitle: String
        }
    };
</script>

<template>
    <div class="fn-ui-layout-column-header">
        <template v-if="icon">
            <div class="fn-ui-layout-column-header__icon fn-ui-layout-column-header__icon--compact pointer" @click.prevent="toggleCompact">
                <icon :icon="icon" size="2x" :class="{'hidden': !compact}"></icon>
                <icon :icon="iconCompact ? iconCompact : icon" size="2x" :class="{'hidden': compact}"></icon>
            </div>
        </template>
        <div class="fn-ui-layout-column-header__title" v-show="!compact">
            <slot>{{ title }}</slot>
        </div>
        <div v-if="closeable" class="fn-ui-layout-column-header__close" @click="toggleClose">
            <icon icon="times" size="2x" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutColumnHeader",
        props: {
            title: String,
            icon: String,
            iconCompact: String,
            compact: Boolean,
            closeable: Boolean
        },
        methods: {
            toggleCompact() {
                this.$emit("toggle-compact");
            },
            toggleClose() {
                this.$emit("toggle-close");
            },
        },
    };
</script>

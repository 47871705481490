<template>
    <div class="fn-ui-report-column-selector">
        <div v-if="enabled" class="fn-ui-report-column-selector__overlay">
            <div
                v-for="(n) in range" :key="n.value"
                :class="['fn-ui-report-column-selector__hover', {'selected' : isColumnSelected(n.value)}]"
            >
                <div class="fn-ui-report-column-selector__header">
                    <div class="header__inner">
                        <div class="header__icon">
                            <icon icon="grip-vertical" size="sm" type="solid"></icon>
                        </div>
                        <div class="header__checkbox">
                            <input
                                type="checkbox"
                                :id="'checkbox-' + n.value"
                                :name="'checkbox-' + n.value"
                                :value="n.value"
                                multiple
                                v-model="selectedComputed"
                            >
                        </div>
                        <div class="header__icon">
                            <icon icon="grip-vertical"  size="sm" type="solid"></icon>
                        </div>
                    </div>
                </div>

                <div class="fn-ui-report-column-selector__body"></div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ReportColumnSelector",
        props: {
            enabled: Boolean,
            range: {
                type: Array
            },
            selected: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        computed: {
            selectedComputed: {
                get() {
                    return this.selected;
                },
                set(values) {
                    this.$emit("change", values);
                }
            }
        },
        methods: {
            isColumnSelected(value) {
                if(this.selectedComputed) {
                    return this.selectedComputed.includes(value);
                }
            }
        }
    };
</script>

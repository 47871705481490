<template>
    <div class="card-footer">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'CardFooter'
}
</script>

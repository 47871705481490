<template>
    <fn-ui-button ref="button" v-bind="{variant: $props.variant, size: $props.size, tooltip: $props.tooltip || $props.title, tag: $props.tag}" :submitting="submitting" :icon="buttonIcon" :text="buttonText" @click.prevent="onClick" :disabled="submitting"></fn-ui-button>
</template>

<script>
    import {FnUiButton} from "@fndry-vue/fn-ui";

    /**
     * A Foundry Request button for calling a URI
     *
     * It is typically used when performing an action against the URI such as delete or restore and those that do not
     * need a form to process the request.
     *
     * By default all requests are made using POST as the assumption is a change is being made.
     *
     */
    export default {
        name: "ApiButtonRequest",
        inheritAttrs: false,
        props: {

            /**
             * A title attribute value
             */
            title: String,

            /**
             * The URI to fetch results from
             */
            uri: {
                type: String,
                required: true
            },
            /**
             * The URI Parameters to send to the server
             */
            uriParams: {
                type: Object,
                default(){
                    return {};
                }
            },
            /**
             * The HTTP method to use when calling the URI
             */
            uriMethod: {
                type: String,
                default: "POST"
            },

            /**
             * Display a confirm box before completing the action
             */
            confirm: {
                type: Boolean
            },
            /**
             * Customise the confirmation message
             */
            confirmMessage: {
                type: String,
                default: "Are you sure?",
            },
            /**
             * Customise the Confirm title (only works with Vue Bootstrap Modals)
             */
            confirmTitle: {
                type: String,
                default: "Confirm"
            },
            /**
             * Customise the confirm options (only works with Vue Bootstrap Modals)
             *
             * @see https://bootstrap-vue.org/docs/components/modal
             */
            confirmOptions: {
                type: Object,
                default() {
                    return {
                        size: "sm",
                        okTitle: "Yes",
                        cancelTitle: "No"
                    };
                }
            },
            /**
             * The size of the button (only works with Vue Bootstrap Buttons)
             *
             * @see https://bootstrap-vue.org/docs/components/button
             */
            size: {
                type: String
            },
            /**
             * The variant of the button (only works with Vue Bootstrap Buttons)
             *
             * @see https://bootstrap-vue.org/docs/components/button
             */
            variant: {
                type: String
            },

            /**
             * Override the tag typ of the button
             */
            tag: {
                type: String,
                default: "b-button"
            },

            /**
             * The icon to use in the button
             */
            buttonIcon: {
                type: String
            },

            /**
             * The text to display in the button
             */
            buttonText: {
                type: String
            },

            /**
             * The tooltip to display
             */
            tooltip: {
                type: String
            }

        },
        components: {
            FnUiButton
        },
        data() {
            return {
                submitting: false,
                showConfirm: false
            };
        },
        methods: {
            onClick() {

                let options = {};

                if (this.confirm) {

                    this.showConfirm = true;

                    if (this.$bvModal) {
                        options = Object.assign({}, {
                            title: "Confirmation",
                            size: "sm",
                            centered: true,
                        }, this.confirmOptions);

                        this.$bvModal.msgBoxConfirm(this.confirmMessage, options)
                            .then(value => {
                                if (value) {
                                    this.call();
                                } else {
                                    this.onCancel();
                                }
                            })
                            .catch((response) => {
                                this.$notify({
                                    message: response.error,
                                    type: "error"
                                });
                                this.onFail(response);
                            });
                    } else {
                        if (confirm(this.confirmMessage)) {
                            this.call();
                        } else {
                            this.onCancel();
                        }
                    }
                } else {
                    this.call();
                }
            },
            call(){
                this.submitting = true;
                this.$fnApi.call(this.uri, this.uriMethod, this.uriParams)
                    .then((response) => {
                        if (response.status) {
                            this.onSuccess(response);
                        } else {
                            this.$fnNotify({
                                message: response.error,
                                type: "error"
                            });
                            this.onFail(response);
                        }
                    })
                    .finally(() => {
                        this.submitting = false;
                    })
                ;
            },
            onSuccess(response){
                /**
                 * Success event
                 *
                 * @property {object} response The response from the API
                 */
                this.$emit("success", response);
            },
            onFail(response){
                /**
                 * Fail event
                 * @property {object | string} response The response from the API
                 */
                this.$emit("fail", response);
            },
            onCancel(){
                this.submitting = false;
                /**
                 * Cancel event
                 */
                this.$emit("cancel");
            }
        }
    };

</script>

<template>
    <div class="fn-ui-report-menu-items-selection-item" :title="item.code">
        <span>
            <b-form-checkbox :indeterminate="indeterminate" :checked="checked" @change="handleChange" size="sm">
                {{ item.text }}
            </b-form-checkbox>
        </span>
<!--        <span>-->
<!--            <input type="checkbox" :id="'selection-item_'+item.id" :indeterminate.prop="indeterminate" :checked="checked" @change="(evt) => handleChange(evt.target.checked)">-->
<!--            <label :for="'selection-item_'+item.id" class="fn-ui-report-menu-items-selection-item__label">{{ item.text }} ({{key}})</label>-->
<!--        </span>-->
        <span v-if="expandable" @click="handleExpand" class="fn-ui-report-menu-items-selection-item__action">{{ actionText }}</span>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuItemsSelectionItem",
        props: {
            item: Object,
            checked: Boolean,
            indeterminate: Boolean,
            expandable: Boolean,
            expanded: Boolean,
        },
        computed: {
            actionText() {
                return this.expanded ? "Collapse" : "Expand";
            }
        },
        methods: {
            handleExpand() {
                this.$emit("expand", !this.expanded);
            },
            handleChange(checked){
                this.$emit("change", checked);
            }
        }
    };
</script>

<template>
    <div class="container-fluid">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>

export default {
    name: "ErrorLayout",
    components: {
    },
    computed: {
    }
};
</script>

<template>
    <b-form-input type="text"
          :id="id"
          :name="name"
          :value="value"
          :class="className"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :autocomplete="autocomplete"
          :autofocus="autofocus"
          :min="minLength"
          :max="maxLength"
          :required="required"
          :state="state"
          :size="size"
          @keypress="isValidKey"
          v-on="$listeners"
    ></b-form-input>
</template>

<script>

import {BFormInput} from "bootstrap-vue";

import TextInput from "./TextInput";

export default {
    name: "NumberInput",
    extends: TextInput,
    components: {
        BFormInput
    },
    methods: {
        isValidKey(val) {
            if (isNaN(Number(val.key))) {
                return val.preventDefault();
            }
        }
    }
};
</script>

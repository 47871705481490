<template>
    <div class="fn-ui-report-menu-select-header">
        <div class="fn-ui-report-menu-select-header__back-wrapper" @click="handleBack">
            <icon icon="chevron-left"></icon>
            <span class="fn-ui-report-menu-select-header__back-text">{{ backText }}</span>
        </div>
        <div class="fn-ui-report-menu-select-header__title">
            {{ title }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuSelectHeader",
        props: {
            title: String,
            backText: {
                type: String,
                default: 'Back'
            }
        },
        methods: {
            handleBack() {
                this.$emit('back');
            }
        }
    }
</script>

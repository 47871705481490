<template>
    <b-input-group class="b-date-time-picker">
        <b-form-datepicker v-model="date"
                           :disabled="disabled"
                           :readonly="readonly"
                           :required="required"
                           :state="state"
                           :size="size"
                           @input="handleInput"
                           :max="maxDate"
                           :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
        ></b-form-datepicker>
        <b-form-timepicker v-model="time"
                           :minutes-step="minutesStep"
                           :disabled="disabled"
                           :readonly="readonly"
                           :required="required"
                           :state="state"
                           :size="size"
                           @input="handleInput"
        ></b-form-timepicker>
    </b-input-group>
</template>

<script>
    import {BFormDatepicker, BFormTimepicker} from "bootstrap-vue";

    import TextInput from "./TextInput";
    import format from "../mixins/format";
    import input from "../mixins/input";
    import moment from "moment";

    export default {
        name: "DateTimeInput",
        extends: TextInput,
        mixins: [
            input,
            format
        ],
        components: {
            BFormDatepicker,
            BFormTimepicker
        },
        data() {
            return {
                dateTime: null,
                date: null,
                time: null,
            };
        },
        mounted() {
            this.setDateTime(this.value);
        },
        computed: {
            minutesStep() {
                return this.data?.datetime?.interval?.minutes;
            },
            maxDate() {
                return this.rules?.max;
            }
        },
        watch: {
            value: function (newValue) {
                this.setDateTime(newValue);
            }
        },
        methods: {
            setDateTime(value) {
                if (value) {
                    this.dateTime = moment(value, this.format);
                    this.date = this.dateTime.format("YYYY-MM-DD");
                    this.time = this.dateTime.format("HH:mm");

                    this.handleInput();
                } else {
                    this.dateTime = null;
                    this.date = null;
                    this.time = null;
                }
            },
            handleInput() {
                let newValue = null;

                if (this.date && this.time) {
                    newValue = moment(`${this.date} ${this.time}`).format(this.format);
                }

                const oldValue = moment(this.value).format(this.format);

                if (newValue !== oldValue) {
                    this.$emit("input", newValue);
                }
            }
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("loader", { attrs: { loading: _vm.loadingLeases } }, [
    _vm.approvedLeases.length > 0
      ? _c(
          "div",
          [
            _c("div", { staticClass: "row mb-2" }, [
              _c(
                "div",
                { staticClass: "col font-18 text-primary font-weight-bold" },
                [_vm._v(_vm._s(_vm.$t("You are approved for:")))]
              ),
            ]),
            _vm._l(_vm.approvedLeases, function (lease, index) {
              return _c("approved-lease-item", {
                key: index,
                attrs: { lease: lease },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-td :class="['fn-ui-report-column', 'fn-ui-report-column-value']">
        <slot></slot>
    </b-td>
</template>

<script>
    import ReportColumn from "./ReportColumn";

    export default {
        extends: ReportColumn,
        name: "ReportColumnValue"
    }
</script>

<template>
    <router-link v-if="to" ref="routerLink" v-b-tooltip.hover.noninteractive :title="computedTitle" :class="['fn-ui-layout-navigation-link', { 'fn-ui-layout-navigation-link--compact' : compact }]" :active-class="activeClassName" :href="href" :to="to" v-on="$listeners">
        <span class="fn-ui-layout-navigation-link__icon">
            <icon v-if="icon && compact" :icon="iconCompact ? iconCompact : icon"></icon>
            <icon v-else-if="icon && !compact" :icon="icon"></icon>
        </span>
        <span class="fn-ui-layout-navigation-link__text"><slot>{{ text }}</slot></span>
    </router-link>
    <div v-else
         v-b-tooltip.hover.noninteractive
         :title="computedTitle"
         :class="[
        'fn-ui-layout-navigation-link',
        { 'fn-ui-layout-navigation-link--compact' : compact },
        {'fn-ui-layout-navigation-link--active': active}
        ]" v-on="$listeners">
        <span v-if="avatar" class="fn-ui-layout-navigation-link__avatar">
            <avatar :src="avatar" type="image" size="sm" />
        </span>
        <span v-else class="fn-ui-layout-navigation-link__icon">
            <icon v-if="icon && compact" :icon="iconCompact ? iconCompact : icon"></icon>
            <icon v-else-if="icon && !compact" :icon="icon"></icon>
        </span>
        <span class="fn-ui-layout-navigation-link__text">
            <slot>{{ text }}</slot>
        </span>
        <span class="fn-ui-layout-navigation-link__badge">
            <b-badge variant="primary" v-if="badge">{{ badge }}</b-badge>
        </span>
    </div>
</template>

<script>
    import {$_fnNavGroup} from "./common";
    import Avatar from "../avatar/Avatar";

    export default {
        name: "LayoutNavigationLink",
        components: {
            Avatar
        },
        inject: {
            [$_fnNavGroup]: {
                name: $_fnNavGroup,
                default: null
            }
        },
        props: {
            compact: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: null
            },
            iconCompact: {
                type: String,
                default: null
            },
            avatar: {
                type: String,
                default: null,
            },
            text: {
                type: String,
                required: false
            },
            href: String,
            to: Object,
            active: {
                type: Boolean,
                default: false
            },
            alt: String,
            badge: [String, Number],
            activeClassName: {
                type: String,
                default() {
                    return 'fn-ui-layout-navigation-link--active';
                }
            }
        },
        computed: {
            computedTitle: function(){
                if (this.compact && this.alt) {
                    return this.alt;
                } else {
                    return "";
                }
            },
            isSameRoute() {
                if (this.to && this.$refs.routerLink.$el.classList.contains(this.activeClassName)) {
                    return true;
                }
                return false;
            }
        },
        mounted() {
            if (this.active || this.isSameRoute) {
                this.toggleNavGroup(true);
            }
        },
        methods: {
            toggleNavGroup(show){
                if (this.$_fnNavGroup && show) {
                    this.$_fnNavGroup.toggleShowChildren(show);
                }
            }
        },
        watch: {
            active(newVal){
                this.toggleNavGroup(newVal);
            }
        }
    };
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lease
    ? _c("div", [
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            { staticClass: "col font-18 text-primary font-weight-bold" },
            [_vm._v(_vm._s(_vm.$t("Summmary")))]
          ),
        ]),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row mb-2" }, [
              _c(
                "div",
                { staticClass: "col font-14 text-primary font-weight-bold" },
                [_vm._v(_vm._s(_vm.$t("Installment Summary")))]
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col text-left" }, [
                _vm._v(_vm._s(_vm.$t("Minimum Due"))),
              ]),
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("fn-format-currency", {
                    attrs: { value: _vm.lease.extra.MinDue },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col-8 text-left" }, [
                _vm._v(_vm._s(_vm.$t("Next Payment Due Date"))),
              ]),
              _c(
                "div",
                { staticClass: "col-4 text-right" },
                [
                  _c("fn-format-date", {
                    attrs: {
                      format: "MM/DD/YYYY",
                      value: _vm.lease.extra.nextDueDate,
                      "ignore-time-zone": true,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col-8 text-left" }, [
                _vm._v(_vm._s(_vm.$t("Number of Installments"))),
              ]),
              _c("div", { staticClass: "col-4 text-right" }, [
                _vm._v(_vm._s(_vm.totalNumberOfPayments)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            { staticClass: "col font-14 text-primary font-weight-bold" },
            [_vm._v(_vm._s(_vm.$t("Payment Summary")))]
          ),
        ]),
        _c("div", { staticClass: "row row-cols-2 mb-2" }, [
          _c("div", { staticClass: "col text-left" }, [
            _vm._v(_vm._s(_vm.$t("Total Paid"))),
          ]),
          _c(
            "div",
            { staticClass: "col text-right" },
            [
              _c("fn-format-currency", {
                attrs: { value: _vm.lease?.extra?.totalPaid ?? 0 },
              }),
            ],
            1
          ),
        ]),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col text-left" }, [
                _vm._v(_vm._s(_vm.$t("Payments Made"))),
              ]),
              _c("div", { staticClass: "col text-right" }, [
                _vm._v(
                  _vm._s(_vm.lease.extra.NumberPaymentsMade) +
                    " of " +
                    _vm._s(_vm.totalNumberOfPayments)
                ),
              ]),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col text-left" }, [
                _vm._v(_vm._s(_vm.$t("Last Payment Date"))),
              ]),
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("fn-format-date", {
                    attrs: {
                      format: "MM/DD/YYYY",
                      value: _vm.lease.extra.lastPaymentdate,
                      "ignore-time-zone": true,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col text-left" }, [
                _vm._v(_vm._s(_vm.$t("Late Fees"))),
              ]),
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("fn-format-currency", {
                    attrs: { value: _vm.lease.extra.LateFee },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row mb-2" }, [
              _c(
                "div",
                { staticClass: "col font-14 text-primary font-weight-bold" },
                [_vm._v(_vm._s(_vm.$t("Payoff Summary")))]
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col text-left" }, [
                _vm._v(_vm._s(_vm.$t("Payoff Amount"))),
              ]),
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("fn-format-currency", {
                    attrs: { value: _vm.lease.extra.balance },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row row-cols-2 mb-2" }, [
              _c("div", { staticClass: "col text-left" }, [
                _vm._v(_vm._s(_vm.$t("Payoff Due Date"))),
              ]),
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("fn-format-date", {
                    attrs: {
                      format: "MM/DD/YYYY",
                      value: _vm.lease.extra.PayoffGoodThruDate,
                      "ignore-time-zone": true,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isLeaseClosed
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col text-center small text-light" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "*Take this offer and visit your specified retailer to choose your merchandise"
                    )
                  )
                ),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div :class="classes">
        <div class="fn-ui-layout-column-header__logo" @click="toggleCompact">
            <span :class="{'hidden': !compact}"><img :src="iconCompact"></span>
            <span :class="{'hidden': compact}"><img :src="icon"></span>
        </div>
        <div class="fn-ui-layout-column-header__close pr-3" @click="toggleShow">
            <icon icon="times" size="2x" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutColumnHeader",
        props: {
            icon: String,
            iconCompact: String,
            compact: Boolean,
            show: Boolean,
        },
        computed: {
            classes: function(){
                return {
                    "fn-ui-layout-column-header": true,
                    "fn-ui-layout-column-header--branded": true,
                    "fn-ui-layout-column-header--compact" : this.compact
                };
            },
        },
        methods: {
            toggleCompact() {
                this.$emit("toggle-compact");
            },
            toggleShow() {
                this.$emit("toggle-show");
            },
        },
    };
</script>

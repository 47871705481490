<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{ $t('OTP Verification') }}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{ $t("Please check your mail for a 5 digit pin and enter it below to continue.") }}</p>
            <fn-form class="mt-3" :uri="formUri" :uri-params="formUriParams" uri-method="POST" :value="form"
                     @success="onSuccess">
                <template v-slot:buttons="{submit, invalid, loading, submitting}">
                    <div class="form-group mt-4">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded"
                                      :disabled="invalid || submitting" :submitting="submitting" type="submit">
                            {{ $t("Confirm") }}
                        </fn-ui-button>
                    </div>
                    <div class="mt-4">
                        <p class="text-center">{{ $t("Didn't receive the One Time Pin?") }} <a href="#"
                                                                                               :disabled="resendingPin"
                                                                                               @click.prevent="resendPin">{{ $t("Resend") }}
                            <b-spinner v-if="resendingPin" small variant="primary" class="ml-1"
                                       :label="$t('Loading')"></b-spinner>
                        </a></p>
                    </div>
                </template>
            </fn-form>
        </div>
    </div>
</template>

<script>
import {AUTH_CHECK} from "./index";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

export default {
    name: "AuthVerify",
    mixins: [
        withAutoGuard
    ],
    props: {
        token: String
    },
    data() {
        return {
            resendingPin: false,
            form: {
                token: this.token,
                otp: ""
            },
            pin: {
                digits: 5,
                state: null,
                invalidFeedback: null
            }
        };
    },
    computed: {
        formUri() {
            return "/api/v2/auth/verify";
        },
        formUriParams() {
            return this.getGuardUriParams();
        }
    },
    methods: {
        resendPin() {
            this.resendingPin = true;

            const uri = "/api/v2/auth/verify/resend";
            const data = this.getGuardUriParams({
                token: this.token,
            });

            this.$fnApi
                .call(uri, "POST", data)
                .then((response) => {
                    if (response.status) {
                        this.$fnNotify(this.$t("Pin resent!"));
                        this.form.token = response.data.token;
                    } else {
                        this.$fnNotify({
                            message: this.$t("Unable to resend Pin. Try again or contact support."),
                            type: "warning"
                        });
                    }
                })
                .finally(() => {
                    this.resendingPin = false;
                });
        },
        onSuccess(response) {
            if (response.status) {
                this.$fnNotify(this.$t("Account Verified!"));
                this.$fnAuth.setUser(response.data);
                this.$router.push({
                    name: AUTH_CHECK
                });
            }
        },
        onFail(response) {
            if (response.code === 422) {
                this.$refs.observer.setErrors(response.data);
            }
        }
    }
};
</script>

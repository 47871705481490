<template>
    <div class="fn-ui-report-input">
        <b-form-input
            class="fn-ui-report-input__input"
            type="text"
            :name="name"
            v-model="model"
            :placeholder="computedPlaceholder"
            size="sm"
            :tabindex="tabIndex"
            :disabled="disabled"
            @keypress="isNumber"
        ></b-form-input>
        <div :class="['fn-ui-report-input__append', 'bg-'+variant ]"></div>
    </div>
</template>

<script>
    import numeral from "numeral";

    export default {
        name: "ReportInput",
        props: {
            name: String,
            value: {
                type: [String,Number],
            },
            placeholder: [String,Number],
            variant: {
                type: String,
                default: "primary"
            },
            tabIndex: [Number, String],
            disabled: Boolean,
            format: {
                type: String,
                required: false
            }
        },
        computed: {
            computedPlaceholder: function() {
                return (this.placeholder) ? `${this.placeholder}` : null;
            },
            model: {
                get: function(){
                    if (this.format && this.value !== null && this.value !== "" && this.value !== "-" && !isNaN(this.value)) {
                        return numeral(this.value).format(this.format);
                    } else {
                        return this.value;
                    }
                },
                set: function(value){
                    if (value !== "" && value !== null && value !== "-") {
                        this.$emit("input", numeral(value).value());
                    } else {
                        this.$emit("input", value);
                    }
                }
            }
        },
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                let charCode = (evt.which) ? evt.which : evt.key;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        }
    };
</script>

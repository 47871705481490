var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column h-100 justify-content-between" },
    [
      _vm._m(0),
      _c("div", [
        _c("p", { staticClass: "lead text-center text-gray-600" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                "A code was sent to your email. Please check it and enter it below."
              )
            )
          ),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "fn-form-schema",
                {
                  ref: "formSchema",
                  attrs: { value: _vm.model, "no-inject": "" },
                  on: { input: _vm.onUpdate, validated: _vm.onValidated },
                },
                [_c("fn-form-group", { attrs: { schema: _vm.schema.code } })],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group mt-4" },
                [
                  _c(
                    "fn-ui-button",
                    {
                      staticClass: "btn-block btn-rounded",
                      attrs: {
                        variant: "primary",
                        disabled: _vm.flags.invalid || _vm.submitting,
                        submitting: _vm.submitting,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Continue")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group mt-4" },
                [
                  _c(
                    "fn-ui-button",
                    {
                      staticClass: "btn-block btn-rounded",
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.redirectToHome },
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h4", { staticClass: "text-left text-primary" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }
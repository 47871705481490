<template>
    <div :class="classes">
        <div :style="indent" class="fn-ui-report-menu-hierarchy-item__wrapper">
            <span @click="toggleChildren" class="fn-ui-report-menu-hierarchy-item__icon">
                <icon :icon="iconName" :class="iconClasses" type="solid"></icon>
            </span>
            <span class="d-inline-flex align-items-center">
<!--                <b-form-checkbox :id="'hierarchy-item_' + _uid"-->
<!--                                 class="fn-ui-report-menu-hierarchy-item__checkbox"-->
<!--                                 :checked="isChecked"-->
<!--                                 @change="(checked) => handleChange(id, checked)"-->
<!--                                 size="sm"-->
<!--                >-->
<!--                    {{ label }}-->
<!--                </b-form-checkbox>-->
                <label class="fn-ui-report-menu-hierarchy-item__label">{{ label }}</label>
            </span>
        </div>
        <div v-if="showChildren">
            <report-menu-hierarchy-item
                v-for="(_id) in children[id]"
                :key="_id"
                :id="_id"
                :children="children"
                :items="items"
                :depth="depth + 1"
                :label="items[_id].label"
                @change="(id, checked) => handleChange(_id, checked)"
            >
            </report-menu-hierarchy-item>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportMenuHierarchyItem",
        props: {
            id: String,
            children: Object,
            items: Object,
            label: String,
            depth: Number
        },
        data() {
            return {
                showChildren: true
            };
        },
        computed: {
            isChecked(){
                return this.items[this.id].checked;
            },
            hasChildren(){
                return !!(this.children[this.id]);
            },
            iconName() {
                if (!this.hasChildren) {
                    return "check-square";
                } else {
                    return this.showChildren ? "minus-square": "plus-square";
                }
            },
            iconClasses() {
                return "text-primary";
                // return {
                //     "text-secondary" : this.showChildren,
                //     "text-primary" : !this.showChildren
                // };
            },
            classes() {
                return {
                    "fn-ui-report-menu-hierarchy-item": true,
                    "has-children": this.hasChildren
                };
            },
            indent() {
                return { transform: `translate(${this.depth * (24 + 8)}px)` };
            }
        },
        methods: {
            toggleChildren() {
                this.showChildren = !this.showChildren;
            },
            handleChange(id, checked){
                this.$emit("change", id, checked);
            }
        }
    };
</script>

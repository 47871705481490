<template>
    <div class="fn-ui-layout-column-header fn-ui-layout-column-header--nav">
        <template v-if="mobileMenu.length">
            <div v-for="(menu, i) in mobileMenu"
                 :key="i"
                 class="fn-ui-layout-column-header__icon fn-ui-layout-column-header--nav__mobile pointer"
                 @click.prevent="handleMobileMenuClick(menu)"
            >
                <icon :icon="menu.icon" size="2x"></icon>
            </div>
        </template>
        <div class="fn-ui-layout-column-header__title">
            <slot>{{ title }}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderMenuColumn",
        props: {
            title: String,
            mobileMenu: Array,
        },
        methods: {
            handleMobileMenuClick(menu) {
                this.$emit("mobile-menu-click", menu);
            },
        }
    };
</script>

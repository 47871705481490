<template>
    <div class="fn-ui-nav-bar">
        <div class="fn-ui-nav-bar__mobile" @click="toggleMobileMenu">
            <icon icon="bars" size="2x"></icon>
        </div>
        <div class="fn-ui-nav-bar__title">{{ title }}</div>
    </div>
</template>

<script>


export default {
    name: "NavBar",
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    methods: {
        toggleMobileMenu() {
            this.$emit("toggle-mobile-menu");
        }
    }
};
</script>

<template>
    <div class="fn-ui-report-menu-items-selection">
        <div class="fn-ui-report-menu-items-selection__header">
            <span class="fn-ui-report-menu-items-selection__label" @click="handleSelectAll">Select all</span>
<!--            <span>-->
<!--                <input type="checkbox" :id="'selection-item-select-all_'+_uid">-->
<!--                <label :for="'selection-item-select-all_'+_uid" ></label>-->
<!--            </span>-->
            <span class="fn-ui-report-menu-items-selection__action" @click="clearSelected">Clear Selection</span>
        </div>

        <div class="fn-ui-report-menu-items-selection__list">
            <report-menu-items-selection-item v-for="(item) in items"
                                              :key="item.hierarchicalId"
                                              :item="item"
                                              :indeterminate="indeterminate[item.hierarchicalId]"
                                              :checked="checked[item.hierarchicalId]"
                                              :expanded="(expanded === item.id)"
                                              :expandable="expandable"
                                              @expand="(value) => handleExpand(value, item)"
                                              @change="(checked) => handleChange(item.id, checked)"
            ></report-menu-items-selection-item>
        </div>
    </div>
</template>

<script>
    import ReportMenuItemsSelectionItem from "./ReportMenuItemsSelectionItem";

    export default {
        name: "ReportMenuItemsSelection",
        components: {
            ReportMenuItemsSelectionItem
        },
        props: {
            checked: Object,
            indeterminate: Object,
            items: {
                type: Array,
                default: () => []
            },
            expandable: Boolean,
            expanded: Number
        },
        methods: {
            handleChange(id, checked){
                this.$emit("change", id, checked);
            },
            handleExpand(value, item) {
                if(value) {
                    this.$emit("expand", item);
                } else {
                    this.$emit("expand", null);
                }
            },
            handleSelectAll(){
                let ids = [];
                this.items.forEach((item) => {
                    ids.push(item.id);
                });
                this.$emit("select-all", ids);
            },
            clearSelected(){
                let ids = [];
                this.items.forEach((item) => {
                    ids.push(item.id);
                });
                this.$emit("clear", ids);
            }
        }
    };
</script>

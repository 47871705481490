<template>
    <div class="fn-ui-layout-row">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "LayoutRow"
    };
</script>

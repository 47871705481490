<template>
    <div :class="{'fn-types-file-input': true, 'is-valid': state === true, 'is-invalid': state === false}">
        <slot v-if="showDragDrop" v-bind="{handleUploadFiles}">
            <fn-ui-file-drag-drop icon="upload" @change="handleUploadFiles" :multiple="multiple" :accept="accept" :label-text="dragLabelText"></fn-ui-file-drag-drop>
        </slot>
        <div class="d-flex mb-2" v-if="showDragDrop && accept && accept.length > 0">
            <span class="ml-auto small text-gray-500 text-right">Acceptable file formats: {{ accept.join(", ") }}</span>
        </div>
        <div v-if="haveUploaded" class="media-uploaded" :class="{'media-layout-grid': grid, 'media-layout-row': !grid}">
            <div v-if="multiple" :class="{'row': grid}">
                <div v-for="(file, index) in uploaded" :class="gridColClass" :key="index">
                    <slot name="uploaded" v-bind="{file, layout, border, deleteUri, editUri, handleCloseFile}">
                        <fn-ui-file-upload :thumbnail="layout === 'thumbnail'" upload-icon="upload" close-icon="times" error-icon="exclamation-triangle" success-icon="check" v-bind="file" :uploaded="true" @close="() => handleCloseFile('uploaded', file.id)" :key="file.id" :border="border" @rename="() => handleRename(file)" :fileIndex="index" :enable-edit="enableEdit">
                            <template v-slot:buttons v-if="deleteUri" v-bind="{file}">
                                <fn-api-button :uri="deleteUri" :uri-params="{...uriParams, file: file.id, force: true, token: file.token}" confirm confirm-message="Are you sure you want to delete this file?" button-icon="times" variant="link" size="sm" @success="() => handleCloseFile('uploaded', file.id)"></fn-api-button>
                            </template>
                        </fn-ui-file-upload>
                    </slot>
                </div>
            </div>
            <div v-else>
                <slot name="uploaded" v-bind="{layout, border, deleteUri, editUri, handleCloseFile}" v-bind:file="uploaded">
                    <fn-ui-file-upload upload-icon="upload" :thumbnail="layout === 'thumbnail'" close-icon="times" error-icon="exclamation-triangle" success-icon="check" v-bind="uploaded" :uploaded="true" @close="() => handleCloseFile('uploaded', uploaded.id)" :key="uploaded.id" :border="border" @rename="() => handleRename(uploaded)" :enable-edit="enableEdit" :fileIndex="0">
                        <template v-if="deleteUri" v-slot:buttons>
                            <fn-api-button :uri="deleteUri" :uri-params="{...uriParams, file: uploaded.id, force: true, token: uploaded.token}" confirm confirm-message="Are you sure you want to delete this file?" button-icon="times" variant="link" size="sm" @success="() => handleCloseFile('uploaded', uploaded.id)"></fn-api-button>
                        </template>
                    </fn-ui-file-upload>
                </slot>
            </div>
        </div>
        <div v-if="uploading.length > 0" class="media-uploading">
            <template v-for="(file, index) in uploading">
                <slot name="uploading" v-bind="{file, handleCloseFile}">
                    <fn-ui-file-upload upload-icon="upload" close-icon="times" error-icon="exclamation-triangle" success-icon="check" v-bind="file" @close="() => handleCloseFile('uploading', file.id)" :key="file.id" :border="border" :fileIndex="index">
                    </fn-ui-file-upload>
                </slot>
            </template>
        </div>
        <div v-if="upload.length > 0" class="media-upload">
            <template v-for="(file, index) in upload">
                <slot name="upload" v-bind="{file, handleCloseFile}">
                    <fn-ui-file-upload upload-icon="upload" close-icon="times" error-icon="exclamation-triangle" success-icon="check" v-bind="file" @close="() => handleCloseFile('uploading', file.id)" :key="file.id" :border="border" :fileIndex="index">
                    </fn-ui-file-upload>
                </slot>
            </template>
        </div>
    </div>
</template>

<script>

    import {forEach, debounce, uniqueId, findIndex, merge, isEmpty, isArray, isObject} from "lodash";
    import {FnUiFileUpload, FnUiFileDragDrop} from "@fndry-vue/fn-ui";
    import {FnApiButton} from "@fndry-vue/fn-api";

    import action from "../mixins/action";
    import minMax from "../mixins/minMax";
    import input from "../mixins/input";
    import {fnResponseToNotification} from "../../fn-notify";

    /**
     * Upload Input for uploading files
     */
    export default {
        name: "FileInput",
        mixins: [
            input,
            action,
            minMax
        ],
        components: {
            FnUiFileUpload,
            FnApiButton,
            FnUiFileDragDrop
        },
        props: {
            /**
             * The edit URI to rename the uploaded file
             */
            editUri: {
                type: String
            },
            /**
             * The delete URI to delete the uploaded file
             */
            deleteUri: {
                type: String
            },
            /**
             * If multiple files are being supported
             */
            multiple: Boolean,
            /**
             * The value of the input
             *
             * This will be an object if a single file or an Array if a list of files. Null if no file(s) have been set yet.
             *
             *
             */
            value: {
                type: [Object,Array],
                required: false
            },
            /**
             * An array of acceptable file extensions for the file input, also outputs this as helptext
             */
            accept: Array,

            /**
             * The label text for the drag and drop label
             */
            dragLabelText: {
                type: String,
                required: false
            },
            uploadOptions: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        data(){
            return {
                upload: [],
                uploading: [],
                process: false
            };
        },
        computed: {
            /**
             * Displays a border around the upload/uploading/upload blocks
             */
            border: function(){
                return this.data?.border;
            },
            /**
             * Switch the display of the files to thumbnails
             */
            layout: function(){
                return this.data?.layout;
            },
            /**
             * How many columns to display if in grid mode
             *
             * this maps to col-[cols]
             *
             */
            cols: function(){
                return this.data?.cols;
            },
            /**
             * Display the layout as a grid
             */
            grid: function(){
                return this.data?.grid;
            },

            uploaded: {
                get: function(){
                    return this.value;
                },
                set: function(value){
                    /**
                     * Emitted when files have been uploaded or deleted
                     *
                     * @property {Object|Array} The file(s). Object if multiple not set, Array of file objects if set
                     */
                    this.$emit("input", value);
                }
            },
            haveUploaded: function() {
                if (isArray(this.uploaded)) {
                    return this.uploaded.length > 0;
                } else if (isObject(this.uploaded)) {
                    return this.uploaded.id;
                } else {
                    return false;
                }
            },
            showDragDrop: function(){
                if (this.multiple) {
                    if (this.maxCount && this.uploaded) {
                        return this.uploaded.length <  parseInt(this.max);
                    } else {
                        return true;
                    }
                } else {
                    return isEmpty(this.uploaded);
                }
            },
            count: function(){
                if (this.multiple) {
                    return (this.uploaded) ? this.uploaded.length + this.upload.length : 0;
                } else {
                    return isEmpty(this.uploaded) ? this.upload.length : this.upload.length + 1;
                }
            },
            maxCount: function(){
                return parseInt(this.max);
            },
            gridColClass: function(){
                if (this.grid) {
                    return (this.multiple) ? `col-${this.cols}` : "col-12";
                } else {
                    return "";
                }
            },
            enableEdit() {
                return !isEmpty(this.editUri);
            }
        },
        methods: {
            handleUploadFiles(files){
                let setFile = (file) => {

                   let checkUnique = true;

                    if(this.multiple && this.uploaded && this.uploaded.length > 0) {
                        checkUnique = this.uploaded.every( (uploaded) => {
                            return uploaded.original_name !== file.name;
                        });
                    }

                   if(checkUnique) {
                       this.upload.push({
                           id: uniqueId("file_"),
                           progress: 0,
                           uploading: true,
                           uploaded: false,
                           file: file,
                           original_name: file.name
                       });
                       this.process = true;
                   }

                };

                if (files.length && files.length > 0) {
                    for (let i=0;i<files.length;i++) {
                        if (!this.maxCount || this.maxCount === 0 || this.count < this.maxCount) {
                            setFile(files[i]);
                        }
                    }
                } else if (files !== null && files.name) {
                    setFile(files);
                }
            },
            processUploads: debounce(function(){
                let upload = this.upload.shift();
                if (upload !== undefined) {
                    this.processFileUpload(upload);
                } else {
                    this.process = false;
                }
            }, 250),
            processFileUpload(upload){

                let length = this.uploading.push(upload);
                let index = length - 1;

                if (this.maxCount > 0 && this.count >= this.maxCount) {
                    this.uploading[index].progress = 0;
                    this.uploading[index].uploading = false;
                    this.uploading[index].failed = true;
                    this.uploading[index].error = "Max file limit reached";
                    this.processUploads();
                    return;
                }

                let file = upload.file;

                this.$fnApi.upload(this.$fnApi.uri(this.uri, this.uriParams), file,
                    (progressEvent) => {
                        this.uploading[index].progress = Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total );
                    }, this.uploadOptions)
                    .then((response) => {
                        if (response.status) {
                            this.uploading.splice(index, 1);
                            this.setUploaded(merge({}, response.data, {
                                file,
                                progress: 100,
                                uploading: false,
                                uploaded: true,
                                failed: false
                            }));
                        } else {
                            this.uploading[index].progress = 0;
                            this.uploading[index].uploading = false;
                            this.uploading[index].failed = true;
                            this.uploading[index].error = (response.error) ? response.error : "Unable to upload file";
                            this.uploading[index].validation = [];
                            if (response.code === 422 && response.data) {
                                forEach(response.data, (errors) => {
                                    forEach(errors, (error) => {
                                        this.uploading[index].validation.push(error);
                                    });
                                });
                            }
                            if (response.code === 504) {
                                this.uploading[index].error = "Server timed out. Please try uploading again later.";
                            }
                        }
                    })
                    .finally(() => {
                        this.processUploads();
                    })
                ;
            },
            handleCloseFile(type, id){
                if (type === "uploading") {
                    let index = findIndex(this.uploading, (file) => file.id === id);
                    if (index !== -1) {
                        this.uploading.splice(index, 1);
                    }
                } else if (type === "uploaded") {
                    if (this.multiple) {
                        let index = findIndex(this.uploaded, (file) => file.id === id);
                        if (index !== -1) {
                            this.uploaded.splice(index, 1);
                        }
                        if (isEmpty(this.uploaded)) {
                            this.uploaded = null;
                        }
                    } else {
                        this.uploaded = null;
                    }
                }
            },
            setUploaded(value){
                if (this.multiple) {
                    if (!this.haveUploaded) {
                        this.uploaded = [value];
                    } else {
                        this.uploaded.push(value);
                    }
                } else {
                    this.uploaded = value;
                }
            },
            handleRename(file) {
                let rename = prompt("Rename this file", file.original_name);
                if(rename) {
                    let params = {
                        ...this.uriParams,
                        file: file.id,
                        original_name : rename,
                    };
                    this.renameFile(params);
                }

            },
            async renameFile(params) {
                const response = await this.$fnApi.call(this.editUri, "POST", params);
                if (response.status) {
                    let index = findIndex(this.uploaded, (file) => file.id === response.data.id);
                    if (index !== -1) {
                        this.uploaded[index].original_name = response.data.original_name;
                    }
                } else {
                    this.$fnNotify(fnResponseToNotification(response));
                }
            },
        },
        watch: {
            process: function(newVal, oldVal) {
                if (newVal !== oldVal && newVal === true) {
                    this.processUploads();
                }
            }
        }
    };
</script>

<style scoped>

</style>

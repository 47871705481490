var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VerifyIdentityComponent", {
    ref: "verifyIdentityComponent",
    attrs: {
      "sub-title": _vm.$t("ENTER THE FOLLOWING DETAILS..."),
      "submit-button-text": _vm.$t("Verify My Identity"),
      title: _vm.$t("Verify Identity"),
      "no-toggle": true,
      "with-button": true,
    },
    on: { submit: _vm.onSubmit },
    scopedSlots: _vm._u([
      {
        key: "further-actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "mt-4 pointer" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-dark font-14",
                    attrs: { to: _vm.loginRoute },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Or Cancel and Login")) + " ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
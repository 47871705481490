<template>
    <a v-bind="$attrs" v-on="$listeners" :href="href">{{ value }}</a>
</template>

<script>

    export default {
        name: "FnFormatUrl",
        props: {
            value: {
                type: String,
                required: true,
            }
        },
        computed: {
            href: function() {
                if (!this.value.match(/^https?:\/\//i)) {
                    return "https://" + this.value;
                } else {
                    return this.value;
                }
            }
        }
    };

</script>

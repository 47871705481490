<template>
    <div :class="classes">

        <span v-if="type == 'text'" class="fn-ui-avatar__title" :class="{[`bg-${variant}`]: true}">
            <slot></slot>
        </span>

        <span v-else-if="type == 'icon'" class="fn-ui-avatar__icon" :class="{[`bg-${variant}`]: true}">
            <slot></slot>
        </span>

        <img v-else-if="type == 'image'" class="fn-ui-avatar__img" :alt="alt" :src="src">

        <div v-if="withBadge" :class="['fn-ui-avatar__badge', {[`${badgePosition}`]: true} ]"></div>

    </div>
</template>

<script>

export default {
    name: "Avatar",
    props: {
        src: String,
        alt: String,
        variant: {
            type: String,
            default: "primary"
        },
        type: {
            type: String,
            default: "text",
            validator: function (value) {
                return ["text", "image", "icon"].indexOf(value) !== -1;
            }
        },
        size: {
            type: String,
            default: "md",
            validator: function (value) {
                return ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1;
            }
        },
        withBadge: {
            type: Boolean,
            default: false
        },
        badgePosition: {
            type: String,
            default: "bottom right"
        }
    },
    computed: {
        classes(){
            return {
                "fn-ui-avatar": true,
                [`fn-ui-avatar--${this.size}`]: true
            };
        },
        imgSrc() {
            return this.src;
        }
    }
};
</script>

<template>
    <div class="fn-ui-report-table">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ReportTable",
        props: {
            columns: {
                type: [Number, String],
                default: 13
            }
        },
        provide() {
            return {
                columns: this.columns
            }
        }
    }
</script>

<template>
    <b-form-timepicker :id="id"
                       :name="name"
                       :value="value"
                       :placeholder="placeholder"
                       :disabled="disabled"
                       :readonly="readonly"
                       :required="required"
                       :state="state"
                       :size="size"
                       v-on="$listeners"
                       :hour12="hour12"
                       :minutes-step="step"
                       @input="(value) => $emit('input', value)"
    ></b-form-timepicker>
</template>

<script>

    import {BFormTimepicker} from 'bootstrap-vue';

    import format from "../mixins/format";
    import input from "../mixins/input";

    export default {
        name: "TimeInput",
        mixins: [
            input,
            format
        ],
        components: {
            BFormTimepicker
        },
        computed: {
            hour12: function(){
                return this.data?.datetime?.hour24Format !== true;
            },
            step: function(){
                return this.data?.datetime?.interval.minutes ?? 1;
            }
        },
    };
</script>

<template>
    <div class="fn-ui-report-taggable-list-item">
        <span class="fn-ui-report-taggable-list-item__label">{{ label }}</span>
        <span class="fn-ui-report-taggable-list-item__action" v-if="actionText" @click="handleAction">{{ actionText }}</span>
        <span class="fn-ui-report-taggable-list-item__close" @click="handleCancel">
            <icon icon="times"></icon>
        </span>
    </div>
</template>

<script>
    export default {
        name: "ReportTaggableListItem",
        props: {
            label: String,
            actionText: String
        },
        methods: {
            handleAction() {
                this.$emit("action");
            },
            handleCancel() {
                this.$emit("cancel");
            }
        }
    };
</script>

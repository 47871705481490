var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "screen",
    {
      staticClass: "customer-panel",
      attrs: {
        title: _vm.$t("Add New Credit Card"),
        "with-button": false,
        "no-toggle": "",
        "clear-wave": "",
      },
    },
    [
      _c("add-card-screen", {
        attrs: { "is-panel": "", ssn: _vm.ssn },
        on: { success: _vm.handleSuccess, cancel: _vm.handleCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <span>{{ formatted }}</span>
</template>

<script>

export default {
    name: "FnFormatCurrency",
    props: {
        value: {
            required: false
        },
        currency: {
            type: String,
            default() {
                return "USD";
            }
        },
        defaultValue: {
            default() {
                return null;
            }
        }
    },
    computed: {
        formatted() {
            const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: this.currency,
                currencyDisplay: "narrowSymbol",
            });

            return this.value ? formatter.format(this.value) : formatter.format(this.defaultValue);
        }
    }
}
</script>


<template>
    <b-form-select
        :id="id"
        :name="name"
        v-model="model"
        :multiple="multiple"
        :disabled="disabled"
        :readonly="readonly"
        :state="state"
        :size="size"
        v-on="$listeners"
    >
        <option v-if="empty && !multiple" :value="null">{{ (empty === true) ? 'Please select an option' : empty }}
        </option>
        <template v-if="groups_">
            <optgroup v-for="(group) in groups_" :label="group.label" :key="`group-${key}-${group.label}`">
                <option v-for="option in group.values" :value="option.value" :key="`${key}-${option.value}`">
                    {{ option.text }}
                </option>
            </optgroup>
        </template>
        <option v-else-if="options_" v-for="option in options_" :value="option.value" :key="`${key}-${option.value}`">
            {{ option.text }}
        </option>
    </b-form-select>

</template>

<script>

import {forEach, findIndex, merge} from "lodash";

import {BFormSelect} from "bootstrap-vue";
import input from "../mixins/input";
import options from "../mixins/options";

export default {
    name: "SelectInput",
    mixins: [
        input,
        options
    ],
    components: {
        BFormSelect
    },
    props: {
        buttons: {
            type: Array,
            required: false
        },
        empty: {
            type: [String, Boolean],
            required: false
        }
    },
    data() {
        return {
            key: 0,
            loading: false
        };
    },
    computed: {
        model: {
            get: function () {
                if (this.multiple && this.value === null) {
                    return [];
                }
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            }
        }
    },
    methods: {
        canDisplayButton(type) {
            switch (type) {
                case "edit":
                    return !this.multiple && this.model;
                case "add":
                default:
                    return true;
            }
        },
        getButtonParams(button) {
            return merge({}, this.params, button.params, {
                _entity: this.model
            });
        },
        getResults(query) {
            this.loading = true;

            let params = merge({}, this.params, {
                [this.query]: query || ""
            });

            this.$fnApi.call(`${this.uri}`, "GET", params)
                .then((res) => {
                    let results = [];
                    if (res.data.length > 0) {
                        forEach(res.data, (item) => {
                            results.push({
                                [this.textKey]: item[this.textKey],
                                [this.valueKey]: item[this.valueKey]
                            });
                        });
                    }
                    this.localOptions = results;
                    this.setOptions();
                })
                .finally(() => {
                    this.loading = false;
                })
            ;
        }
    },
    watch: {
        filter: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setOptions();
            }
        }
    }
};
</script>

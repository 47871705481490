<template>
    <div class="fn-ui-layout-navigation-header" :class="{'fn-ui-layout-navigation-header--compact' : compact}">
        {{ compact ? titleCompact : title }}
    </div>
</template>

<script>
    export default {
        name: "LayoutNavigationHeader",
        props: {
            title: String,
            titleCompact: String,
            compact: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
